<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="headline"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['name']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.surname") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['vorname']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.firstname") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.status"
                        :options="aktivitaetsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row" v-show="!rolleProp">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-if="rollen"
                        v-model="filters.rolle"
                        :reduce="(r) => r.id"
                        :options="rollen"
                        label="bezeichnung"
                        :placeholder="$t('dashboard.type')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.notypefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("dashboard.type") }}</label>
                    </div>
                  </div>
                </div>
                <div
                  v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                  class="row"
                >
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.funktionsbereich"
                        :reduce="(fb) => fb.id"
                        :options="funktionsbereiche"
                        label="bezeichnung"
                        :placeholder="$t('global.functionalarea')"
                      >
                        <span slot="no-options">{{
                          $t("global.nofunctionalareafound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.functionalarea") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.interesse"
                        :reduce="(i) => i.id"
                        :options="interessen"
                        label="bezeichnung"
                        :placeholder="$t('global.interests')"
                      >
                        <span slot="no-options">{{
                          $t("global.nointerestfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.interests") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.interesse_infoabend"
                        :reduce="(i) => i.id"
                        :options="interessen_infoabend"
                        label="bezeichnung"
                        placeholder="Interesse Infoabend"
                      >
                        <span slot="no-options">{{
                          $t("global.nointerestfound")
                        }}</span>
                      </v-select>
                      <label>Interesse Infoabend</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.versand"
                        :reduce="(vl) => vl.id"
                        :options="versandlisten"
                        label="bezeichnung"
                        :placeholder="$t('global.dispatch')"
                      >
                        <span slot="no-options">{{
                          $t("global.nomailinglistfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.mailinglist") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button
                  @searchWithFilter="filterSearch"
                  @resetFilter="clearFilter"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="
                rolleProp && rolleObj
                  ? rolleObj.bezeichnung
                  : $t('global.personlist')
              "
              :date="'Mittwoch 29.Juli'"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <router-link
                      v-if="
                        rolleProp && berechtigungen.m_geschaeftspartner.create
                      "
                      class="btn btn-primary"
                      :to="{
                        name: 'NeuerGeschäftspartnerMitRolle',
                        params: { rolleProp: rolleProp },
                      }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{
                        rolleProp && rolleObj
                          ? rolleObj.bezeichnung
                          : $t("global.businesspartner")
                      }}
                      {{ $t("global.add") }}
                    </router-link>
                    <router-link
                      v-if="
                        !rolleProp && berechtigungen.m_geschaeftspartner.create
                      "
                      class="btn btn-success"
                      :to="{
                        name: 'geschaeftspartner',
                        params: { anzeige: 0 },
                      }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>

                    <span v-if="$CONST('CONFIG').APPTYPE === 'Education'">
                      <div style="display: inline-flex">
                        <massenmutation-button
                          :mutatableFields="this.mutatableFields"
                          :mutatableFieldsMultiple="
                            this.mutatableFieldsMultiple
                          "
                          :mutatableValues="[
                            [this.aktivitaetsstati],
                            [this.rollen],
                          ]"
                          :mutatableMultipleValues="[[this.rollen]]"
                          :mutatableLabels="this.mutatableLabels"
                          :mutatableLabelsMultiple="
                            this.mutatableLabelsMultiple
                          "
                          :mutationPfad="this.mutationPfad"
                          :selectedIds="this.selectedIds"
                          @mutated="refresh"
                        />
                      </div>
                      <b-dropdown variant="primary ml-2">
                        <template slot="button-content">
                          <font-awesome-icon
                            icon="fa-duotone fa-print"
                            class="mr-2"
                          />
                          Etiketten
                        </template>
                        <b-dropdown-item @click="ausgebenHerma445121">
                          HERMA_4451_21
                        </b-dropdown-item>
                        <b-dropdown-item @click="ausgebenHerma445214">
                          HERMA_4452_14
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="ausgebenAvery365221">
                          Avery 3652 21
                        </b-dropdown-item>
                        <b-dropdown-item @click="ausgebenAvery365314">
                          Avery 3653 14
                        </b-dropdown-item>
                        <b-dropdown-item @click="ausgebenZweckform478427">
                          Zweckform 4784 27
                        </b-dropdown-item> -->
                      </b-dropdown>
                    </span>

                    <b-dropdown
                      variant="primary ml-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                    >
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-regular fa-envelope"
                          class="mr-2"
                        />
                        {{ $t("global.createmassmail") }}
                      </template>
                      <b-dropdown-item @click="massenmailErstellen(true)">
                        {{ $t("global.selected") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="massenmailErstellen(false)">
                        {{ $t("global.alldisplayed") }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown
                      variant="primary ml-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                    >
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-regular fa-envelope"
                          class="mr-2"
                        />
                        {{ $t("global.createserialletter") }}
                      </template>
                      <b-dropdown-item
                        @click="massenkorrespondenzErstellen(true)"
                      >
                        {{ $t("global.selected") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="massenkorrespondenzErstellen(false)"
                      >
                        {{ $t("global.alldisplayed") }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <!--                     <button
                      class="btn btn-primary ml-2"
                      v-if="berechtigungen.m_geschaeftspartner.create"
                      @click="oeffneLoginErstellenModal"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />{{ $t("global.createLogin") }}
                    </button> -->
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      v-if="berechtigungen.m_geschaeftspartner.delete"
                      @click="oeffneLoeschenModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <div class="form-group p-0 m-0">
                          <input
                            class="form-control"
                            v-if="
                              field.key != 'selected' &&
                              field.searchable != false
                            "
                            size="sm"
                            type="text"
                            v-model="filters[field.key]"
                            :placeholder="field.label"
                          />
                        </div>
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">
                      {{ $t("global.loadmorebusinesspartners") }}
                    </div>
                    <div slot="no-more">
                      {{ $t("global.nofurtherbusinesspartners") }}
                    </div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherbusinesspartnersfound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="geschaeftspartner-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="geschaeftspartnerLoeschen"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>

    <messagebox-count
      :headerText="$t('global.personsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <messagebox-enhanced
      :headerText="$t('global.createLogin')"
      :id="'create-login-alert-msgbox'"
      :ok="true"
      :cancel="true"
      :okText="msgboxOkText"
      :cancelText="$t('global.cancel')"
      @ok="createLogin"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

import store from "@/store";
import server from "@/server";

import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";
import { LADE_VERSANDLISTEN } from "@/store/korrespondenz/actions.type";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import { addresseToSingleLineString } from "@/models/Adresse";

export default {
  name: "personen-liste",
  components: {
    Navbar,
    LoeschenModal,
    HeadMenu,
    HeadMenuFilter,
    MessageboxEnhanced,
    FilterSearchResetButton,
    MassenmutationButton,
  },
  metaInfo() {
    const rolleObj = this.rolleObj;

    return {
      titleTemplate: () => {
        return rolleObj
          ? "BX: " + rolleObj.bezeichnung
          : "BX: " + this.$t("global.person");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {
    rolleProp: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      name: "Geschaeftspartnerliste",
      infiniteId: +new Date(),
      pdfPath: "",
      fields: [
        { key: "selected", label: "" },
        {
          key: "nummer",
          sortable: true,
          label: this.$t("global.bpartnernum"),
          tdClass: "col-number",
        },
        { key: "name", sortable: true, label: this.$t("global.surname") },
        {
          key: "vorname",
          sortable: true,
          label: this.$t("global.firstname"),
        },
        {
          key: "mailadressen[0].bezeichnung",
          sortable: true,
          label: this.$t("global.email"),
        },
        {
          key: "aktivitaetsstatus.bezeichnung",
          sortable: true,
          searchable: false,
          class: "text-center",
          label: this.$t("global.status"),
        },
        { key: "telefon_g", sortable: true, label: this.$t("global.phoneb") },
        { key: "telefon_p", sortable: true, label: this.$t("global.phonep") },
        { key: "telefon_m", sortable: true, label: this.$t("global.phonem") },
      ],

      // Support für multiple v-select Auswahlen hinzufügen
      mutatableFields: ["Status"],
      mutatableFieldsMultiple: ["Typ"],
      mutatableValues: [[]],
      mutatableMultipleValues: [[]],
      mutatableLabels: ["bezeichnung"],
      mutatableLabelsMultiple: ["bezeichnung"],
      mutationPfad: "geschaeftspartner/",

      selected: [],
      selectedIds: [],
      msgboxOkText: this.$t("global.ok"),
    };
  },
  computed: {
    queryParams: function () {
      var params = {};
      params.nummer = this.setQueryParam(this.filters.nummer);
      params.name = this.setQueryParam(this.filters["name"]);
      params.vorname = this.setQueryParam(this.filters["vorname"]);
      /* params.statusBez = this.setQueryParam(
        this.filters["aktivitaetsstatus.bezeichnung"]
      ); */

      params.status = this.setQueryParam(this.filters.status);
      /*if (params.status == null) {
        params.status = "1737FA777AA";
      }*/
      params.telg = this.setQueryParam(this.filters.telefon_g);
      params.telp = this.setQueryParam(this.filters.telefon_p);
      params.telm = this.setQueryParam(this.filters.telefon_m);
      params.email = this.setQueryParam(
        this.filters["mailadressen[0].bezeichnung"]
      );
      params.interesse = this.setQueryParam(this.filters.interesse);
      params.interesse_infoabend = this.setQueryParam(
        this.filters.interesse_infoabend
      );

      let filterRollen;
      if (this.rolleProp) filterRollen = this.rolleProp;
      else
        filterRollen = this.filters.rolle ? this.filters.rolle.join(",") : null;

      params.rollen = this.setQueryParam(filterRollen);
      params.fbereich = this.setQueryParam(this.filters.funktionsbereich);
      params.versand = this.setQueryParam(this.filters.versand);
      params.filterCount = this.setQueryParam(this.filters.count);

      params.typ = "17EF8844E6B";
      params.incf = "1";

      return params;
    },

    rollen: {
      get() {
        return this.$store.state.geschaeftspartner.partnerrollen;
      },
    },
    funktionsbereiche: {
      get() {
        return this.$store.state.geschaeftspartner.funktionsbereiche;
      },
    },
    interessen: {
      get() {
        return this.$store.state.geschaeftspartner.interessen;
      },
    },
    interessen_infoabend: {
      get() {
        return this.$store.state.geschaeftspartner.interessen_infoabend;
      },
    },
    aktivitaetsstati: {
      get() {
        return this.$store.state.geschaeftspartner.aktivitaetsstati;
      },
    },
    versandlisten: {
      get() {
        return this.$store.state.korrespondenz.versandlisten;
      },
    },

    rolleObj() {
      if (this.rolleProp && this.rollen)
        return this.rollen.find((r) => r.id === this.rolleProp);
      return null;
    },
    headline() {
      if (this.rolleProp && this.rollen) {
        let rolle = this.rollen.find((r) => r.id === this.rolleProp);
        if (rolle) return rolle.bezeichnung;
      }
      return "BX: " + this.$t("dashboard.contacts");
    },
  },
  watch: {},
  created() {
    if (this.rollen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    if (this.versandlisten.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_VERSANDLISTEN}`);

    this.initFilter("geschaeftspartner-liste", "geschaeftspartner/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "bpartnernum",
      "surname",
      "firstname",
      "email",
      "status",
      "phoneb",
      "phonep",
      "phonem",
    ]);
  },
  methods: {
    createLogin() {
      Api.post("/geschaeftspartner/create-login/", this.selectedIds)
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.createLoginSuccess"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    adresse(geschaeftspartner) {
      if (geschaeftspartner.hauptadresse)
        return addresseToSingleLineString(geschaeftspartner.hauptadresse);
      else return "";
    },

    geschaeftspartnerLoeschen() {
      this.delete("geschaeftspartner/", this.selectedIds);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit(
          "openLoeschenModal",
          "geschaeftspartner-loeschen-modal"
        );
    },

    oeffneLoginErstellenModal() {
      if (this.selectedIds.length > 0) {
        this.msgboxOkText = `${this.$t("global.ok")} (${
          this.selectedIds.length
        })`;
        let text = this.$t("global.createLoginInquiry");

        this.$bus.$emit("openMessageBoxEnhanced", {
          id: "create-login-alert-msgbox",
          text: text,
        });
      }
    },

    ausgebenHerma445121() {
      let ids = this.selectedIds.join(",");
      let filename =
        "HERMA_4451_21_" + new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/herma-4451-21.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },
    ausgebenHerma445214() {
      let ids = this.selectedIds.join(",");
      let filename =
        "HERMA_4452_14_" + new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/herma-4452-14.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },
    ausgebenAvery365221() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/avery-3652-21.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    ausgebenAvery365314() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/avery-3653-14.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    ausgebenZweckform478427() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/zweckform-4784-27.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    massenmailErstellen(selected) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedIds
          : this.$refs.selectableTable.items.map((item) => item.id);

        let json = {
          empfaenger: tempList,
          isMassMail: true,
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        Api.post("mail/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "mail",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nopersonsselected"),
        });
    },

    massenkorrespondenzErstellen(selected) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedIds
          : this.$refs.selectableTable.items.map((item) => item.id);

        let json = {
          empfaenger: [],
          person: [], // ist de facto person. Noch unsauber im Backend
          isMassKorrespondenz: true,
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter.person,
            datum: new Intl.DateTimeFormat("ch").format(new Date()),
            ort: this.$CONST("CONFIG").MANDANTSTADT,
            betreff: "",
          },
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        json.empfaenger = tempList;
        json.person = tempList;

        Api.post("korrespondenz/", json, { params: {} }).then((response) => {
          Api.post("korrespondenz/empfaenger/", json, {
            params: {
              korrespondenz: response.data.id,
              isPerson: true,
              isMassCorr: true,
            },
          }).then((empfresponse) => {}),
            this.$router.push({
              name: "korrespondenz",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nopersonselected"),
        });
    },

    details(geschaeftspartner) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: geschaeftspartner.id, anzeige: 0 },
      });
    },

    refresh() {
      this.resetLoading(this);
    },
  },
};
</script>

<style>
</style>
