<template>
  <div class="list">
    <navbar :title="$t('dashboard.presence')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="filters.termin"
                        :show-icon="true"
                        :tabindex="1"
                        field="termin"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.appointmentdate") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="filters.von"
                        :show-icon="true"
                        :tabindex="1"
                        field="von"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.fromdate") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.bis"
                        :tabindex="2"
                        field="bis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.untildate") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          filters['dozentenbuchung.veranstaltung.kuerzel']
                        "
                        class="form-control input"
                        type="text"
                        :tabindex="3"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          filters['dozentenbuchung.veranstaltung.bezeichnung']
                        "
                        :tabindex="4"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="dozent"
                      :person="filters.dozent"
                      :label="$t('global.lecturer')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :tabindex="5"
                      editable
                      @confirmed="setDozent"
                    />
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('dashboard.presence')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2"></div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    no-local-sorting
                    @sort-changed="sortingChanged"
                    fixed
                    @row-dblclicked="details"
                    :sort-compare="myCompare"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:cell(abgeschlossen)="row">
                      <font-awesome-icon
                        v-if="row.item.abgeschlossen"
                        style="color: green"
                        icon="fa-solid fa-check"
                      />
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_PRAESENZ } from "@/store/praesenz/actions.type";

export default {
  name: "anwesenheitsliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    SingleSelectPerson,
    DatePicker,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.attendances");
      },
    };
  },
  mixins: [page, dashboard, filter],
  data() {
    return {
      sortField: null,
      sortDir: false,
      isDozent: false,
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,
      sortBy: "bezeichnung",
      sortDesc: false,
      filters: { dozent: null },
      fields: [
        {
          key: "abgeschlossen",
          tdClass: "text-center",
          sortable: true,
          searchable: false,
          label: "",
        },
        {
          key: "dozentenbuchung",
          sortable: true,
          label: this.$t("global.lecturer"),
          formatter: (value) => {
            if (value) return `${value.person?.personName}`;
            else return "-";
          },
        },
        {
          key: "termin",
          sortable: true,
          label: this.$t("global.appointment"),
          formatter: (value) => {
            if (value)
              return `${value.von}  ${value.von_uhrzeit} - ${value.bis_uhrzeit}`;
            else return "-";
          },
        },
        {
          key: "dozentenbuchung.veranstaltung.kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "dozentenbuchung.veranstaltung.bezeichnung",
          sortable: true,
          label: this.$t("global.event"),
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      if (this.sortField) {
        params.sdir = this.sortDir;
        params.sfield = this.sortField;
      }

      //params.filterVon = this.setQueryParam(this.filters.von);
      params.filterTermin = this.setQueryParam(this.filters.termin);
      params.filterVon = this.setQueryParam(this.filters.von);

      params.filterBis = this.setQueryParam(this.filters.bis);

      params.filterVKuerzel = this.setQueryParam(
        this.filters["dozentenbuchung.veranstaltung.kuerzel"]
      );
      params.filterVBezeichnung = this.setQueryParam(
        this.filters["dozentenbuchung.veranstaltung.bezeichnung"]
      );

      params.filterDozent = this.setQueryParam(this.filters.dozent?.id);
      params.filterDBez = this.setQueryParam(this.filters.dozentenbuchung);

      return params;
    },
    praesenzstati: {
      get() {
        return this.$store.state.praesenz.praesenzstati;
      },
    },
  },

  created() {
    if (this.praesenzstati.length == 0)
      this.$store.dispatch(`praesenz/${LADE_AUSWAHLWERTE_PRAESENZ}`);

    this.initFilter("anwesenheiten-liste", "/bildung/praesenz-dozent/", true);
  },
  mounted() {
    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    ) {
      this.isDozent = true;
      this.setDozent(this.angemeldeterMitarbeiter.person);
    }

    this.$bus.$emit("searchWithFilter");

    this.setHeaders("global", [
      "",
      "lecturer",
      "appointment",
      "shortdesignation",
      "event",
    ]);
  },
  methods: {
    setDozent(dozent) {
      this.$set(this.filters, "dozent", dozent);
    },

    details(anwesenheitsliste) {
      this.$router.push({
        name: "anwesenheit",
        params: {
          vid: anwesenheitsliste.dozentenbuchung.veranstaltung.id,
          tid: anwesenheitsliste.termin.id,
        },
      });
    },
    sortingChanged(ctx) {
      /*if (key !== 'termin') { // erkennt so feld noch nicht :/.. further investigation needed
           console.log("sort ist nicht termin..")
        // If field is not `date` we let b-table handle the sorting
        return false
      } else { */
      console.log("sort ist termin..");
      this.sortDir = ctx.sortDesc;
      this.sortField = ctx.sortBy;
      this.resetLoading(this);
      //}
    },
  },
};
</script>

<style>
</style>
