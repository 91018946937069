//import Anmeldewarteliste from "@/pages/Dashboardlisten/Anmeldewarteliste";
import Anmeldeliste from "@/pages/Dashboardlisten/Anmeldungsliste";
import AnmeldeRechnungliste from "@/pages/Dashboardlisten/AnmeldungsRechnungListe";
import Anmeldung from "@/pages/Bildung/Anmeldung";
//import Lernzielvereinbarungsliste from "@/pages/Dashboardlisten/Lernzielvereinbarungsliste";

export default [
  /* {
    path: "/bildung/anmeldewarteliste",
    name: "anmeldungen-warte-liste",
    component: Anmeldewarteliste,
  }, */
  {
    path: "/bildung/anmeldungsliste",
    name: "anmeldungen-liste",
    component: Anmeldeliste,
  },
  {
    path: "/finanzen/anmeldungsliste",
    name: "rechnung-anmeldungen-liste",
    component: AnmeldeRechnungliste,
  },
 /*  {
    path: "/bildung/lernzielvereinbarung",
    name: "lernzielvereinbarungs-liste",
    component: Lernzielvereinbarungsliste,
  }, */
  {
    path: "/bildung/anmeldung/:anmeldungid?",
    name: "anmeldung",
    props: true,
    component: Anmeldung,
  },
  {
    path: "/bildung/anmeldung/:anmeldungid?/:anzeige",
    name: "anmeldung.anzeige",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        anmeldungid: route.params.anmeldungid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: Anmeldung,
  },
  {
    path: "/bildung/anmeldung/:veranstaltungid",
    name: "anmeldung.veranstaltung",
    props: true,
    component: Anmeldung,
  },
  {
    path: "/bildung/anmeldung/:personid",
    name: "anmeldung.person",
    props: true,
    component: Anmeldung,
  },
];
