<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4">
                <button class="btn btn-primary mr-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="mr-4">
                <button class="btn btn-danger" @click="oeffneLoeschenModal">
                  <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
                  <span>{{ $t("global.delete") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- DATEN AUS ANMELDEFORMULAR -->
      <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <container-headline
          :headline="$t('global.registration')"
          :col="6"
        ></container-headline>

        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungBeratungsgespraech.salutation"
                      class="form-control"
                      :placeholder="$t('global.salutation')"
                      readonly
                    />
                    <label>{{ $t("global.salutation") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungBeratungsgespraech.first_name"
                      class="form-control"
                      :placeholder="$t('global.firstname')"
                      readonly
                    />
                    <label>{{ $t("global.firstname") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungBeratungsgespraech.last_name"
                      class="form-control"
                      :placeholder="$t('global.surname')"
                      readonly
                    />
                    <label>{{ $t("global.surname") }}</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungBeratungsgespraech.phone"
                      class="form-control"
                      :placeholder="$t('global.phone')"
                      readonly
                    />
                    <label>{{ $t("global.phone") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungBeratungsgespraech.email"
                      class="form-control"
                      :placeholder="$t('global.email')"
                      readonly
                    />
                    <label>{{ $t("global.email") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungBeratungsgespraech.education_course"
                      class="form-control"
                      :placeholder="$t('global.interests')"
                      readonly
                    />
                    <label>{{ $t("global.interests") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      ref="messageTextarea"
                      v-model="anmeldungBeratungsgespraech.message"
                      class="form-control"
                      :placeholder="$t('global.message')"
                      readonly
                    />
                    <label>{{ $t("global.message") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="anmeldung-loeschen-modal"
      @confirmed="anmeldungLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>
	
	<script>
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageBox from "@/components/Modals/Messagebox";

import WebsiteApi from "@/WebsiteApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import store from "@/store";
import page from "@/mixins/Page";

export default {
  name: "WebseiteAnmeldungBeratungsgespraech",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
    LoeschenModal,
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      anmeldungBeratungsgespraech: {},
    };
  },
  computed: {
    navbarTitel: function () {
      return "Webseite Anmeldung Beratungsgespräch";
    },
  },
  created() {
    this.initializeAnmledung();
  },
  mounted: function () {},
  methods: {
    initializeAnmledung() {
      this.loading = true;

      WebsiteApi.get("consultation/", { params: { id: this.id } })
        .then((response) => {
          this.anmeldungBeratungsgespraech = response.data;
        })
        .finally(() => {
          this.setTextareaHeight();
          this.loading = false;
        });
    },
    setTextareaHeight() {
      const textarea = this.$refs.messageTextarea;
      textarea.setAttribute(
        "style",
        `height: ${textarea.scrollHeight}px !important;`
      );
    },
    abbrechen() {
      this.$router.push({
        name: "webseite-anmeldungen-liste",
        params: { anzeige: 1 },
      });
    },

    anmeldungLoeschen() {
      let json = [];
      json.push(this.id);
      WebsiteApi.delete("consultation/", {
        data: json,
      }).then(() => {
        this.$router.push({
          name: "webseite-anmeldungen-liste",
          params: { anzeige: 1 },
        });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "anmeldung-loeschen-modal");
    },
  },
};
</script>
	
	<style></style>
	