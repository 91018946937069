<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">{{ $t("global.newlecturerbooking") }}</h4>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-xl-12">
                <single-select-person
                  id="dozent-neue-dozentenbuchung"
                  :person="person"
                  :label="$t('global.person')"
                  :editable="true"
                  :rollen="filterRollen"
                  :allowOpen="false"
                  :required="true"
                  @confirmed="setPerson"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <single-select-veranstaltung
                  id="neueDozentenbuchung-veranstaltung"
                  :label="$t('global.event')"
                  :veranstaltung="gewaehlteVeranstaltung"
                  :allowOpen="false"
                  :required="true"
                  editable
                  @confirmed="setVeranstaltung"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="dozentenstatus"
                    class="mt-2 mb-3"
                    label="bezeichnung"
                    :options="dozentenstati"
                    :reduce="(dsl) => dsl.id"
                    :placeholder="$t('global.statuslecturer')"
                  >
                    <span slot="no-options">{{
                      $t("notification.taptosearch")
                    }}</span>
                  </v-select>
                  <label class="p-0 t-5">{{
                    $t("global.statuslecturer")
                  }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="abrechnungsstatus"
                    class="mt-2 mb-3"
                    label="bezeichnung"
                    :options="abrechnungsstati"
                    :reduce="(asl) => asl.id"
                    :placeholder="$t('global.statusbilling')"
                  >
                    <span slot="no-options">{{
                      $t("notification.taptosearch")
                    }}</span>
                  </v-select>
                  <label class="p-0 t-5">{{
                    $t("global.statusbilling")
                  }}</label>
                </div>
              </div>
            </div>
            <div class="row" v-if="person.id != null">
              <div class="col-xl-12 my-2">
                <div class="form-group-inline">
                  <label class="float-left p-0 t-5"
                    >{{ $t("global.wagerate") }}: </label
                  ><input
                    class="form-check-input float-right mt-1 mx-3"
                    :value="false"
                    v-model="lohnansatzAktiv"
                    type="checkbox"
                    :disabled="notValid"
                  />
                </div>
              </div>
            </div>
            <ValidationProvider rules="required" immediate>
            <div class="row" v-if="person.id != null && lohnansatzAktiv">
              <div class="col-xl-12">
                <div class="form-group p-0">
                  <v-select
                    v-model="lohnansatz"
                    class="mt-2 mb-3"
                    label="bezeichnung"
                    :options="lohnansaetze"
                    :reduce="(asl) => asl.id"
                    :placeholder="$t('global.wagerate')"
                  >
                    <span slot="no-options">{{
                      $t("notification.taptosearch")
                    }}</span>
                  </v-select>
                </div>
              </div>
            </div>
            </ValidationProvider>
            <div class="row" v-if="person.id != null && lohnansatzAktiv">
              <div class="col-xl-1 mb-3 text-right d-flex align-items-center">
                <span>{{ $t("global.chf") }}</span>
              </div>
              <div class="col-xl-3 mb-3">
                <div class="form-group">
                  <input
                    v-model.number="stundensatz"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.hourlyrate") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <v-select
                    v-model="lohnart"
                    class="mt-2 mb-3"
                    label="bezeichnung"
                    :options="lohnarten"
                    :reduce="(ll) => ll.id"
                    :placeholder="$t('global.wagetype')"
                  >
                    <span slot="no-options">{{
                      $t("notification.taptosearch")
                    }}</span>
                  </v-select>
                  <label class="p-0 t-5">{{ $t("global.wagetype") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="invalid"
              @click="speichern"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";
import { LADE_AUSWAHLWERTE_LOHN } from "@/store/lohn/actions.type";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "NeueDozentenbuchungModal",
  components: {
    SingleSelectPerson,
    ModalCloseButton,
    SingleSelectVeranstaltung,
  },
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      default: "neue-dozentenbuchung-modal",
    },
  },
  data() {
    return {
      show: false,
      person: { personName: textNotLinked },
      gewaehlteVeranstaltung: { bezeichnung: textNotLinked },
      //veranstaltungen: [],
      lohnansaetze: [],
      bezeichnung: null,
      dozentenstatus: null,
      abrechnungsstatus: null,
      total_lektionen: null,
      lohnansatz: null,
      lohnart: null,
      stundensatz: null,
      bemerkung: null,
      lohnansatzAktiv: false,
      notValid: true,

      filterRollen: this.$CONST("ROLLEN").DOZENTEN.concat(
        this.$CONST("ROLLEN").MITARBEITER
      ),
    };
  },
  computed: {
    dozentenstati: {
      get() {
        return this.$store.state.dozent.dozentenstati;
      },
    },
    abrechnungsstati: {
      get() {
        return this.$store.state.dozent.abrechnungsstati;
      },
    },
    lohnarten: {
      get() {
        return this.$store.state.lohn.lohnarten;
      },
    },
  },
  watch: {
    lohnansatz: {
      handler: function () {
        let lohnansatz = this.lohnansaetze.find(
          (ansatz) => ansatz.id == this.lohnansatz
        );
        if (lohnansatz) this.stundensatz = lohnansatz.ansatz;
      },
    },
    lohnansatzAktiv: {
      handler: function () {
        if (!this.lohnansatzAktiv) {
          this.lohnansatz = null;
        }
      },
    },
    dozentenstati: {
      handler: function (val) {
        if (val && this.dozentenstatus == null) {
          let dozentStatusGebucht = this.$CONST("DOZENTENSTATI").BOOKED;
          this.dozentenstatus = this.dozentenstati.find(
            (status) => status.id == dozentStatusGebucht
          );
        }
      },
      immediate: true,
    },
    abrechnungsstati: {
      handler: function (val) {
        if (val && this.abrechnungsstatus == null) {
          let dozentAbrechnungsStatusOffen =
            this.$CONST("ABRECHNUNGSTATI").OPEN;

          this.abrechnungsstatus = this.abrechnungsstati.find(
            (status) => status.id == dozentAbrechnungsStatusOffen
          );
        }
      },
      immediate: true,
    },
    lohnarten: {
      handler: function (val) {
        if (val && this.lohnart == null) {
          this.lohnart = this.lohnarten.find(
            (status) => status.id == this.$CONST("LOHNARTEN").LESSONS
          );
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$bus.$on("openNeueDozentenBuchungModal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.show = true;
      this.person = { personName: textNotLinked };

      if (!this.gewaehlteVeranstaltung.id && this.veranstaltung)
        Api.get("veranstaltungen/", {
          params: { id: this.veranstaltung },
        }).then((response) => (this.gewaehlteVeranstaltung = response.data));

      if (this.dozentenstati.length == 0)
        this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);

      if (this.lohnarten.length == 0)
        this.$store.dispatch(`lohn/${LADE_AUSWAHLWERTE_LOHN}`);

      $("#neue-dozentenbuchung-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.show = false;

      $("#neue-dozentenbuchung-modal").modal("hide");
      this.$emit("confirmed");
    },

    speichern() {
      var json = {
        action: "speichern",
        person: this.person.id ? this.person.id : this.person,
        veranstaltung: this.gewaehlteVeranstaltung.id
          ? this.gewaehlteVeranstaltung.id
          : this.veranstaltung,
        bezeichnung: this.bezeichnung,
        dozentenstatus: this.dozentenstatus.id
          ? this.dozentenstatus.id
          : this.dozentenstatus,
        abrechnungsstatus: this.abrechnungsstatus.id
          ? this.abrechnungsstatus.id
          : this.abrechnungsstatus,
        lohnansatz: this.lohnansatzAktiv
          ? this.lohnansatz.id
            ? this.lohnansatz.id
            : this.lohnansatz
          : null,
        lohnart: this.lohnart.id ? this.lohnart.id : this.lohnart,
        stundensatz: this.lohnansatzAktiv ? this.stundensatz : null,
        bemerkung: this.bemerkung,
      };

      Api.post("dozentenbuchung/", json)
        .then(() => {})
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.lecturerbookingsuccessfullysaved"),
          });
          this.closeModal();
        });
    },

    setPerson(person) {
      this.person = person;
      Api.get("lohn/lohnansatz/", {
        params: { person: person.id },
      }).then((response) => {
        this.lohnansaetze = response.data;

        if (response.data.length > 0) {
          let defaultValue = this.lohnansaetze.findIndex(
            (ansatz) => ansatz.standard == true
          );

          if (defaultValue == -1) defaultValue = 0;

          this.lohnansatz = response.data[defaultValue];
          this.stundensatz = response.data[defaultValue].ansatz;
          this.notValid = false;
          this.lohnansatzAktiv = true;
        } else if(response.data.length == 0) {
          this.notValid = true;
          this.lohnansatzAktiv = false;
        }
      });
      $("#myModal").modal("handleUpdate");
    },

    leereEingaben() {
      this.person = null;
      this.bezeichnung = null;
      this.dozentenstatus = null;
      this.abrechnungsstatus = null;
      this.lohnansatz = null;
      this.lohnart = null;
      this.stundensatz = null;
      this.bemerkung = null;
    },

    setVeranstaltung(veranstaltung) {
      this.gewaehlteVeranstaltung = veranstaltung;
    },
  },
};
</script>

<style>
</style>
