<template>
  <div
    id="app"
    :class="{ 'margin-top-70': $route && $route.name === 'Startseite' }"
  >
    <router-view
      v-if="
        (angemeldeterMitarbeiter && kategorienLoaded && permissions) ||
        loginError
      "
    ></router-view>
  </div>
</template>

<script>
import store from "@/store";
import {
  SET_PERMISSIONS,
  SET_MITARBEITER,
  SET_LAENDER,
  SET_ADRESSTYPEN,
  SET_BERECHTIGUNGEN,
  SET_GRUPPEN,
} from "@/store/mutations.type";

import { SET_EMAIL_TYPEN } from "@/store/geschaeftspartner/mutations.type";
import { LADE_SPRACHTEXTE } from "@/store/langtexts/actions.type";

import Api from "@/Api";

export default {
  name: "App",
  components: {},
  data() {
    return {
      kategorienLoaded: false,
      loginError: false,
    };
  },
  store,
  computed: {
    angemeldeterMitarbeiter: {
      get() {
        return this.$store.state.mitarbeiter;
      },
      set(value) {
        this.$store.commit(SET_MITARBEITER, value);
      },
    },
    permissions: {
      get() {
        return this.$store.state.permissions;
      },
      set(val) {
        this.$store.commit(SET_PERMISSIONS, val);
      },
    },
    langtexts: {
      get() {
        return this.$store.state.langtexts.texts;
      },
    },
    langLoaded: {
      get() {
        return this.$store.state.langtexts.loaded;
      },
    },
  },
  async created() {
    if (!this.angemeldeterMitarbeiter)
      try {
        let response = await Api.get("aktueller_mitarbeiter/");
        this.angemeldeterMitarbeiter = response.data.mitarbeiter;
        this.$store.commit(SET_BERECHTIGUNGEN, response.data.berechtigungen);
        this.$store.commit(SET_GRUPPEN, response.data.gruppen);

        this.angemeldeterMitarbeiter.dashboard = response.data.dashboard;

        this.angemeldeterMitarbeiter.dashboard.json = JSON.parse(
          response.data.dashboard.json
        );
        await this.ladeKategorien();
      } catch (e) {
        this.loginError = true;
      }

    if (this.langtexts.length == 0) {
      this.$store.dispatch(`langtexts/${LADE_SPRACHTEXTE}`);
    }
  },
  methods: {
    ladeBerechtigungen() {
      Api.get("security/permissions/").then((response) => {
        this.permissions = response.data;
      });
    },
    async ladeKategorien() {
      try {
        let response = await Api.get("kategorien/");
        this.$store.commit(SET_LAENDER, response.data.laender);
        this.$store.commit(
          `geschaeftspartner/${SET_EMAIL_TYPEN}`,
          response.data.emailtypen
        );
        this.$store.commit(SET_ADRESSTYPEN, response.data.adresstypen);
      } finally {
        this.kategorienLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./scss/app.scss";

.margin-top-70 {
  margin-top: 70px !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
