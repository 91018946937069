import {
	SET_ANMELDE_TYPEN,
	SET_ABLEHNUNG_GRUENDE,
	SET_ANMELDE_GRUENDE,
	SET_ABMELDE_GRUENDE,
	SET_ANMELDE_STATI,
	SET_ANMELDE_VERRECHNUNGEN,
	SET_ABSCHLUESSE,
	SET_KOMPETENZRASTERS,
	SET_NIVEAUS,
	SET_QUELLEN,
	SET_INTERESSEN_INFOABEND
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_ANMELDUNG } from "./actions.type";

import Api from "../../Api";

export default {
	namespaced: true,
	state: {
		anmeldetypen: [],
		ablehnunggruende: [],
		anmeldegruende: [],
		abmeldegruende: [],
		anmeldestati: [],
		verrechnungen: [],
		abschluesse: [],
		kompetenzrasters: [],
		niveaus: [],
		quellen: [],
		interessen_infoabend: []
	},
	mutations: {
		[SET_ANMELDE_TYPEN](state, val) {
			state.anmeldetypen = val;
		},
		[SET_ABLEHNUNG_GRUENDE](state, val) {
			state.ablehnunggruende = val;
		},
		[SET_ANMELDE_GRUENDE](state, val) {
			state.anmeldegruende = val;
		},
		[SET_ABMELDE_GRUENDE](state, val) {
			state.abmeldegruende = val;
		},
		[SET_ANMELDE_STATI](state, val) {
			state.anmeldestati = val;
		},
		[SET_ANMELDE_VERRECHNUNGEN](state, val) {
			state.verrechnungen = val;
		},
		[SET_ABSCHLUESSE](state, val) {
			state.abschluesse = val;
		},
		[SET_KOMPETENZRASTERS](state, val) {
			state.kompetenzrasters = val;
		},
		[SET_NIVEAUS](state, val) {
			state.niveaus = val;
		},
		[SET_QUELLEN](state, val) {
			state.quellen = val;
		},
		[SET_INTERESSEN_INFOABEND](state, val) {
			state.interessen_infoabend = val;
		},
	},
	actions: {
		async [LADE_AUSWAHLWERTE_ANMELDUNG](context) {
			let response = await Api.get("anmeldung/auswahlwerte/");
			context.commit(SET_ANMELDE_TYPEN, response.data.anmeldetypen);
			context.commit(SET_ABLEHNUNG_GRUENDE, response.data.ablehnunggruende);
			context.commit(SET_ANMELDE_GRUENDE, response.data.anmeldegruende);
			context.commit(SET_ABMELDE_GRUENDE, response.data.abmeldegruende);
			context.commit(SET_ANMELDE_STATI, response.data.anmeldestati);
			context.commit(SET_ANMELDE_VERRECHNUNGEN, response.data.verrechnungen);
			context.commit(SET_ABSCHLUESSE, response.data.abschluesse);
			context.commit(SET_KOMPETENZRASTERS, response.data.kompetenzraster);
			context.commit(SET_NIVEAUS, response.data.niveau);
			context.commit(SET_QUELLEN, response.data.quelle);
			context.commit(SET_INTERESSEN_INFOABEND, response.data.interessen_infoabend);
		},

		/*
		async [LADE_ABSCHLUESSE](context) {
		  let response = await Api.get("anmeldung/abschluesse/");
		  context.commit(SET_ABSCHLUESSE, response.data);
		}
		*/
	}
};
