 <template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.participants') + ' (' + teilnehmer.length + ')'"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="neuerTeilnehmer"
              v-if="editable"
            >
              <span class="mr-2">
                <font-awesome-icon icon="fa-solid fa-user-check" />
              </span>
              {{ $t("global.newparticipant") }}
            </button>

            <button
              class="btn btn-primary mr-2"
              @click="selectAll(true)"
              v-if="editable"
            >
              <span class="mr-2">
                <font-awesome-icon icon="fa-regular fa-ballot-check" />
              </span>
              {{ $t("global.selectall") }}
            </button>

            <button
              class="btn btn-primary mr-2"
              @click="selectAll(false)"
              v-if="editable && selectedIds.length > 0"
            >
              <span class="mr-2">
                <font-awesome-icon icon="fa-regular fa-ballot" />
              </span>
              {{ $t("global.deselectall") }}
            </button>

            <b-dropdown
              variant="primary mr-2"
              v-if="editable && selectedIds.length > 0"
            >
              <template slot="button-content">
                <span class="mr-2">
                  <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                </span>
                <span
                  >{{ $t("global.setstatus") }} ({{ selectedIds.length }})</span
                >
              </template>

              <b-dropdown-item
                v-for="(item, key) in kampagneteilnehmerstati"
                :key="key"
              >
                <span @click="statusSetzen(item.id)">
                  <span class="mr-2">
                    <font-awesome-icon :icon="item.icon" />
                  </span>
                  {{ item.bezeichnung }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable && selectedIds.length > 0"
            >
              <font-awesome-icon icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }} ({{ selectedIds.length }})</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div
            class="row ml-2"
            style="height: 400px; width: 99%; overflow-y: auto"
            :key="teilnehmerKey"
          >
            <div
              v-for="(item, index) in teilnehmer"
              v-bind:key="`teilnehmer-${index}`"
              class="col-xl-2 mt-2"
            >
              <div
                :style="
                  (item.selected
                    ? 'background-color: lightgreen; color:white; font-weight: bold'
                    : item.status.farbe) + ';cursor: pointer; font-size: 10pt'
                "
              >
                <div @click="selectTeilnehmer(item)" class="row">
                  <div class="col-xl-1">
                    <span class="ml-2">
                      <font-awesome-icon :icon="item.status.icon" />
                    </span>
                  </div>
                  <div class="col-xl-11">
                    {{ getFirma(item) }}
                  </div>
                  <div class="col-xl-11 offset-xl-1">
                    {{ item.person.personName }}
                  </div>
                  <div class="col-xl-12 offset-xl-1">
                    {{ getAdresse(item) }}
                  </div>
                  <div class="col-xl-12 offset-xl-1">
                    {{ item.person.telefon_g }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gpSucheModal
      :id="modalId"
      @confirmed="teilnehmerHinzufuegen"
    ></gpSucheModal>

    <loeschen-modal
      id="teilnehmer-loeschen-modal"
      @confirmed="teilnehmerLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import gpSucheModal from "@/components/Geschaeftspartner/gpSucheModal";
import { v4 as uuid } from "uuid";
import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faCalendarClock } from "@fortawesome/pro-duotone-svg-icons";
import {
  faBallot,
  faUserCheck,
  faBallotCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faXmark,
  faPersonRunning,
  faArrowRotateRight,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faCalendarClock,
  faCheck,
  faXmark,
  faPersonRunning,
  faArrowRotateRight,
  faBallot,
  faUserCheck,
  faBallotCheck
);

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    gpSucheModal,
  },
  mixins: [],
  props: {
    kampagne: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      teilnehmerKey: uuid(),
      teilnehmer: [],
      selectedIds: [],
      modalId: "TeilnehmerHinzufuegen",
    };
  },
  watch: {
    shown(val) {
      if (val && this.teilnehmer.length == 0) this.getTeilnehmer();
    },
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
    kampagneteilnehmerstati: {
      get() {
        return this.$store.state.crm.kampagneteilnehmerstati;
      },
    },
  },
  mounted() {
    if (this.kampagneteilnehmerstati.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);

    if (this.shown) this.getTeilnehmer();
  },
  created() {},
  methods: {
    getFirma(item) {
      return item.firma ? item.firma.name : "<ohne Firma>";
    },

    getAdresse(item) {
      if (!item.adresse) return "";

      return (
        item.adresse.strasse_nr +
        ", " +
        item.adresse.plz +
        " " +
        item.adresse.ort
      );
    },

    getTeilnehmer() {
      this.loading = true;
      Api.get("crm/kampagne/teilnehmer/", {
        params: {
          kampagne: this.kampagne,
        },
      }).then((response) => {
        this.teilnehmer = response.data;
        this.$bus.$emit("kampagne-teilnehmer-update", this.teilnehmer);
      });
    },

    statusSetzen(statusId) {
      let json = [];

      this.selectedIds.forEach((id) => {
        var foundIndex = this.teilnehmer.findIndex(
          (teilnehmer) => teilnehmer.id == id
        );

        json.push({
          id: this.teilnehmer[foundIndex].id,
          person: this.teilnehmer[foundIndex].person,
          status: statusId,
          kampagne: this.kampagne,
        });

        this.teilnehmer[foundIndex].status = this.kampagneteilnehmerstati.find(
          (status) => status.id == statusId
        );
      });

      this.selectedIds = [];
      this.teilnehmer.forEach((teilnehmer) => {
        teilnehmer.selected = false;
      });

      Api.put("crm/kampagne/teilnehmer/", {
        json,
        params: {
          kampagne: this.kampagne,
        },
      });

      this.$bus.$emit("kampagne-teilnehmer-update", this.teilnehmer);
    },

    selectAll(status) {
      this.teilnehmer.forEach((teilnehmer) => {
        teilnehmer.selected = status;
      });

      this.selectedIds = this.teilnehmer
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.teilnehmerKey = uuid();
    },

    selectTeilnehmer(teilnehmer) {
      let realItem = this.teilnehmer.find((item) => item.id == teilnehmer.id);
      realItem.selected = teilnehmer.selected ? false : true;

      this.selectedIds = this.teilnehmer
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.teilnehmerKey = uuid();
    },

    neuerTeilnehmer() {
      this.$bus.$emit("openGeschaeftspartnerModal", this.modalId);
    },

    teilnehmerHinzufuegen(selectedPerson) {
      let json = {
        kampagne: this.kampagne,
        person: selectedPerson[0],
        status: this.$CONST("CONFIG").KAMPAGNETEILNEHMERSTATUS,
      };

      Api.post("crm/kampagne/teilnehmer/", json)
        .then(() => {
          this.getTeilnehmer();

          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.campaignparticipantssavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "teilnehmer-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noparticipantsselected"),
        });
      }
    },

    teilnehmerLoeschen() {
      Api.delete("crm/kampagne/teilnehmer/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getTeilnehmer();
      });
    },
  },
};
</script>
