<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['person.personName']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.person") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="verantwortlicher"
                      :person="filters.singelSelectVerantwortlicher"
                      :label="$t('global.creator')"
                      :allowOpen="false"
                      editable
                      :rollen="filterMitarbeitende"
                      @confirmed="setVerantwortlicher"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-firma
                      id="aktivitaeten-firma"
                      :firma="filters.firma"
                      :label="$t('global.contactcompany')"
                      :allowOpen="false"
                      editable
                      @confirmed="setKontaktFirma"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2"></div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_lead.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(verantwortlicher)="row">
                      <!--
                      {{
                        row.item.verantwortlicher
                      }}-->

                      <div
                        v-for="(verantwortlicher, index) in row.item
                          .verantwortlicher"
                        :key="index"
                      >
                        {{ verantwortlicher.personName }}
                      </div>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loeschen-modal
      id="aktivitaeten-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />

    <messagebox-count
      :headerText="$t('global.personsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

export default {
  name: "Leads",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    SingleSelectPerson,
    SingleSelectFirma,
    FilterSearchResetButton,
  },
  mixins: [page, dashboard, filter],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      aktivitaetenliste: [],
      isDisabled: true,
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,

      fields: [
        { key: "selected", label: "" },
        {
          key: "geplantdatum",
          searchable: false,
          sortable: true,
          label: this.$t("global.planned"),
          formatter: (value, key, item) => {
            return value ? value + " - " + item.geplantzeit : "-";
          },
        },
        {
          key: "effektivdatum",
          searchable: false,
          sortable: true,
          label: this.$t("global.effective"),
          formatter: (value, key, item) => {
            return value ? value + " - " + item.effektivzeit : "-";
          },
        },
        {
          key: "typ.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.type"),
        },
        {
          key: "status.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "firma.name",
          sortable: true,
          label: this.$t("global.contactcompany"),
        },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.contactperson"),
        },
        {
          key: "verantwortlicher",
          searchable: false,
          sortable: true,
          label: this.$t("global.Responsible"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.pers = this.setQueryParam(this.filters["person.personName"]);
      params.resp = this.setQueryParam(
        this.filters.singelSelectVerantwortlicher?.id
      );
      params.filterCount = this.setQueryParam(this.filters.count);
      params.firma = this.setQueryParam(this.filters.firma?.id);
      params.bezeichnung = this.setQueryParam(this.filters["bezeichnung"]);
      params.firmName = this.setQueryParam(this.filters["firma.name"]);
      params.statusTB = this.setQueryParam(this.filters["status.bezeichnung"]);
      params.typ = this.setQueryParam(this.filters["typ.bezeichnung"]);

      return params;
    },

    navbarTitel: function () {
      return this.$t("dashboard.activities");
    },
  },
  created() {
    this.initFilter("aktivitaeten-liste", "crm/aktivitaet/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "planned",
      "effective",
      "",
      "status",
      "contactcompany",
      "contactperson",
      "Responsible",
      "designation",
    ]);

    this.setHeaders("dashboard", ["", "", "", "type"]);
  },
  methods: {
    details(aktivitaet) {
      this.$router.push({
        name: "aktivitaet",
        params: { id: aktivitaet.id, anzeige: 0 },
      });
    },

    loeschen() {
      this.delete("crm/aktivitaet/", this.selectedIds);
      /*
      Api.delete("crm/aktivitaet/", { data: this.selectedIds }).then(
        (response) => {
          this.schliesseLoeschenModal();

          this.listData = this.listData.filter(
            (v) => !response.data.success.includes(v.id)
          );
        }
      );
      */
    },

    schliesseLoeschenModal() {
      $("#aktivitaeten-loeschen-modal").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#aktivitaeten-loeschen-modal").modal("show");
    },

    setVerantwortlicher(person) {
      this.$set(this.filters, "verantwortlicher", person);
    },

    setKontaktFirma(firma) {
      this.$set(this.filters, "firma", firma);
    },

    setPerson(person) {
      this.$set(this.filters, "person", person);
    },
  },
};
</script>

<style>
</style>
