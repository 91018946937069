import WebseiteAnmeldungen from "@/pages/Dashboardlisten/WebseiteAnmeldungen";
import WebseiteAnmeldungInfoveranstaltung from "@/pages/Webseite/WebseiteAnmeldungInfoveranstaltung";
import WebseiteAnmeldungBeratungsgespraech from "@/pages/Webseite/WebseiteAnmeldungBeratungsgespraech";
import WebseiteAnmeldungEvent from "@/pages/Webseite/WebseiteAnmeldungEvent";

export default [
	{
		path: "/WebseiteAnmeldungen/:anzeige",
		name: "webseite-anmeldungen-liste",
		component: WebseiteAnmeldungen,
		props: (route) => {
			const a = parseInt(route.params.anzeige, 10);
			return {
				anzeige: isNaN(a) ? 0 : a,
			};
		},
	},
	{
		path: "/WebseiteAnmeldungInfoveranstaltung/:id",
		name: "webseite-anmeldung-infoveranstaltung",
		props: (route) => {
			return {
				id: route.params.id,
			};
		},
		component: WebseiteAnmeldungInfoveranstaltung,
	},
	{
		path: "/WebseiteAnmeldungBeratungsgespraech/:id",
		name: "webseite-anmeldung-beratungsgespraech",
		props: (route) => {
			return {
				id: route.params.id,
			};
		},
		component: WebseiteAnmeldungBeratungsgespraech,
	},
	{
		path: "/WebseiteAnmeldungEvent/:id",
		name: "webseite-anmeldung-event",
		props: (route) => {
			return {
				id: route.params.id,
			};
		},
		component: WebseiteAnmeldungEvent,
	},
];
