<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.websiteregistrations')"
              :anzahl="anzahlDaten"
              :anzahlTotal="anzahlTotal"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>

            <div class="row mb-2 mt-n2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 d-flex">
                    <button
                      class="btn btn-primary mr-2"
                      @click="oeffneAnmeldungFinalisierenmodal"
                      v-if="
                        veranstaltungstypen[aktiverVeranstaltungstyp]
                          .bezeichnung != 'Beratungsgespräche'
                      "
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-duotone fa-clipboard-check"
                      /></span>
                      {{ $t("global.finalizeregistrations") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_anmeldungen.delete"
                    >
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div
                class="block block-kopf mr-1 clickable"
                :style="getAktiverReiter(index)"
                @click="setAktiverVeranstaltungstyp(index)"
                v-for="(typ, index) in veranstaltungstypen"
                :key="'typ-' + index"
              >
                <span class="col primary-headline-text p-0"
                  ><router-link
                    class="primary-headline-text nav-link p-0"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'webseite-anmeldungen-liste',
                      params: {
                        anzeige: index,
                      },
                    }"
                    >{{ typ.anzeige }}</router-link
                  >
                </span>
              </div>
            </div>
            <div class="row block belegliste belegliste-small br-t-l-0">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="oeffneAnmeldung"
                  >
                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                          v-shortkey.once="['enter']"
                          @shortkey="filterSearch"
                        />
                        <b-form-checkbox
                          v-if="field.list == true"
                          v-model="filters[field.key]"
                          @input="filterSearch"
                        ></b-form-checkbox>
                      </td>
                    </template>

                    <template v-slot:cell(duplicate)="row">
                      <span v-if="row.item.duplicate_resolved == true"
                        ><font-awesome-icon
                          class="text-success text-center fs-18"
                          icon="fa-solid fa-check"
                      /></span>
                      <span v-else-if="row.value == true"
                        ><font-awesome-icon
                          class="text-danger text-center fs-18"
                          icon="fa-regular fa-circle-exclamation"
                      /></span>
                    </template>

                    <template v-slot:cell(event_slots)="row">
                      <div
                        class="text-center text-bold fs-14"
                        :class="'text-' + row.value.color"
                      >
                        {{
                          row.value.registrations +
                          " / " +
                          row.value.maxParticipants
                        }}
                      </div>
                    </template>

                    <template v-slot:cell(chevron)="row">
                      <div
                        v-if="checkDuplicate(row.item)"
                        class="clickable w-50-px text-center"
                        @click="toggleRowDetailsShowing(row)"
                      >
                        <font-awesome-icon
                          :icon="
                            getRowDetailsShowing(row)
                              ? 'fa-solid fa-chevron-up'
                              : 'fa-solid fa-chevron-down'
                          "
                        />
                      </div>
                    </template>

                    <template #row-details="row">
                      <div
                        class="p-3 bg-light d-flex align-items-center"
                        v-if="getRowDetailsShowing(row)"
                      >
                        <div class="col-9">
                          <b-table-simple borderless striped small>
                            <b-thead>
                              <b-tr>
                                <b-th></b-th>
                                <b-th>{{ $t("global.personnumber") }}</b-th>
                                <b-th>{{ $t("global.surname") }}</b-th>
                                <b-th>{{ $t("global.firstname") }}</b-th>
                                <b-th>{{ $t("global.address") }}</b-th>
                                <b-th>{{ $t("global.zip") }}</b-th>
                                <b-th>{{ $t("global.city") }}</b-th>
                                <b-th>{{ $t("global.phone") }}</b-th>
                                <b-th>{{ $t("global.email") }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr>
                                <b-td class="text-bold">
                                  {{ $t("global.datafromregistrationform") }}:
                                </b-td>
                                <b-td>-</b-td>
                                <b-td>{{ row.item.last_name }}</b-td>
                                <b-td>{{ row.item.first_name }}</b-td>
                                <b-td>{{ row.item.adress }}</b-td>
                                <b-td>{{ row.item.zip }}</b-td>
                                <b-td>{{ row.item.city }}</b-td>
                                <b-td>{{ row.item.phone }}</b-td>
                                <b-td>{{ row.item.email }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="text-bold">
                                  {{ $t("global.foundpersoninsystem") }}:
                                </b-td>
                                <b-td>{{
                                  row.item.master_gp.person_number
                                }}</b-td>
                                <b-td>{{ row.item.master_gp.last_name }}</b-td>
                                <b-td>{{ row.item.master_gp.first_name }}</b-td>
                                <b-td>{{ row.item.master_gp.adress }}</b-td>
                                <b-td>{{ row.item.master_gp.zip }}</b-td>
                                <b-td>{{ row.item.master_gp.city }}</b-td>
                                <b-td>{{
                                  formattedPhoneNumber(row.item.master_gp.phone)
                                }}</b-td>
                                <b-td>{{ row.item.master_gp.email }}</b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                        <div class="col-3 d-flex flex-column ml-auto">
                          <button
                            class="btn btn-secondary d-flex align-items-center mb-2"
                            @click="oeffnePerson(row.item.master_gp.id)"
                            v-if="berechtigungen.m_anmeldungen.delete"
                          >
                            <font-awesome-icon
                              class="ml-1"
                              icon="fa-solid fa-user-pen"
                            />
                            <span class="mx-auto">{{
                              $t("global.editexistingperson")
                            }}</span>
                          </button>
                          <button
                            class="btn btn-primary d-flex align-items-center mb-2"
                            @click="
                              oeffneAnmeldungBestehendePersonModal(row.item)
                            "
                            v-if="berechtigungen.m_anmeldungen.delete"
                          >
                            <font-awesome-icon
                              class="ml-1"
                              icon="fa-solid fa-people-arrows-left-right"
                            />
                            <span class="mx-auto">{{
                              $t("global.linkwithexistingperson")
                            }}</span>
                          </button>
                          <button
                            class="btn btn-success d-flex align-items-center"
                            @click="oeffneAnmeldungNeuePersonModal(row.item)"
                            v-if="berechtigungen.m_anmeldungen.delete"
                          >
                            <font-awesome-icon
                              class="ml-1"
                              icon="fa-solid fa-user-plus"
                            />
                            <span class="mx-auto">{{
                              $t("global.createnewperson")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    ref="infiniteLoader"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loeschen-modal
      id="anmeldungen-webseite-loeschen-modal"
      @confirmed="anmeldungenLoeschen"
      :selectedIds="selectedIds"
    />
    <loading-overlay v-show="loading"></loading-overlay>
    <message-box
      id="anmeldung-neue-person-modal"
      :headerText="$t('global.createnewperson')"
      :text="messageTextNeuePerson"
      :ok="true"
      :cancelText="$t('global.cancel')"
      :repeat="false"
      :okText="$t('global.ok')"
      @ok="neuePersonErstellen"
      @cancel="aktiveDubletteZuruecksetzen"
    />
    <message-box
      id="anmeldung-bestehende-person-modal"
      :headerText="$t('global.linkwithexistingperson')"
      :text="messageTextBestehendePerson"
      :ok="true"
      :cancelText="$t('global.cancel')"
      :repeat="false"
      :okText="$t('global.ok')"
      @ok="bestehendePersonVerknuepfen"
      @cancel="aktiveDubletteZuruecksetzen"
    />
    <message-box
      id="anmeldung-finalisieren-modal"
      :headerText="$t('global.finalizeregistrations')"
      :text="messageAnmeldungenFinalisieren"
      :ok="true"
      :cancelText="$t('global.cancel')"
      :repeat="false"
      :okText="$t('global.ok')"
      @ok="pruefeAnmeldungen"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import WebsiteApi from "@/WebsiteApi";

import websiteApiDashboard from "@/mixins/WebsiteApiDashboard";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageBox from "@/components/Modals/Messagebox";

export default {
  name: "WebseiteAnmeldungenListe",
  components: {
    Navbar,
    HeadMenu,
    LoeschenModal,
    MessageBox,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.websiteregistrations");
      },
    };
  },
  mixins: [page, websiteApiDashboard],
  props: {
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  store,
  data() {
    return {
      sortBy: "name",
      sortDesc: false,
      aktiverVeranstaltungstyp: 0,
      expandedRows: new Set(),
      aktiveDublette: null,
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.is_duplicate = this.filters.duplicate;
      params.first_name = this.filters.first_name;
      params.last_name = this.filters.last_name;
      params.education_course = this.filters.education_course;
      params.short_designation = this.filters["event.short_designation"];
      params.designation = this.filters["event.designation"];

      if (this.filters.size && this.filters.size != "") {
        params.size = this.filters.size;
      }

      return params;
    },
    fields() {
      if (
        this.veranstaltungstypen[this.aktiverVeranstaltungstyp].bezeichnung ==
          "Infoveranstaltungen" ||
        this.veranstaltungstypen[this.aktiverVeranstaltungstyp].bezeichnung ==
          "Events"
      ) {
        return [
          { key: "selected", label: "" },
          {
            key: "duplicate",
            sortable: true,
            searchable: false,
            list: true,
            label: this.$t("global.duplicates"),
            formatter: (value, key, item) => {
              const isDuplicate = this.checkDuplicate(item);
              if (isDuplicate) {
                return true;
              } else {
                return false;
              }
            },
          },
          {
            key: "last_name",
            sortable: true,
            searchable: true,
            label: this.$t("global.surname"),
          },
          {
            key: "first_name",
            sortable: true,
            searchable: true,
            label: this.$t("global.firstname"),
          },
          {
            key: "event.short_designation",
            sortable: true,
            searchable: true,
            label: this.$t("global.shortdesignation"),
          },
          {
            key: "event.designation",
            sortable: true,
            searchable: true,
            label: this.$t("global.designation"),
          },
          {
            key: "creation_date",
            sortable: true,
            searchable: false,
            label: this.$t("global.date"),
          },
          {
            key: "event_slots",
            sortable: true,
            searchable: false,
            label: this.$t("global.openeventslots"),
            formatter: (value, key, item) => {
              return this.checkOpenSlots(item);
            },
          },
          {
            key: "chevron",
            sortable: false,
            searchable: false,
            label: "",
          },
        ];
      } else if (
        this.veranstaltungstypen[this.aktiverVeranstaltungstyp].bezeichnung ==
        "Beratungsgespräche"
      ) {
        return [
          { key: "selected", label: "" },
          {
            key: "last_name",
            sortable: true,
            searchable: true,
            label: this.$t("global.surname"),
          },
          {
            key: "first_name",
            sortable: true,
            searchable: true,
            label: this.$t("global.firstname"),
          },
          {
            key: "education_course",
            sortable: true,
            searchable: true,
            label: this.$t("global.interests"),
          },
          {
            key: "message",
            sortable: true,
            searchable: false,
            label: this.$t("global.message"),
          },
          {
            key: "creation_date",
            sortable: true,
            searchable: false,
            label: this.$t("global.date"),
          },
        ];
      } else {
        return [];
      }
    },
    veranstaltungstypen() {
      return [
        {
          bezeichnung: "Infoveranstaltungen",
          anzeige: this.$t("global.infoevents"),
          call: "information-event/",
          route: "webseite-anmeldung-infoveranstaltung",
        },
        {
          bezeichnung: "Beratungsgespräche",
          anzeige: this.$t("global.consultations"),
          call: "consultation/",
          route: "webseite-anmeldung-beratungsgespraech",
        },
        {
          bezeichnung: "Events",
          anzeige: this.$t("dashboard.events"),
          call: "event/",
          route: "webseite-anmeldung-event",
        },
      ];
    },
    messageTextNeuePerson() {
      return this.$t("global.messagetextnewperson");
    },
    messageTextBestehendePerson() {
      return this.$t("global.messagetextexistingperson");
    },
    messageAnmeldungenFinalisieren() {
      return this.$t("global.messagetextnewperson");
    },
  },

  watch: {
    async aktiverVeranstaltungstyp() {
      let isFirstCall = this.lastWebsiteApiCall ? false : true;
      await this.initializeAnmeldungen();
      if (!isFirstCall) this.resetLoading(this);
    },
  },
  created() {
    this.aktiverVeranstaltungstyp = this.$route.params.anzeige
      ? this.$route.params.anzeige
      : 0;
    if (this.listData.length === 0) {
      this.initializeAnmeldungen();
    }
  },
  mounted() {},
  methods: {
    initializeAnmeldungen() {
      this.initFilter(
        "webseite-anmeldungen-liste",
        this.veranstaltungstypen[this.aktiverVeranstaltungstyp].call
      );
    },
    formattedPhoneNumber(phone) {
      if (!phone) return "";
      const numbers = phone.split(",").map((num) => num.trim());
      const validNumbers = numbers.filter((num) => num);
      const length = validNumbers.length;

      switch (length) {
        case 0:
          return "";
        case 1:
          return validNumbers[0];
        case 2:
          return validNumbers.join(", ");
        default:
          return `${validNumbers[0]}, ${validNumbers[1]}, ${validNumbers[2]}`;
      }
    },
    getAktiverReiter(index) {
      if (this.aktiverVeranstaltungstyp != index) {
        return { background: "#A3BAC6" };
      }
    },
    setAktiverVeranstaltungstyp() {
      if (!this.loading) {
        if (this.aktiverVeranstaltungstyp != this.$route.params.anzeige)
          this.aktiverVeranstaltungstyp = this.$route.params.anzeige;
      }
    },
    checkDuplicate(anmeldung) {
      if (anmeldung.duplicate_resolved == true) {
        return false;
      } else if (
        !anmeldung.duplicate_resolved &&
        anmeldung.person === null &&
        anmeldung.master_gp
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkOpenSlots(anmeldung) {
      const maxParticipants = anmeldung?.event?.max_participants || 0;
      const registrations = anmeldung?.event?.registrations || 0;
      const filledPercentage = registrations / maxParticipants || 0;

      const slots = {
        registrations: registrations,
        maxParticipants: maxParticipants,
      };
      if (filledPercentage < 0.1 || maxParticipants === 0) {
        //slots.color = "medium-blue";
        slots.color = "success"; //10% Belegung
      } else if (filledPercentage > 0.75 && filledPercentage < 1) {
        slots.color = "warning"; //Über 75% Belegung aber nocht nicht voll
      } else if (filledPercentage >= 1) {
        slots.color = "danger"; //voll
      } else {
        slots.color = "success"; //zwischen 10% und 75% Belegung
      }

      return slots;
    },
    getRowDetailsShowing(row) {
      return this.expandedRows.has(row.item.id);
    },
    toggleRowDetailsShowing(row) {
      if (this.expandedRows.has(row.item.id)) {
        this.expandedRows.delete(row.item.id);
      } else {
        this.expandedRows.add(row.item.id);
      }

      this.$set(row.item, "_showDetails", !row.item._showDetails);
    },
    refresh() {
      this.resetLoading(this);
    },
    oeffneAnmeldung(anmeldung) {
      this.$router.push({
        name: this.veranstaltungstypen[this.aktiverVeranstaltungstyp].route,
        params: { id: anmeldung.id },
      });
    },
    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit(
          "openLoeschenModal",
          "anmeldungen-webseite-loeschen-modal"
        );
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselecteddeletion"),
        });
      }
    },
    anmeldungenLoeschen() {
      WebsiteApi.delete(
        this.veranstaltungstypen[this.aktiverVeranstaltungstyp].call,
        { data: this.selectedIds }
      )
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.registrationsdeleted"),
          });
        })
        .finally(() => {
          this.resetLoading(this);
        });
    },
    oeffneAnmeldungFinalisierenmodal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("open-modal", "anmeldung-finalisieren-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselected"),
        });
      }
    },
    anmeldungenFinalisieren() {
      let json = [];
      this.selectedObjects.forEach((anmeldungen) => {
        json.push(anmeldungen);
      });

      WebsiteApi.post("finalize-registration/", json).then(() => {
        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.$t("global.registrationscreated"),
        });

        this.resetLoading(this);
      });
    },
    pruefeAnmeldungen() {
      let dublettenKonflikt = false;
      this.selectedObjects.forEach((anmeldung) => {
        if (!anmeldung.duplicate_resolved && anmeldung.master_gp) {
          return (dublettenKonflikt = true);
        }
      });
      if (dublettenKonflikt) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.duplicateconflictmessage"),
        });
      } else {
        this.pruefeFreiePlaetze();
      }
    },
    pruefeFreiePlaetze() {
      let events = [];
      let fullEvent = null;

      this.selectedObjects.forEach((data) => {
        const eventCopy = { ...data.event };
        events.push(eventCopy);
      });

      const eventMap = {};
      events.forEach((event) => {
        const eventId = event?.id;
        if (eventId && !eventMap[eventId]) {
          eventMap[eventId] = event;
        }
        if (
          eventMap[eventId].registrations < eventMap[eventId].max_participants
        ) {
          eventMap[eventId].registrations++;
        } else {
          fullEvent = event;
          return;
        }
      });

      if (fullEvent) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text:
            this.$t("notification.messageeventisfull") +
            ": " +
            fullEvent.designation,
          duration: 15000,
        });
      } else {
        this.anmeldungenFinalisieren();
      }
    },
    oeffneAnmeldungNeuePersonModal(anmeldung) {
      this.$bus.$emit("open-modal", "anmeldung-neue-person-modal");
      this.aktiveDublette = anmeldung;
    },
    oeffneAnmeldungBestehendePersonModal(anmeldung) {
      this.$bus.$emit("open-modal", "anmeldung-bestehende-person-modal");
      this.aktiveDublette = anmeldung;
    },
    oeffnePerson(personID) {
      let route = this.$router.resolve({
        name: "geschaeftspartner",
        params: { id: personID, anzeige: 0 },
      });
      window.open(route.href, "_blank");
    },
    neuePersonErstellen() {
      this.loading = true;

      WebsiteApi.put(
        "information-event/" + this.aktiveDublette.id + "/",
        this.aktiveDublette,
        {
          params: { new_person: true },
        }
      )
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("global.newpersoncreatedsuccess"),
          });
          this.anmeldungInfoveranstaltung = response.data;
        })
        .finally(() => {
          this.aktiveDubletteZuruecksetzen();
          this.loading = false;
          this.resetLoading(this);
        });
    },
    bestehendePersonVerknuepfen() {
      WebsiteApi.put(
        this.veranstaltungstypen[this.aktiverVeranstaltungstyp].call +
          this.aktiveDublette.id +
          "/",
        this.aktiveDublette,
        {
          params: { new_person: false },
        }
      )
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("global.personlinkedsuccess"),
          });
          this.anmeldungInfoveranstaltung = response.data;
        })
        .finally(() => {
          this.aktiveDubletteZuruecksetzen();
          this.loading = false;
          this.resetLoading(this);
        });
    },
    aktiveDubletteZuruecksetzen() {
      this.aktiveDublette = null;
    },
  },
};
</script>

<style></style>
