<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="
                  $t('global.Subject') +
                  (fach.bezeichnung ? ': ' + fach.bezeichnung : '')
                "
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="showDozenteneinsatzAdministrieren"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-user-clock"
                      class="mr-2"
                    />{{ $t("global.administerlecturerdeployment") }}
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="semesterplanungDrucken"
                    v-if="editable"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-print"
                      class="mr-1"
                    />
                    {{ $t("global.printSemesterPlan") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="editable && berechtigungen.b_bildung_fach.delete"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <fach-stammdaten
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="false"
            :veranstaltungsstati="veranstaltungsstati"
            :lehrmethoden="lehrmethoden"
            :niveaus="niveaus"
            :faecher="faecher"
          />

          <fach-lektionen
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="false"
            :istModulfach="istModulfach"
          />
        </div>

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <fach-bildungsgang
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="false"
          />

          <modul-informationen
            :v-if="fachid"
            :fach="fach"
            :modulID="fach.fach ? fach.fach.id : null"
            :faecher="faecher"
            :editable="editable"
            :isTemplate="false"
          />

          <fach-teilnehmer
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <div v-if="fachid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'fach',
                      params: { fachid: fachid, anzeige: 0 },
                    }"
                    >{{ $t("global.registration") }}</router-link
                  >
                </li>
                <li class="nav-item" v-if="berechtigungen.m_dozierende.read">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'fach',
                      params: { fachid: fachid, anzeige: 1 },
                    }"
                    >{{ $t("global.lecturerbooking") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'fach',
                      params: { fachid: fachid, anzeige: 2 },
                    }"
                    >{{ $t("global.appointment") }}</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="berechtigungen.b_bildung_noteneingabe.read"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'fach',
                      params: { fachid: fachid, anzeige: 3 },
                    }"
                    >{{ $t("dashboard.scoreinput") }}</router-link
                  >
                </li>
                <li
                  v-if="
                    berechtigungen.m_journal.read ||
                    berechtigungen.m_dozierende.read
                  "
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 4 }"
                    :to="{
                      name: 'fach',
                      params: { fachid: fachid, anzeige: 4 },
                    }"
                    >{{ $t("global.presencejournal") }}</router-link
                  >
                </li>
              </ul>
            </div>

            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <anmeldungsliste
                    :veranstaltung="fachid"
                    @anmeldungen-uebertragen="anmeldungenUebertragen"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div
                  v-show="anzeige === 1"
                  v-if="berechtigungen.m_dozierende.read"
                >
                  <dozentenbuchungsliste
                    :veranstaltung="fachid"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                    :getListeKey="getListeKey"
                    @dbUpdated="terminlisteKey++"
                  />
                </div>
                <div v-show="anzeige === 2">
                  <terminliste
                    :veranstaltung="fachid"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                    :key="terminlisteKey"
                    @updated="terminlisteKey++"
                  />
                </div>
                <div
                  v-show="anzeige === 3"
                  v-if="berechtigungen.b_bildung_noteneingabe.read"
                >
                  <noteneingabeliste
                    :veranstaltung="fachid"
                    :shown="anzeige == 3 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div
                  v-show="anzeige === 4"
                  v-if="
                    berechtigungen.m_journal.read ||
                    berechtigungen.m_dozierende.read
                  "
                >
                  <praesenzjournalliste
                    :fachid="fachid"
                    fkey=""
                    ftable=""
                    :editable="editable"
                    :shown="anzeige == 4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loeschen-modal id="fach-loeschen-modal" @confirmed="fachloeschen" />

    <dozenteneinsatz-administrieren-modal
      v-if="fachid"
      :id="DozenteneinsatzAdministrierenModalId"
      :veranstaltung="fachid"
      :bildungsbezeichnung="fach.bezeichnung"
      @dozentChanged="terminlisteKey++"
    ></dozenteneinsatz-administrieren-modal>

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayerFach"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import FachStammdaten from "@/components/Bildung/Fach/Stammdaten";
import ModulInformationen from "@/components/Bildung/Fach/Modulinformationen";
import FachLektionen from "@/components/Bildung/Fach/Lektionen";
import FachBildungsgang from "@/components/Bildung/Fach/Bildungsgang";
import FachTeilnehmer from "@/components/Bildung/Fach/Teilnehmer";

import Anmeldungsliste from "@/components/Reiter/Anmeldungsliste";
import Noteneingabeliste from "@/components/Reiter/Noteneingabeliste";
import Terminliste from "@/components/Reiter/Terminliste";
import Dozentenbuchungsliste from "@/components/Reiter/Dozentenbuchungsliste";
import Praesenzjournalliste from "@/components/Reiter/Praesenzjournalliste.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import DozenteneinsatzAdministrierenModal from "@/components/Modals/DozenteneinsatzAdministrierenModal";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import store from "@/store";
import server from "@/server";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import { textNotLinked } from "@/defaultTexts.js";

import ChangeWatcher from "@/mixins/ChangeWatcher";

export default {
  name: "Fach",
  components: {
    Anmeldungsliste,
    Noteneingabeliste,
    Dozentenbuchungsliste,
    Terminliste,
    HeadMenu,
    Navbar,
    DozenteneinsatzAdministrierenModal,
    LoeschenModal,
    FachStammdaten,
    FachBildungsgang,
    FachLektionen,
    FachTeilnehmer,
    ModulInformationen,
    Praesenzjournalliste,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcher],
  store,

  props: {
    fachid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    kategorieProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pdfPath: "",
      terminlisteKey: 0,
      getListeKey: 0,
      istModulfach: false,
      fach: {
        lehrgang: {
          bezeichnung: textNotLinked,
          lehrgangstatus: { bezeichnung: textNotLinked },
        },
      },
      DozenteneinsatzAdministrierenModalId:
        "dozenteneinsatz-administrieren-modal",
    };
  },
  watch: {
    faecherModulID(val) {
      let modulID = val[0];
      let faecher = val[1];

      if (modulID && faecher) {
        let modul = faecher.find((md) => md.id == modulID);

        this.fach.modulgruppe = modul?.modulgruppe;
        this.fach.moduleinsatz = modul?.moduleinsatz;
        this.fach.modultyp = modul?.modultyp;
        this.fach.modulbezeichnung = modul?.bezeichnung;
        this.fach.leistungspunkte = modul?.cp;
        if (!this.fach.bezeichnung) {
          this.fach.bezeichnung = modul?.bezeichnung;
        }

        if (!this.fach.kuerzel) {
          this.fach.kuerzel = modul?.kuerzel;
        }
      }
    },
  },
  computed: {
    faecherModulID() {
      //Für Watch trigger auf zwei Variabeln
      return [this.fach?.fach?.id, this.faecher];
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    lehrmethoden: {
      get() {
        return this.$store.state.veranstaltung.lehrmethoden;
      },
    },
    niveaus: {
      get() {
        return this.$store.state.veranstaltung.niveau;
      },
    },
    faecher: {
      get() {
        let faecherFormatted = this.$store.state.veranstaltung.faecher;

        faecherFormatted.forEach((f) => {
          f.anzeige = f.kuerzel + " " + f.bezeichnung;
        });
        return faecherFormatted;
      },
    },
  },

  created() {
    if (this.fachid) {
      this.loading = true;

      Api.get("veranstaltungen/", { params: { id: this.fachid } })
        .then((response) => {
          this.initializeFach(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.editable = true;
    }
  },
  mounted: function () {
    this.$bus.$on("lektionenTotal", (lektionenTotal) => {
      if (this.fach.lektionen_total == lektionenTotal) return;

      this.fach.lektionen_total = lektionenTotal;
      //SWE: Ist bisschen unschön da beim Laden eines Fachs immer noch ein put gemacht wird und ab und zu zu Fehlern führt
      //DAP: Update mit SWE beim Laden des Reiters Termine wird geschaut ob das Total der Lektionen geändert hat und speichert das dann hier
      this.speichernLektionenTotal();
    });
    this.getListeKey++;
    this.terminlisteKey++;

    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "faecher-liste" });
    },

    initializeFach(fach) {
      if (fach) {
        this.fach = fach;

        if (!this.fach.lehrgang)
          this.fach.lehrgang = {
            bezeichnung: textNotLinked,
            lehrgangstatus: { bezeichnung: textNotLinked },
          };

        if (!this.fach.lehrgang.lehrgangstatus)
          // Datenfail in der Datenbank Lehrgang ohne Status
          this.fach.lehrgang.lehrgangstatus = { bezeichnung: textNotLinked };

        if (this.fach.lehrgang.bildungsgangtyp) {
          const MODULUNTERRICHT = "1821FEA3F48";
          this.istModulfach =
            this.fach.lehrgang.bildungsgangtyp.id == MODULUNTERRICHT;
        }

        if (this.berechtigungen.b_bildung_fach.update) {
          this.editable = this.$CONST("EVENTEDITABLESTATI").includes(
            this.fach.veranstaltungsstatus.id
          )
            ? true
            : false;
        } else this.editable = false;

        this.initChangeWatcher(this.fach);
      } else {
        console.error("Fach ist null");
      }
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.fach);

      // Relationen auf ID reduzieren

      json.veranstaltungsstatus = this.fach.veranstaltungsstatus.id;
      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").MODULE;
      json.lektionenDifferenz =
        this.fach.lektionenSoll - this.fach.lektionenTotal;

      if (this.fach.lehrgang) json.lehrgang = this.fach.lehrgang.id;
      if (this.fach.lehrmethode) json.lehrmethode = this.fach.lehrmethode.id;

      Api.put("fach/", json, { params: { id: this.fachid } })
        .then((response) => {
          this.initializeFach(response.data);
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.subjectsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    speichernLektionenTotal() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.fach);

      // Relationen auf ID reduzieren

      json.veranstaltungsstatus = this.fach.veranstaltungsstatus.id;
      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").MODULE;

      if (this.fach.lehrgang) json.lehrgang = this.fach.lehrgang.id;
      if (this.fach.lehrmethode) json.lehrmethode = this.fach.lehrmethode.id;

      Api.put("fach/", json, { params: { id: this.fachid } })
        .then((response) => {
          this.initializeFach(response.data);
        })
        .catch((e) => {
          //this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "fach-loeschen-modal");
    },

    fachloeschen() {
      Api.delete("fach/", {
        params: { id: this.fachid },
      }).then(() => {
        this.$router.push({ name: "faecher-liste" });
      });
    },

    anmeldungenUebertragen(anmeldungsIds) {
      this.anmeldungsliste = this.anmeldungsliste.filter(
        (a) => !anmeldungsIds.includes(a.id)
      );
    },

    showDozenteneinsatzAdministrieren() {
      $("#" + this.DozenteneinsatzAdministrierenModalId).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.$bus.$emit("openDozenteneinsatzAdministrierenModal", null);
    },

    semesterplanungDrucken() {
      let datum = new Date().toLocaleDateString("de-DE");
      let [day, month, year] = datum.split(".");

      let filename =
        "Semesterplan_" +
        this.fach.bezeichnung.replace(/\s/g, "") +
        "_" +
        day +
        "-" +
        month +
        "-" +
        year;
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/semesterplan.htm&ids=${this.fach.id}`;

      this.$refs.pdfLayerFach.show();
    },
  },
};
</script>

<style>
</style>
