<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.korrespondenznummer"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.correspondencenum") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betreff"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.subject") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.person"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.receiver") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        @updatefull="setFilterDate"
                        :initial="filters.von"
                        field="von"
                      />
                      <label>{{ $t("global.from") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        @updatefull="setFilterDate"
                        :initial="filters.bis"
                        field="bis"
                      />
                      <label>{{ $t("global.till") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.verarbeitungsStatus"
                        :options="verarbeitungsstati"
                        label="bezeichnung"
                        :placeholder="$t('global.processingstatus')"
                        :reduce="(vst) => vst.id"
                      ></v-select>
                      <label>{{ $t("global.processingstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.correspondencelist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <router-link
                      v-if="berechtigungen.m_korrespondenz.create"
                      class="btn btn-success"
                      :to="{ name: 'korrespondenz', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                    <button
                      class="btn btn-primary ml-2"
                      v-if="berechtigungen.m_korrespondenz.create"
                      @click="massenkorrespondenzErstellen"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-memo"
                        class="mr-2"
                      />
                      {{ $t("global.createserialletter") }}
                    </button>
                    <!-- Button vorerst ausgeblendet, siehe Issue BXEDU-313 (25.05.2022)
                    <button
                      class="btn btn-primary ml-2"
                      @click="ausgebenKontaktlistePdf"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      Kontaktliste
                    </button>
                    -->

                    <b-dropdown variant="primary ml-2">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-print"
                          class="mr-2"
                        />
                        {{ $t("global.print") }}
                      </template>
                      <b-dropdown-item
                        @click="ausgebenKorrespondenzPdfCache(true)"
                      >
                        {{ $t("global.selected") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="ausgebenKorrespondenzPdfCache(false)"
                      >
                        {{ $t("global.alldisplayed") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_korrespondenz.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="korrespondenz-loeschen-modal"
      @confirmed="loeschen"
      :selectedIds="selectedIds"
      :multiple="true"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";
import DatePicker from "@/components/Datepicker";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import server from "@/server";

export default {
  name: "Korrespondenzliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    FilterSearchResetButton,
    DatePicker,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.correspondence");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      pdfPath: "",
      pdfFiles: [],
      fields: [
        { key: "selected", label: "" },
        {
          key: "person",
          sortable: true,
          label: this.$t("global.receiver"),
          formatter: (value, key, item) => {
            if (value) {
              return item.isMassKorrespondenz
                ? "Serienbrief"
                : value[0].personName;
            } else if (item.firma) {
              return item.firma[0].name;
            } else return "Ohne Empfänger";
          },
        },
        {
          key: "versand.datum",
          searchable: false,
          sortable: true,
          label: this.$t("global.date"),
        },
        {
          key: "betreff",
          sortable: true,
          label: this.$t("global.subject"),
          formatter: (value) => {
            if (value) {
              return value;
            } else return "Ohne Betreff";
          },
        },
        {
          key: "versand.erstelltvon",
          sortable: true,
          label: this.$t("global.creator"),
          formatter: (value) => {
            if (value) {
              return value.personName;
            } else return "Ohne Ersteller";
          },
        },
        {
          key: "versand.verarbeitungsstatus.bezeichnung",
          sortable: false,
          searchable: false,
          label: this.$t("global.processingstatus"),
        },
      ],
    };
  },
  computed: {
    verarbeitungsstati: {
      get() {
        return this.$store.state.korrespondenz.verarbeitungsstati;
      },
    },

    queryParams: function () {
      var params = {};

      params.filterBetreff = this.setQueryParam(this.filters.betreff);
      /*  params.filterName = this.setQueryParam(this.filters.name); */
      params.filterPerson = this.setQueryParam(this.filters.person);
      params.filterErsteller = this.setQueryParam(
        this.filters["versand.erstelltvon"]
      );
      params.verarbeitungsStatus = this.setQueryParam(
        this.filters.verarbeitungsStatus
      );
      params.filterVon = this.setQueryParam(this.filters.von);
      params.filterBis = this.setQueryParam(this.filters.bis);

      params.korrespondenznummer = this.setQueryParam(
        this.filters.korrespondenznummer
      );
      params.filterCount = this.setQueryParam(this.filters.count);
      /*  params.filterDatum = this.setQueryParam(this.filters["versand.datum"]); */

      // TODO

      //params.filterVDatum = this.setQueryParam(this.filters.versanddatum)
      //params.filterKAdr = this.setQueryParam(this.filters.korrespondenzadresse)

      return params;
    },
  },
  created() {
    this.initFilter("korrespondenz-liste", "korrespondenz/", true);

    if (this.verarbeitungsstati.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },
  /*watch: {
    pdfFiles: function (val) {
      if (val && val.length > 0) this.pdfPath = val[0];
    },
  },*/
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "receiver",
      "date",
      "subject",
      "creator",
      "processingstatus",
    ]);
  },
  methods: {
    details(korrespondenzliste) {
      this.$router.push({
        name: "korrespondenz",
        params: { id: korrespondenzliste.id, anzeige: 0 },
      });
    },

    massenkorrespondenzErstellen() {
      let json = {
        empfaenger: [],
        person: [], // ist de facto person. Noch unsauber im Backend
        isMassKorrespondenz: true,
        anmeldung: null,
        versand: {
          erstelltvon: this.angemeldeterMitarbeiter.person,
          datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
          ort: this.$CONST("CONFIG").MANDANTSTADT,
          betreff: "Neuer Serienbrief",
        },
        inhalt: "",
        zeitpunkt: new Intl.DateTimeFormat("ch-DE").format(new Date()),
      };

      Api.post("korrespondenz/", json, { params: {} }).then((response) => {
        this.$router.push({
          name: "korrespondenz",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "korrespondenz-loeschen-modal");
    },

    loeschen() {
      Api.delete("korrespondenz/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    ausgebenKontaktlistePdf() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          `/pdf/kontaktliste/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenKorrespondenzPdfCache(selected) {
      this.pdfFiles = [];

      let printIds = selected
        ? this.$refs.selectableTable.items
            .filter((item) => item.selected)
            .map((item) => item.id)
        : this.$refs.selectableTable.items.map((item) => item.id);

      if (printIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nocorrespondenceselected"),
        });
        return;
      }

      if (this.$refs.selectableTable.items.length > 100) {
        let index = 1,
          counter = parseInt(printIds.length / 100, 10);

        if (parseInt(printIds.length / 100, 10) != printIds.length / 100)
          counter++;

        for (var i = 0; i < printIds.length; i += 100) {
          let subList = printIds.slice(i, i + 100);
          let ids = subList.join(",");

          this.generatePDF(index, ids, counter, subList.length);
          index++;
        }
      } else {
        let ids = printIds.join(",");
        this.generatePDF(1, ids, 1, printIds.length);
      }

      this.$refs.pdfLayer.show();
    },

    generatePDF(index, ids, counter, size) {
      let json = {
        idlist: ids,
      };

      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        //let pdfPath = `pdfgenerator/pdfcache.act?filename=LE${filename}.pdf&report=/api/pdfgenerator/korrespondenz.htm&cache=${cacheId}&header=/pdfgenerator/header.htm&footer=/pdfgenerator/footer.htm`;

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=LE" +
          filename +
          ".pdf&report=/api/pdfgenerator/korrespondenz.htm&cache=" +
          cacheId;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            url: server.url + response.data.filepath,
            withCredentials: true,
            count: counter,
            size: size,
          };

          this.pdfFiles.push(retValue);
        });
      });
    },
  },
};
</script>

<style></style>
