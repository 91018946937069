<template>
  <div class="row">
    <div class="col-xl-12">
      <!-- AKTIONEN -->

      <container-headline class="col-xl-12" headline="" :col="3">
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="notenSpeichern"
              :disabled="!editable"
            >
              {{ $t("global.saveevaluations") }}
            </button>
          </div>
        </div>
      </container-headline>

      <!-- INHALT -->

      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              class="minh-200-px"
              ref="notenerfassungFreitable"
              tbody-tr-class="item"
              :items="notenliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              small
              thead-tr-class="pointer"
            >
              <template v-slot:cell(bewertung)="row">
                <v-select
                  v-model="row.item.textwahl_wert"
                  v-if="eingabetyp == 'textwahl'"
                  class="mt-2"
                  label="bezeichnung"
                  :options="textwahlwerte"
                  :disabled="!editable"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>

                <b-form-input
                  v-if="eingabetyp == 'freitext'"
                  v-model="row.item.textbewertung"
                  required
                  size="sm"
                  :readonly="!editable"
                ></b-form-input>
              </template>

              <template v-slot:cell(dispensiert)="row">
                <b-button
                  class="mr-2"
                  :variant="row.item.dispensiert == true ? 'primary' : 'light'"
                  size="sm"
                  style="width: 150px"
                  @click="setDispensiert(row.index)"
                  :disabled="!editable"
                  >{{
                    row.item.dispensiert
                      ? $t("global.dispensed")
                      : $t("global.notdispensed")
                  }}</b-button
                >
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";
import App from "@/AppApi";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    noteneingabe: {
      type: String,
      required: false,
    },
    eingabetyp: {
      type: String,
      required: true,
    },
    person: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "",
      sortDesc: false,
      invalidFields: {},
      notenliste: [],
      pruefungCount: 0,
      pruefungLabel: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.notenliste.length == 0) this.getNoten();
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "teilnehmer.personName",
          sortable: true,
          label: this.$t("global.participants"),
        },
        {
          key: "dispensiert",
          sortable: true,
          class: "text-center",
          label: this.$t("global.dispensed"),
        },
        {
          key: "bewertung",
          class: "text-center",
          sortable: true,
          label: this.$t("global.evaluation"),
        },
      ];
    },
    textwahlwerte: {
      get() {
        return this.$store.state.noten.textwahlwerte;
      },
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.notenliste.length == 0) this.getNoten();
  },

  methods: {
    validateForm() {
      let validState = true;

      Object.values(this.invalidFields).forEach((val) => {
        if (val == true) validState = false;
      });

      return validState;
    },

    getNoten() {
      this.isBusy = true;

      App.get("noteneingabe/textnote/", {
        params: {
          id: this.noteneingabe,
        },
      })
        .then((response) => {
          this.notenliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    setDispensiert(index) {
      this.notenliste[index].dispensiert = !this.notenliste[index].dispensiert;
    },

    notenSpeichern() {
      if (!this.validateForm()) return;

      this.isBusy = true;

      App.put("noteneingabe/textnote/", this.notenliste, {
        params: { id: this.noteneingabe },
      })
        .then((response) => {
          this.notenliste = response.data;

          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.gradessavedsuccessfully"),
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>
  