<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.exams')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              v-if="editable"
              class="btn btn-success"
              @click="pruefungenSpeichern(false)"
            >
              Prüfungen speichern
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="addPruefung"
              :disabled="pruefungen.length >= 5"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.exam") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="
                showAlertModal(
                  'Prüfung sperren',
                  'Wollen sie die ausgewählte Prüfung wirklich sperren?',
                  null
                )
              "
            >
              {{ $t("global.lockexam") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="pruefungWiederoeffnen"
            >
              {{ $t("global.unlockexam") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-danger"
              @click="pruefungLoeschen"
            >
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>

        <!-- INHALT -->
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              :items="pruefungen"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              responsive
              small
              thead-tr-class="pointer"
              tbody-tr-class="item no-pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                  ref="checkboxes"
                  class="form-checkbox-pointer"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                  class="form-checkbox-pointer"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(bezeichnung)="row">
                <b-form-input
                  v-model="row.item.bezeichnung"
                  @blur="checkBezeichnung(row.index)"
                  :readonly="!row.item.editable || !editable"
                  size="sm"
                ></b-form-input>
              </template>

              <template v-slot:cell(datum)="row">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="row.item.datum"
                  :disabled="!row.item.editable || !editable"
                  :show-icon="true"
                  @update="(val) => (row.item.datum = val)"
                />
              </template>

              <template v-slot:cell(gewichtung)="row">
                <b-form-input
                  v-model="row.item.gewichtung"
                  :on-change="checkGewichtung(row.index)"
                  :readonly="!row.item.editable || !editable"
                  :state="row.item.invalid"
                  size="sm"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback>
                  Die Gewichtung muss zwischen 1 und 10 liegen.
                </b-form-invalid-feedback>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <alert-modal
      id="modal-alert"
      :title="alertModal.title"
      :text="alertModal.text"
      :color="alertModal.color"
      :eventName="'abschliessenConfirmed'"
      @abschliessenConfirmed="pruefungAbschliessen"
    />
  </div>
</template>
  
  <script>
import { BSpinner } from "bootstrap-vue";
import DatePicker from "@/components/Datepicker.vue";
import AlertModal from "@/components/Modals/AlertModal.vue";
import ContainerHeadline from "@/components/ContainerHeadline.vue";
import reiter from "@/mixins/Reiter";
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import App from "@/AppApi";

export default {
  name: "Pruefungenliste",

  components: {
    ContainerHeadline,
    AlertModal,
    DatePicker,
    BSpinner,
  },

  mixins: [reiter],
  props: {
    noteneingabe: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "nummer",
      sortDesc: false,
      pruefungen: [],
      invalidFields: {},
      selected: [],
      selectedNumbers: [],
      alertModal: {
        color: null,
        text: "",
        title: "",
      },
    };
  },

  watch: {},

  computed: {
    fields() {
      return [
        {
          key: "selected",
          label: "",
        },
        {
          key: "nummer",
          class: "text-center",
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id === closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          sortable: true,
          label: this.$t("global.number"),
        },
        {
          key: "datum",
          class: "text-center",
          sortable: true,
          label: this.$t("global.date"),
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id === closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
        },
        {
          key: "bezeichnung",
          class: "text-center",
          sortable: true,
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id === closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          label: this.$t("global.designation"),
        },
        {
          key: "durchschnitt",
          class: "text-center",
          sortable: true,
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id === closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          label: this.$t("global.average"),
        },
        {
          key: "gewichtung",
          class: "text-center",
          sortable: true,
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id === closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          label: this.$t("global.weighting"),
        },
      ];
    },
    closed: function () {
      return this.$CONST("PRUEFUNGSTATI").CLOSED;
    },

    planned: function () {
      return this.$CONST("PRUEFUNGSTATI").PLANNED;
    },
  },

  created() {},

  mounted() {
    if (this.shown && this.pruefungen.length === 0) {
      this.getPruefungen();
    }
  },

  methods: {
    showAlertModal(title, text, color) {
      if (this.pruefungen.length > 0) {
        this.alertModal.title = title;
        this.alertModal.text = text;
        this.alertModal.color = color;
        $("#modal-alert").modal("show");
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noexamselected"),
        });
    },
    getPruefungen() {
      this.isBusy = true;

      let plannedId = this.planned;

      App.get("noteneingabe/pruefung/", {
        params: {
          id: this.noteneingabe,
        },
      })
        .then((response) => {
          this.pruefungen = response.data;
          let that = this;
          this.pruefungen.forEach(function (pruefung, index) {
            $;
            pruefung.durchschnitt = that.formatNotenWert(pruefung.durchschnitt);
            pruefung.nummer = index + 1;
            if (!pruefung.status) pruefung.status = { id: plannedId };
            pruefung.status.id == plannedId
              ? (pruefung.editable = true)
              : (pruefung.editable = false);
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    async pruefungenSpeichern(switched) {
      if (this.pruefungen.length > 0) {
        if (!this.validateForm()) {
          this.$notify({
            type: "warning",
            title: this.$t("notification.actionfailed"),
            text: "Die Gewichtung muss zwischen 1 und 10 liegen.",
          });
          return;
        }

        this.isBusy = true;

        App.post("noteneingabe/pruefung/", this.pruefungen, {
          params: {
            id: this.noteneingabe,
          },
        })
          .then((response) => {
            let plannedId = this.planned;

            this.pruefungen = response.data;
            let that = this;
            this.pruefungen.forEach(function (pruefung, index) {
              $;
              pruefung.durchschnitt = that.formatNotenWert(
                pruefung.durchschnitt
              );
              pruefung.nummer = index + 1;
              if (!pruefung.status) pruefung.status = { id: plannedId };
              pruefung.status.id == plannedId
                ? (pruefung.editable = true)
                : (pruefung.editable = false);
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.isBusy = false;
            if (switched) {
              this.$router.push({
                name: "noteneingabe",
                params: { id: this.noteneingabe, anzeige: 1 },
              });
            }
          });
      } else {
        if (switched) {
          this.$router.push({
            name: "noteneingabe",
            params: { id: this.noteneingabe, anzeige: 1 },
          });
        }
      }
    },
    addPruefung() {
      this.pruefungen.push({
        id: null,
        bezeichnung: "Neue Prüfung",
        durchschnitt: null,
        datum: new Intl.DateTimeFormat("ch").format(new Date()),
        gewichtung: null,
        nummer: this.pruefungen.length + 1,
        editable: true,
      });
    },
    pruefungLoeschen() {
      App.delete("noteneingabe/pruefung/", { data: this.selectedIds }).then(
        () => {
          this.pruefungen = this.pruefungen.filter(
            (pruefung) => !this.selectedNumbers.includes(pruefung.nummer)
          );
          this.selectedIds = [];
          this.selectedNumbers = [];
        }
      );
    },
    pruefungAbschliessen() {
      if (!this.selectedNumbers) {
        return;
      }

      this.selectedNumbers.forEach((number) => {
        let pruefungIndex = this.pruefungen.findIndex(
          (p) => p.nummer === number
        );

        if (pruefungIndex.length == -1) {
          console.log("pruefung nicht gefunden");
          return;
        }

        this.pruefungen[pruefungIndex].status = {
          id: this.$CONST("PRUEFUNGSTATI").CLOSED,
        };

        this.pruefungen[pruefungIndex].editable = false;
      });
    },
    pruefungWiederoeffnen() {
      if (!this.selectedNumbers) {
        return;
      }

      this.selectedNumbers.forEach((number) => {
        let pruefungIndex = this.pruefungen.findIndex(
          (p) => p.nummer === number
        );

        if (pruefungIndex == -1) {
          console.log("pruefung nicht gefunden");
          return;
        }

        this.pruefungen[pruefungIndex].status = {
          id: this.$CONST("PRUEFUNGSTATI").PLANNED,
        };

        this.pruefungen[pruefungIndex].editable = true;
      });
    },
    //table helper functions
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedNumbers.push(gp.nummer);
            if (gp.id !== null) {
              this.selectedIds.push(gp.id);
            }
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedNumbers = [];
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedNumbers.push(row.item.nummer);
        if (row.item.id !== null) {
          this.selectedIds.push(row.item.id);
        }
      } else {
        this.selectedNumbers = this.selectedNumbers.filter(
          (nummer) => nummer != row.item.nummer
        );
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
    //exam helper functions
    formatNotenWert(value) {
      return value == undefined || value == ""
        ? ""
        : parseFloat(value).toFixed(1);
    },
    validateForm() {
      let validState = true;

      Object.values(this.invalidFields).forEach((val) => {
        if (val == true) validState = false;
      });

      return validState;
    },
    checkGewichtung(index) {
      let val = this.pruefungen[index].gewichtung;
      this.invalidFields["gewichtung" + index] = false;
      this.pruefungen[index].invalid = true;

      if (val == undefined || val == "") this.pruefungen[index].gewichtung = 1;
      else if (val <= 0 || val > 10) {
        this.pruefungen[index].invalid = false;
        this.invalidFields["gewichtung" + index] = true;
      }
    },
    checkBezeichnung(index) {
      let val = this.pruefungen[index].bezeichnung;
      if (val == undefined || val == "")
        this.pruefungen[index].bezeichnung = "Keine Angabe";
    },
  },
};
</script>
  
  
  <style scoped>
</style>
  