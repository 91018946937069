<template >
  <div v-if="checkBildungsgangTyp">
    <container-headline
      headline="Modulinformationen"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="fach.modulgruppe"
                  label="bezeichnung"
                  :options="modulgruppen"
                  :disabled="true"
                  placeholder="Modulgruppe"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>Modulgruppe</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="fach.moduleinsatz"
                  label="bezeichnung"
                  :options="moduleinsaetze"
                  :disabled="true"
                  placeholder="Moduleinsatz"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>Moduleinsatz</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="fach.modultyp"
                  label="bezeichnung"
                  :options="modultypen"
                  :disabled="true"
                  placeholder="Modultyp"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>Modultyp</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model="fach.leistungspunkte"
                  class="form-control"
                  placeholder="Leistungspunkte"
                  :disabled="true"
                />
                <label>Leistungspunkte</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "ModulInformationen",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    fach: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    modulID: {
      type: String,
    },
    faecher: {
      type: Array,
      default: null,
    },
  },
  watch: {
    /*faecherModulID(val) {
      let modulID = val[0];
      let faecher = val[1];

      if (modulID && faecher) {
        let modul = faecher.find((md) => md.id == modulID);

        this.fach.modulgruppe = modul?.modulgruppe;
        this.fach.moduleinsatz = modul?.moduleinsatz;
        this.fach.modultyp = modul?.modultyp;
        this.fach.modulbezeichnung = modul?.bezeichnung;
      }
    },*/
  },
  computed: {
    /*faecherModulID() {
      //Für Watch trigger auf zwei Variabeln
      return [this.modulID, this.faecher];
    },*/
    modultypen: {
      get() {
        return this.$store.state.veranstaltung.modultypen;
      },
    },
    modulgruppen: {
      get() {
        return this.$store.state.veranstaltung.modulgruppen;
      },
    },
    moduleinsaetze: {
      get() {
        return this.$store.state.veranstaltung.moduleinsaetze;
      },
    },

    checkBildungsgangTyp: function () {
      if (this.fach?.lehrgang?.bildungsgangtyp?.id == "1821FEA3F48") {
        return true;
      }
      return false;
    },
  },
  mounted: function () {},
  methods: {},
};
</script>

<style lang="scss">
</style>
