<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ headerTitel }}</span>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">
              <font-awesome-icon
                icon="fa-light fa-times"
                :style="{ color: 'white' }"
              />
            </span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-danger">{{ errorText }} : {{ errorsCount }}</p>
          <span>{{ errorTitle }}:</span>
          <ul>
            <li v-for="errorObj in errorData" :key="errorObj.message">
              {{ errorObj.message }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 text-center">
              <button type="button" class="btn btn-success mr-3" @click="onOk">
                <span class="font-khand font-weight-bold">{{ okText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";

export default {
  name: "MessageboxError",
  components: {
  },
  props: {
    id: {
      type: String,
      default: "message-error-modal",
    },
    okText: {
      type: String,
      default: "Ok",
    },
    headerTitel: {
      type: String,
      default: "Aktion Fehlgeschlagen",
    },
    errorText: {
      type: String,
      default: "Fehlgeschlagen",
    },
  },
  data() {
    return {
      errorsCount: null,
      errorTitle: null,
      errorData: null,
    };
  },
  mixins: [modal],
  mounted() {
    this.$bus.$on("openMessageboxError", (response) => {
      if (response.id != null && response.id != this.id) return;
      this.errorData = response.data;
      this.errorsCount = response.data.length;
      this.errorTitle = response.data[0].title;

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  },
  methods: {
    closeModal() {
      $("#" + this.id).modal("hide");
      this.$emit("ok");
    },
    onOk() {
      this.closeModal();
      this.$emit("ok");
    },
  },
};
</script>
