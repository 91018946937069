export const SET_ANMELDE_TYPEN = "setAnmeldeTypen";
export const SET_ABLEHNUNG_GRUENDE = "setAblehnungGruende";
export const SET_ANMELDE_GRUENDE = "setAnmeldeGruende";
export const SET_ABMELDE_GRUENDE = "setAbmeldeGruende";
export const SET_ANMELDE_STATI = "setAnmeldeStati";
export const SET_ANMELDE_VERRECHNUNGEN = "setAnmeldeVerrechnungen";
export const SET_ABSCHLUESSE = "setAbschluesse";
export const SET_KOMPETENZRASTERS = "setKompetenzrasters";
export const SET_NIVEAUS = "setNiveaus";
export const SET_QUELLEN = "setQuellen";
export const SET_INTERESSEN_INFOABEND = "setInteressenInfoabend";
