<template>
  <div class="list">
    <navbar :title="$t('global.subsidy')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <notifications></notifications>
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <!--
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.person"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>Person</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.bildungsgang"
                        :options="ausbildungen"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        placeholder="Bildungsgang"
                        multiple
                      >
                        <span slot="no-options"
                          >Kein Bildungsgang gefunden</span
                        >
                      </v-select>
                      <label>Bildungsgang</label>
                    </div>
                  </div>
                </div>
                -->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.subventionsbeitragstatus"
                        :options="beitragstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.subsidycontributionstatus')"
                      >
                        <span slot="no-options"
                          >{{ $t("global.nosubsidystatusfound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.subsidycontributionstatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.beitragsintervall"
                        :options="beitragsintervalle"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.subsidycontributionstatus')"
                      >
                        <span slot="no-options"
                          >{{ $t("global.nosubsidystatusfound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.contributioninterval") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.subventionskanton"
                        :options="subventionskantone"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.subsidycanton')"
                      >
                        <span slot="no-options"
                          >{{ $t("global.nosubsidycantonfound") }}</span
                        >
                      </v-select>
                      <label>{{ $t("global.subsidycanton") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.subventionsbetragVon"
                        class="form-control input"
                        type="number"
                        :placeholder="$t('global.amountfrom')"
                      />
                      <label>{{ $t("global.amountfrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.subventionsbetragBis"
                        class="form-control input"
                        type="number"
                        :placeholder="$t('global.amountupto')"
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>

                <!--
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.subventionsbeitrag"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>Subventionsbeitrag</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.ausbildungsstatus"
                        :options="ausbildungsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        placeholder="Status Bildung"
                      >
                        <span slot="no-options"
                          >Kein Bildungstatus der Subventionen gefunden</span
                        >
                      </v-select>
                      <label>Status Bildung</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-ausbildung-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.ausbildungsbeginn"
                        field="ausbildungsbeginn"
                        @updatefull="setFilterDate"
                      />
                      <label>Ausbildungsbeginn</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-ausbildung-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.ausbildungsende"
                        field="ausbildungsende"
                        @updatefull="setFilterDate"
                      />
                      <label>Ausbildungsende</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-kostengutsprache-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.kostengutspracheVon"
                        field="kostengutspracheVon"
                        @updatefull="setFilterDate"
                      />
                      <label>Kostengutsprache von</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-kostengutsprache-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.kostengutspracheBis"
                        field="kostengutspracheBis"
                        @updatefull="setFilterDate"
                      />
                      <label>Kostengutsprache bis</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 my-2">
                    <div class="form-group-inline" style="margin-left: 7px">
                      <label
                        class="float-left"
                        style="
                          font-size: 11px !important;
                          font-weight: 700 !important;
                        "
                        >Neueintritt: </label
                      ><input
                        class="form-check-input float-right mx-3"
                        style="margin-top: 0.2rem"
                        :value="true"
                        v-model="filters['neueintritt']"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bemerkung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>Bemerkungen</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>Anzahl Datensätze</label>
                    </div>
                  </div>
                </div>
                -->
                <filter-search-reset-button/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.subsidycontributionlist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4"></div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :subvention="subventionsstati"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <massenmutation-modal
      id="massenmutation-modal"
      :ids="selectedIds"
      :objects="selectedObjects"
    />

    <subventionsbeitrag-erstellen-modal
      id="subventionsbeitrag-erstellen-modal"
      :subventionsstati="subventionsstati"
      :ausbildungsstati="ausbildungsstati"
    />

    <loeschen-modal @confirmed="loeschen" :multiple="true" />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
//import DatePicker from "@/components/Datepicker";
import MassenmutationModal from "@/components/Modals/MassenmutationModal";
import SubventionsbeitragErstellenModal from "@/components/Modals/SubventionsbeitragErstellenModal";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import numeral from "numeral";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "@/store/subventionbeitrag/actions.type";
import { LADE_AUSWAHLWERTE_SUBVENTION } from "@/store/subvention/actions.type";

export default {
  name: "subventionsbeitragliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    //DatePicker,
    MassenmutationModal,
    SubventionsbeitragErstellenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.subsidies");
      },
    };
  },
  mixins: [page, dashboard, filter],
  data() {
    return {
      sortBy: "bezeichnung",
      sortDesc: false,

      fields: [
        { key: "selected", label: "" },
        /*
        {
          key: "person.personName",
          sortable: true,
          label: "Person",
          searchable: false,
        },
        */
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
          searchable: true,
        },
        {
          key: "subventionsbeitragstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.subsidycontributionstatus"),
          searchable: false,
        },
        {
          key: "beitragsintervall.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.subsidyinterval"),
        },
        {
          key: "subventionskanton.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.subsidycanton"),
        },
        {
          key: "betrag",
          sortable: true,
          label: this.$t("global.amount"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
          thClass: "text-right",
          searchable: false,
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.filters.bezeichnung;
      params.filterSubventionsbeitragstatus =
        this.filters.subventionsbeitragstatus;
      params.filterBeitragsintervall = this.filters.beitragsintervall;
      params.filterSubventionskanton = this.filters.subventionskanton;
      params.filterSubventionsbetragVon = this.filters.subventionsbetragVon;
      params.filterSubventionsbetragBis = this.filters.subventionsbetragBis;

      /*
      params.filterPerson = this.filters.person;
      //params.filterBildungsgang = this.filters["bildungsgang.bezeichnung"];

      if (this.bildungsgang) params.filterBildungsgang = this.bildungsgang;
      else
        params.filterBildungsgang = this.filters.bildungsgang
          ? this.filters.bildungsgang.join(",")
          : null;

      params.filterSubventionsstatus = this.filters.subventionsstatus;
      params.filterSubventionskanton = this.filters.subventionskanton;
      params.filterAusbildungsstatus = this.filters.ausbildungsstatus;

      if (
        this.filters.ausbildungsbeginn &&
        this.filters.ausbildungsbeginn != ".."
      )
        params.filterAusbildungsbeginn = this.filters.ausbildungsbeginn;

      if (this.filters.ausbildungsende && this.filters.ausbildungsende != "..")
        params.filterAusbildungsende = this.filters.ausbildungsende;

      if (
        this.filters.kostengutspracheVon &&
        this.filters.kostengutspracheVon != ".."
      )
        params.filterKostengutspracheVon = this.filters.kostengutspracheVon;
      if (
        this.filters.kostengutspracheBis &&
        this.filters.kostengutspracheBis != ".."
      )
        params.filterKostengutspracheBis = this.filters.kostengutspracheBis;

      params.filterBemerkung = this.filters.bemerkung;

      params.filterNeueintritt = this.filters.neueintritt;
      if (this.filters.count != "") params.filterCount = this.filters.count;
      */
      return params;
    },

    beitragstati: {
      get() {
        return this.$store.state.subventionbeitrag.beitragstati;
      },
    },
    subventionskantone: {
      get() {
        return this.$store.state.subvention.subventionskantone;
      },
    },
    beitragsintervalle: {
      get() {
        return this.$store.state.subventionbeitrag.beitragsintervalle;
      },
    },

    /*
    subventionsstati: {
      get() {
        return this.$store.state.subvention.subventionsstati;
      },
    },
    ausbildungsstati: {
      get() {
        return this.$store.state.subvention.ausbildungstati;
      },
    },
    abschluesse: {
      get() {
        return this.$store.state.subvention.abschlusse;
      },
    },
    ausbildungen: {
      get() {
        return this.$store.state.subvention.ausbildungen;
      },
    },
    */
  },

  created() {
    this.initFilter(
      "subventionsbeitrag-liste",
      "bildung/subvention/beitrag/",
      true
    );
  },

  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.$store.dispatch(
      `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
    );
    if (this.subventionsstati.length == 0)
      this.$store.dispatch(`subvention/${LADE_AUSWAHLWERTE_SUBVENTION}`);
    this.setHeaders("global", [
      "",
      "designation",
      "subsidycontributionstatus",
      "subsidycontributionstatus",
      "subsidyinterval",
      "subsidycanton",
      "amount",
    ]);
  },
  methods: {
    details(subventionsbeitrag) {
      console.log(subventionsbeitrag.id);
      this.$router.push({
        name: "subventionsbeitrag",
        params: { subventionsbeitragid: subventionsbeitrag.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noeducationportfolioselected"),
        });
      }
    },

    oeffneMassenmutationsModal() {
      this.$bus.$emit("open-modal", "massenmutation-modal");
    },

    oeffneSubventionsbeitragErstellenModal() {
      this.$bus.$emit("open-modal", "subventionsbeitrag-erstellen-modal");
    },

    loeschen() {
      Api.delete("bildung/subvention/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },
  },
};
</script>

<style>
</style>
