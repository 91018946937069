import Termin from "@/pages/Ressourcen/Termin";
import TerminKalender from "@/pages/Ressourcen/TerminKalender";
import BildschirmPlaner from "@/pages/Ressourcen/BildschirmPlaner";
import ScreenEvent from "@/pages/Ressourcen/Event";
import StandardEvent from "@/pages/Ressourcen/Standardevent";
import Raum from "@/pages/Ressourcen/Raum";
import Raumliste from "@/pages/Einstellungen/Raumliste";
import Gebaeude from "@/pages/Ressourcen/Gebäude";
import Gebäudeliste from "@/pages/Einstellungen/Gebäudeliste";

export default [
  {
    path: "/ressourcen/bildschirm-planer",
    name: "bildschirm-planer",
    component: BildschirmPlaner,
  },
  {
    path: "/ressourcen/screenevent/:id?/:anzeige",
    name: "screenevent",
    component: ScreenEvent,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/ressourcen/standardevent/:id?/:anzeige",
    name: "standardevent",
    component: StandardEvent,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/ressourcen/termin-kalender",
    name: "termin-kalender",
    component: TerminKalender,
  },
  {
    path: "/ressourcen/termin/:id?/:anzeige",
    name: "termin",
    component: Termin,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/ressourcen/raumliste",
    name: "Raumliste",
    component: Raumliste,
  },
  {
    path: "/ressourcen/raum",
    name: "Neuer Raum",
    component: Raum,
  },
  {
    path: "/ressourcen/raum/:id",
    name: "RaumMitId",
    props: true,
    component: Raum,
  },
  {
    path: "/ressourcen/raum/:id/:anzeige",
    name: "RaumMitIdUndAnzeige",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: Raum,
  },

  {
    path: "/ressourcen/gebaeudeliste",
    name: "Gebäudeliste",
    component: Gebäudeliste,
  },
  {
    path: "/ressourcen/gebaeude",
    name: "Neues Gebäude",
    component: Gebaeude,
  },
  {
    path: "/ressourcen/gebaeude/:id",
    name: "GebäudeMitId",
    props: true,
    component: Gebaeude,
  },
  {
    path: "/ressourcen/gebaeude/:id/:anzeige",
    name: "GebäudeMitIdUndAnzeige",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: Gebaeude,
  },

  /*** Muss übearbeitet werden ***/

  {
    path: "/ressourcen/dokumentenliste",
    name: "Dokumentenliste",
    component: () => import("@/pages/Dashboardlisten/Dokumentenliste"),
  },
  {
    path: "/ressourcen/dokument/:id",
    name: "DokumentMitId",
    props: true,
    component: () => import("@/pages/Ressourcen/Dokument"),
  },

  {
    path: "/ressourcen/terminliste",
    name: "Terminliste",
    component: () => import("@/pages/Dashboardlisten/Terminliste"),
  },

  //File ist noch im Backup folder
  /* {
    path: "/ressourcen/arbeitsplaetze",
    name: "Arbeitsplatzliste",
    component: () => import("@/pages/Dashboardlisten/Arbeitsplatzliste"),
  },
 */
  {
    path: "/ressourcen/arbeitsplatz/:id",
    name: "Arbeitsplatz",
    component: () => import("@/pages/Ressourcen/Arbeitsplatz"),
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
];
