import {
  SET_LEADQUELLEN,
  SET_LEADSTAGES,
  SET_LEADTYPEN,
  SET_KONTAKTROLLEN,
  SET_AKTIVITAETTYPEN,
  SET_AKTIVITAETSTATI,
  SET_KAMPAGNESTATI,
  SET_KAMPANGEEINTRAGSTATI,
  STE_KAMPAGNETEILNEHMERSTATI,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_CRM } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    leadquellen: [],
    leadstages: [],
    leadtypen: [],
    kontaktrollen: [],
    aktivitaettypen: [],
    aktivitaetstati: [],
    kampagnestati: [],
    kampagneeintragstati: [],
    kampagneteilnehmerstati: [],
  },
  mutations: {
    [SET_LEADQUELLEN](state, val) {
      state.leadquellen = val;
    },
    [SET_LEADSTAGES](state, val) {
      state.leadstages = val;
    },
    [SET_LEADTYPEN](state, val) {
      state.leadtypen = val;
    },
    [SET_KONTAKTROLLEN](state, val) {
      state.kontaktrollen = val;
    },
    [SET_AKTIVITAETTYPEN](state, val) {
      state.aktivitaettypen = val;
    },
    [SET_AKTIVITAETSTATI](state, val) {
      state.aktivitaetstati = val;
    },
    [SET_KAMPAGNESTATI](state, val) {
      state.kampagnestati = val;
    },
    [SET_KAMPANGEEINTRAGSTATI](state, val) {
      state.kampagneeintragstati = val;
    },
    [STE_KAMPAGNETEILNEHMERSTATI](state, val) {
      state.kampagneteilnehmerstati = val;
    },
  },
  actions: {
    async [LADE_AUSWAHLWERTE_CRM](context) {
      let response = await Api.get("crm/auswahlwerte/");
      context.commit(SET_LEADQUELLEN, response.data.leadquellen);
      context.commit(SET_LEADSTAGES, response.data.leadstages);
      context.commit(SET_LEADTYPEN, response.data.leadtypen);
      context.commit(SET_KONTAKTROLLEN, response.data.kontaktrollen);
      context.commit(SET_AKTIVITAETTYPEN, response.data.aktivitaettypen);
      context.commit(SET_AKTIVITAETSTATI, response.data.aktivitaetstati);
      context.commit(SET_KAMPAGNESTATI, response.data.kampagnestati);
      context.commit(
        SET_KAMPANGEEINTRAGSTATI,
        response.data.kampagneeintragstati
      );
      context.commit(
        STE_KAMPAGNETEILNEHMERSTATI,
        response.data.kampagneteilnehmerstati
      );
    },
  },
};
