/* Positionen */

export const SET_POSITIONEN = "setPositionen";
export const ADD_POSITIONEN = "addPositionen";
export const ADD_POSITION = "addPosition";
export const CHANGE_POSITION = "changePosition";
export const CHANGE_UNTERPOSITION = "changeUnterposition";
export const SWAP_POSITIONEN = "swapPositionen";
export const REMOVE_POSITIONEN = "removePosition";

/* Beleg */

export const SET_GEBUCHT = "setGebucht";
export const SET_LIEFERANT = "setLieferant";
export const SET_BELEGTYP = "setBelegTyp";
export const ADD_LIEFERANT = "addLieferant";
export const SET_BELEG_STATISTIK = "setBelegstatistik";

/* Verknüpfte Belege */

export const SET_VERKNUEPFTE_BELEGE = "setVerknuepfteBelege";
export const CHANGE_VERKNUEPFTER_BELEG = "changeVerknuepfterBeleg";
export const ADD_VERKNUEPFTER_BELEG = "addVerknuepfterBeleg";

/* Erinnerungen */

export const SET_ERINNERUNGEN = "setErinnerungen";
export const CHANGE_ERINNERUNG = "changeErinnerung";
export const ADD_ERINNERUNG = "addErinnerung";

/* Listen */

export const SET_EINHEITEN = "setEinheiten";
export const SET_ARTIKELTYPEN = "setArtikeltypen";
export const SET_ZAHLUNGSBEDINGUNGEN = "setZahlungsbedingungen";
export const SET_LIEFERBEDINGUNGEN = "setLieferbedingungen";
export const SET_MITARBEITERLISTE = "setMitarbeiterListe";
export const SET_MEHRWERTSTEUERSAETZE = "setMehrwertsteuersaetze";
export const SET_LAENDER = "setLaender";
export const SET_LAGER = "setLager";
export const SET_ARTIKELGRUPPEN = "setArtikelGruppen";
export const SET_ANMELDESTATI = "setAnmeldeStati";
export const SET_AKTIVITAETSSTATUS = "setAktivitaetsStatus";
export const SET_BILDUNGSKATEGORIEN = "setBildungskategorien";
export const SET_BELEGTYPEN = "setBelegtypen";
export const SET_LAGERPLAETZE = "setLagerplaetze";
export const ADD_LAGERPLATZ = "aLagerplatz";
export const ADD_MEHRWERSTEUERSATZ = "addMehrwertsteuersatz";
export const SET_ADRESSTYPEN = "setAdresstypen";
export const SET_FILTERSTATE = "setFilterState";
export const SET_FUNKTIONSBEREICHE = "setFunktionsbereiche";

/* Session */

export const SET_MITARBEITER = "setMitarbeiter";
export const SET_PERMISSIONS = "setPermissions";

/* Viewport */

export const SET_WINDOW_HEIGHT = "setWindowHeight";
export const SET_WINDOW_WIDTH = "setWindowWidth";

export const SET_BERECHTIGUNGEN = "setBerechtigungen";
export const SET_GRUPPEN = "setGruppen";

/* App Keys */

export const SET_APPKEYS = "setAppKeys";
