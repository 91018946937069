<template>
  <div class="modal fade" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ headerTitel }}</span>
          <modal-close-button />
        </div>
        <div class="modal-body">
          <p v-if="text !== null" v-html="replaceWithBr()">{{ text }}</p>
          <div v-if="fields.length > 0" class="row col-xl-12">
            <div class="col-xl-12 block belegliste br-t-l-0">
              <div>
                <b-table
                  ref="selectableTable"
                  tbody-tr-class="item"
                  :items="items"
                  :fields="fields"
                  fixed
                  selectable
                  hover
                  responsive
                  table-class="w-auto"
                >
                </b-table>
              </div>
            </div>
          </div>
          <span v-if="fields.length == 0">Keine</span>

          <slot></slot>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 text-center">
              <button
                v-show="cancel"
                type="button"
                class="btn btn-danger mr-3"
                data-dismiss="modal"
                @click="onCancel"
              >
                <span class="font-khand font-weight-bold">{{
                  cancelText
                }}</span>
              </button>
              <button
                v-show="repeat"
                type="button"
                class="btn btn-primary mr-3"
                data-dismiss="modal"
                @click="onRepeat"
              >
                <span class="font-khand font-weight-bold">{{
                  repeatText
                }}</span>
              </button>
              <button
                v-show="ok"
                type="button"
                class="btn btn-success"
                @click="onOk"
              >
                <span class="font-khand font-weight-bold">{{ okText }}</span>
              </button>
              <button
                v-show="close"
                type="button"
                class="btn btn-primary"
                @click="onClose"
              >
                <span class="font-khand font-weight-bold">{{ closeText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { faLoveseat } from "@fortawesome/pro-regular-svg-icons";

export default {
  name: "MessageboxTable",
  components: {
    ModalCloseButton,
  },
  props: {
    headerText: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      default: "messagebox-table-modal",
    },
    ok: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: true,
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: "OK",
    },
    closeText: {
      type: String,
      default: "Schliessen",
    },
    cancelText: {
      type: String,
      default: "Abbrechen",
    },
    repeatText: {
      type: String,
      default: "Wiederholen",
    },
  },
  data() {
    return {
      text: null,
      headerTitel: null,
      fields: [],
      items: [],
    };
  },
  mixins: [modal],
  mounted() {
    this.$bus.$on("openMessageBoxTable", (data) => {
      if (data.id != null && data.id != this.id) return;

      this.text = data.text ? data.text : this.text;
      this.headerTitel = data.headerText ? data.headerText : this.headerText;
      this.fields = data.fields ? data.fields : this.fields;
      this.items = data.items ? data.items : this.items;
      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  },
  methods: {
    closeModal() {
      $("#" + this.id).modal("hide");
    },
    onCancel() {
      this.closeModal();
      this.$emit("cancel");
    },
    onOk() {
      this.closeModal();
      this.$emit("ok");
    },
    onClose() {
      this.closeModal();
      this.$emit("close");
    },
    onRepeat() {
      this.closeModal();
      this.$emit("repeat");
    },
    replaceWithBr() {
      return this.text.replace(/\n/g, "<br />");
    },
  },
};
</script>
