<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel ? navbarTitel : $t('global.newpresence')"
            ></head-menu>
            <div class="row mt-2">
              <div class="ml-4 mr-2">
                <button
                  v-shortkey.once="['ctrl', 's']"
                  v-if="editable"
                  class="btn btn-success"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>
                <button class="btn btn-primary ml-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
                <button
                  class="btn btn-primary ml-4"
                  @click="praesenzlisteDrucken"
                  v-if="editable"
                >
                  <font-awesome-icon icon="fa-duotone fa-print" class="mr-1" />
                  {{ $t("global.printAttendanceList") }}
                </button>
                <button
                  v-if="editable"
                  class="btn btn-primary ml-4"
                  @click="setAll100"
                >
                  {{ $t("global.allon100") }}
                </button>
              </div>
              <div
                class="ml-2"
                v-if="raster ? (raster.id ? true : false) : false"
              >
                <button
                  class="btn btn-primary"
                  @click="showUnterrichtsinhaltModal"
                >
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.showlessoncontent")
                  }}
                </button>
                <button
                  class="btn btn-primary ml-2"
                  @click="showUnterrichtsplanungModal"
                >
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.showlessoncontentplanning")
                  }}
                </button>
              </div>
              <div>
                <button
                  class="btn btn-primary ml-2"
                  @click="showFachkompetenzenUebernehmenModal"
                >
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />
                  Bearbeite alle Fachkompetenzen & Sozialverhalten
                </button>
              </div>
              <div>
                <button
                  class="btn btn-primary ml-2"
                  @click="_showDetails ? collapseAll : expandAll"
                >
                  alle anzeigen
                </button>
              </div>
              <div class="ml-2">
                <button
                  class="btn btn-primary ml-4"
                  @click="excelExport"
                  v-if="editable"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-file-export"
                    class="mr-1"
                  />
                  {{ $t("global.export") }}
                </button>
              </div>
              <div class="mr-2"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- START Linke Seite -->

      <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Daten -->
        <container-headline
          :headline="$t('global.data')"
          :col="4"
        ></container-headline>

        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <span
                          class="openIcon"
                          v-on:click="oeffneFach"
                          v-if="berechtigungen.b_bildung_fach.read"
                          ><font-awesome-icon
                            icon="fa-duotone fa-arrow-circle-right"
                          />
                        </span>
                        <input
                          v-model="fach"
                          class="form-control"
                          readonly
                          placeholder=" "
                        />
                        <label
                          >{{ $t("global.Subject") }}
                          <font-awesome-icon
                            icon="fa-duotone fa-arrow-circle-right"
                          />
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-1">
                      <div class="form-group">
                        <input
                          v-model.trim="veranstaltungStatus"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.status") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-1">
                      <div class="form-group">
                        <input
                          v-model.trim="datum"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.date") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-1">
                      <div class="form-group">
                        <input
                          v-model.trim="uhrzeit"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-1">
                      <div class="form-group">
                        <input
                          v-model.trim="raum"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.room") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <span
                          class="openIcon"
                          v-on:click="oeffneDozentenbuchung"
                          v-if="berechtigungen.m_dozierende.read"
                          ><font-awesome-icon
                            icon="fa-duotone fa-arrow-circle-right"
                          />
                        </span>
                        <input
                          v-model="dozent"
                          class="form-control"
                          readonly
                          placeholder=" "
                        />
                        <label
                          >{{ $t("global.lecturer") }}
                          <font-awesome-icon
                            icon="fa-duotone fa-arrow-circle-right"
                          />
                        </label>
                      </div>
                    </div>

                    <div class="col-xl-2">
                      <div class="form-group">
                        <span
                          class="openIcon"
                          v-on:click="oeffneTermin"
                          v-if="berechtigungen.m_dozierende.read"
                          ><font-awesome-icon
                            icon="fa-duotone fa-arrow-circle-right"
                          />
                        </span>
                        <input
                          v-model="termin.bezeichnung"
                          class="form-control"
                          readonly
                          placeholder=" "
                        />
                        <label
                          >{{ $t("global.appointment") }}
                          <font-awesome-icon
                            icon="fa-duotone fa-arrow-circle-right"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ENDE Daten -->
        <!-- START Inhalt -->
        <container-headline
          :headline="$t('global.lessoncontent')"
          :col="4"
          v-if="showUnterrichtsinhalt"
        ></container-headline>

        <div class="row mb-3" v-if="showUnterrichtsinhalt">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <div
                    v-for="(bereich, bereichKey) in selectedFachMatrix"
                    v-bind:key="bereich.id"
                    class="row"
                  >
                    <div class="col-xl-12">
                      <div class="row">
                        <div
                          class="col-xl-12"
                          style="font-weight: 600; font-size: 14pt"
                        >
                          {{ bereiche[bereichKey] }}
                        </div>
                      </div>
                      <div class="row mt-2 px-3" style="font-size: 12pt">
                        <div
                          v-for="(sektion, sektionKey) in bereich"
                          v-bind:key="sektion.id"
                          class="col-xl-2 p-0 px-1"
                        >
                          <div
                            @click="selectInhalt(sektionKey)"
                            class="col-xl-12 mb-3 d-flex align-items-center"
                            :class="
                              sektionenList.includes(sektionKey)
                                ? 'bg-green'
                                : 'bg-medium-blue'
                            "
                            style="
                              font-weight: 600;
                              height: 60px;
                              padding: 40px 15px;
                              border-radius: 3px;
                            "
                          >
                            {{ sektionen[sektionKey] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <textarea
                    v-model.trim="bemerkung_unterricht"
                    class="form-control"
                    placeholder=" "
                    :tabindex="113"
                    :readonly="!editable"
                  ></textarea>
                  <label>{{ $t("global.comment") }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ENDE Inhalt -->

        <!-- START Planung -->
        <container-headline
          :headline="$t('global.connectionplanning')"
          :col="4"
          v-if="showPlanung"
        ></container-headline>

        <div class="row mb-3" v-if="showPlanung">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <div
                    v-for="(bereich, bereichKey) in selectedFachMatrix"
                    v-bind:key="bereich.id"
                    class="row"
                  >
                    <div class="col-xl-12">
                      <div class="row">
                        <div
                          class="col-xl-12"
                          style="font-weight: 600; font-size: 14pt"
                        >
                          {{ bereiche[bereichKey] }}
                        </div>
                      </div>
                      <div class="row mt-2 px-3" style="font-size: 12pt">
                        <div
                          v-for="(sektion, sektionKey) in bereich"
                          v-bind:key="sektion.id"
                          class="col-xl-2 p-0 px-1"
                        >
                          <div
                            @click="selectPlanung(sektionKey)"
                            class="col-xl-12 mb-3 d-flex align-items-center"
                            :class="
                              sektionenListPlanung.includes(sektionKey)
                                ? 'bg-green'
                                : 'bg-medium-blue'
                            "
                            style="
                              font-weight: 600;
                              height: 60px;
                              padding: 40px 15px;
                              border-radius: 3px;
                            "
                          >
                            {{ sektionen[sektionKey] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <textarea
                    v-model.trim="bemerkung_planung"
                    class="form-control"
                    placeholder=" "
                    :tabindex="113"
                    :readonly="!editable"
                  ></textarea>
                  <label>{{ $t("global.comment") }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ENDE Planung -->
        <!-- START Reiter -->

        <container-headline
          :headline="$t('global.participants')"
          :col="4"
        ></container-headline>

        <div class="row mb-3">
          <div class="col-xl-12 block belegliste br-t-l-0">
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                :items="anwesenheit"
                :fields="fields"
                :sort-by="defaultSortBy"
                sort-icon-left
                fixed
              >
                <template v-slot:cell(redirectPerson)="row">
                  <b-button
                    size="sm"
                    @click="redirectToPerson(row.item.anmeldung.person.id)"
                  >
                    <font-awesome-icon icon="fa-regular fa-arrow-up-right" />
                  </b-button>
                </template>

                <template v-slot:cell(redirect)="row">
                  <b-button
                    size="sm"
                    @click="redirectToRegistration(row.item.anmeldung.id)"
                  >
                    <font-awesome-icon icon="fa-regular fa-arrow-up-right" />
                  </b-button>
                </template>

                <template v-slot:cell(anwesenheit)="row">
                  <b-button
                    class="mr-2"
                    :variant="
                      row.item.anwesenheitsrate == 100 ? 'primary' : 'light'
                    "
                    size="sm"
                    style="width: 50px"
                    @click="setAnwesenheit(100, row.item)"
                    >100%</b-button
                  >
                  <b-button
                    class="mr-2"
                    :variant="
                      row.item.anwesenheitsrate == 50 ? 'primary' : 'light'
                    "
                    size="sm"
                    style="width: 50px"
                    @click="setAnwesenheit(50, row.item)"
                    >50%</b-button
                  >
                  <b-button
                    class="mr-2"
                    :variant="
                      row.item.anwesenheitsrate == 0 ||
                      row.item.anwesenheitsrate == null
                        ? 'primary'
                        : 'light'
                    "
                    size="sm"
                    style="width: 50px"
                    @click="setAnwesenheit(0, row.item)"
                    >0%</b-button
                  >
                  <b-button
                    v-if="
                      row.item.anwesenheitsrate != 0 &&
                      row.item.anwesenheitsrate != 50 &&
                      row.item.anwesenheitsrate != 100 &&
                      row.item.anwesenheitsrate != null
                    "
                    class="mr-2"
                    variant="primary"
                    size="sm"
                    style="width: 50px"
                    >{{ row.item.anwesenheitsrate }}%</b-button
                  >
                </template>
                <template v-slot:cell(abwesenheit)="row">
                  <b-button
                    class="mr-2 mb-2"
                    :variant="
                      row.item.praesenzstatus.id ==
                      $CONST('PRAESENZSTATI').EXCUSED
                        ? 'primary'
                        : 'light'
                    "
                    size="sm"
                    style="width: 150px"
                    @click="
                      setPraesenzStatus(
                        $CONST('PRAESENZSTATI').EXCUSED,
                        row.item
                      )
                    "
                    >{{ $t("global.excused") }}</b-button
                  >
                  <b-button
                    class="mr-2 mb-2"
                    :variant="
                      row.item.praesenzstatus.id ==
                      $CONST('PRAESENZSTATI').UNEXCUSED
                        ? 'primary'
                        : 'light'
                    "
                    size="sm"
                    style="width: 150px"
                    @click="
                      setPraesenzStatus(
                        $CONST('PRAESENZSTATI').UNEXCUSED,
                        row.item
                      )
                    "
                    >{{ $t("global.unexcused") }}</b-button
                  >
                  <b-button
                    class="mr-2 mb-2"
                    :variant="
                      row.item.praesenzstatus.id ==
                      $CONST('PRAESENZSTATI').DISPENSED
                        ? 'primary'
                        : 'light'
                    "
                    size="sm"
                    style="width: 150px"
                    @click="
                      setPraesenzStatus(
                        $CONST('PRAESENZSTATI').DISPENSED,
                        row.item
                      )
                    "
                    >{{ $t("global.dispensed") }}</b-button
                  >
                  <b-button
                    class="mr-2 mb-2"
                    :variant="
                      row.item.praesenzstatus.id ==
                      $CONST('PRAESENZSTATI').JOKERDAY
                        ? 'primary'
                        : 'light'
                    "
                    size="sm"
                    style="width: 150px"
                    @click="
                      setPraesenzStatus(
                        $CONST('PRAESENZSTATI').JOKERDAY,
                        row.item
                      )
                    "
                    >{{ $t("global.jokerday") }}</b-button
                  >
                </template>
                <template v-slot:cell(note)="row">
                  <div class="form-group m-0 p-0 col-xl-4">
                    <div class="form-group" style="padding-top: 8px">
                      <input
                        v-model.trim="row.item.note"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                </template>
                <template #cell(kompetenzraster)="row">
                  <b-button
                    type="button"
                    size="sm"
                    style="width: 150px"
                    @click="showKompetenzraster(row.item)"
                    >Kompetenzraster</b-button
                  >
                </template>
                <!-- TODO bitte wie bei notenübersicht -->
                <template #cell(edit)="row" class="d-flex align-items-center">
                  <span class="d-flex justify-content-center">
                    <button
                      type="button"
                      size="sm"
                      @click="row.toggleDetails"
                      class="btn btn-primary btn-sm"
                    >
                      {{
                        row.detailsShowing
                          ? $t("global.hide")
                          : $t("global.show")
                      }}
                    </button>
                  </span>
                  <span class="d-flex justify-content-center p-1">
                    <font-awesome-icon
                      v-if="row.item.fachkompetenzen"
                      class="m-1"
                      icon="fa-regular fa-file-alt"
                    />
                  </span>
                </template>
                <template #row-details="row">
                  <b-card>
                    <div class="d-flex flex-column">
                      <span class="row mb-4">
                        <span sm="3" class="col-2 text-sm-right"
                          ><b
                            >{{
                              $t("global.commentonsubjectprofessionalskills")
                            }}:
                          </b></span
                        >
                        <textarea
                          class="col-8 input-lg"
                          type="text"
                          :placeholder="
                            $t('global.commentonsubjectprofessionalskills')
                          "
                          v-model="row.item.fachkompetenzen"
                          :disabled="bearbeitungGesperrt"
                        />

                        <div
                          class="btn btn-primary mb-4 ml-auto mr-3"
                          style="width: 200px; display-block: inline"
                          v-if="row.item.anmeldungIdSemester != ''"
                          @click="showLernzielvereinbarungBewertung(row.item)"
                        >
                          {{ $t("global.learningobjectivesagreement") }}
                        </div>
                      </span>

                      <span class="row mb-2">
                        <span sm="3" class="col-2 text-sm-right"
                          ><b
                            >{{ $t("global.commentonworksocialbehavior") }}:
                          </b></span
                        >
                        <textarea
                          class="col-8 input-lg"
                          type="text"
                          :placeholder="
                            $t('global.commentonworksocialbehavior')
                          "
                          v-model="row.item.sozialverhalten"
                          :disabled="bearbeitungGesperrt"
                        />

                        <div
                          class="btn btn-primary mb-4 ml-auto mr-3"
                          style="width: 200px"
                          @click="
                            KompetenzenUndVerhaltenFuerAlleUebernehmen(row.item)
                          "
                        >
                          {{ $t("Für alle übernehmen") }}
                        </div>
                      </span>
                    </div>
                  </b-card>
                </template>

                <template
                  #cell(editSozial)="row2"
                  class="d-flex align-items-center"
                >
                  <div class="d-flex" style="position: relative">
                    <span class="d-flex justify-content-center ml-auto mr-auto">
                      <button
                        type="button"
                        size="sm"
                        @click="row2.toggleDetails"
                        class="btn btn-primary btn-sm"
                      >
                        {{
                          row2.detailsShowing
                            ? $t("global.hide")
                            : $t("global.show")
                        }}
                      </button>
                    </span>
                    <span
                      class="d-flex justify-content-center p-1"
                      style="position: absolute; left: 72%"
                    >
                      <font-awesome-icon
                        v-if="
                          row2.item.sozialverhalten || row2.item.fachkompetenzen
                        "
                        class="m-1"
                        icon="fa-regular fa-file-alt"
                      />
                    </span>
                  </div>
                </template>

                <template
                  #head(editKompetenzen)
                  v-if="raster ? (raster.id ? true : false) : false"
                >
                  <span>
                    {{ $t("global.lessoncontent") }}
                  </span>
                </template>

                <template
                  #cell(editKompetenzen)="row"
                  class="d-flex align-items-center"
                >
                  <span
                    class="d-flex justify-content-center"
                    v-if="raster ? (raster.id ? true : false) : false"
                  >
                    <button
                      type="button"
                      size="sm"
                      @click="showUnterrichtsinhaltPersonModal(row.item)"
                      class="btn btn-primary btn-sm"
                    >
                      {{ $t("global.content") }}
                    </button>
                  </span>
                </template>
                <!--
                <template #row2-details="row2">
                  <b-card>
                    <span class="row mb-2">
                      <span sm="3" class="col-2 text-sm-right"
                        ><b>Bemerkung: </b></span
                      >
                      <textarea
                        class="col-8 input-lg"
                        type="text"
                        placeholder="Bemerkung"
                        v-model="row2.item.sozialverhalten"
                        :disabled="bearbeitungGesperrt"
                      />
                    </span>
                  </b-card>
                </template>-->
              </b-table>
            </div>
          </div>
        </div>

        <!-- ENDE linke Seite -->
      </div>
    </div>

    <fachkompetenzen-uebernehmen-modal @confirmed="uebernehmen" />

    <messagebox
      id="dupletten-msg-box"
      ref="duplettenMsgBox"
      :header-text="$t('global.conflictwithjokerday')"
      ok
    >
      <p>{{ $t("global.thereisaconflictwithjokerday") }}</p>
      <div class="row"></div>
    </messagebox>

    <loading-overlay v-show="loading"></loading-overlay>

    <lernzielvereinbarung-bewertung-modal
      :editable="false"
      :id="LernzielvereinbarungBewertungModalId"
      :anmeldungId="anmeldungId"
    ></lernzielvereinbarung-bewertung-modal>

    <lektionsInhalt-modal
      :editable="false"
      :id="LektionsInhaltModalId"
      :termin="this.termin"
      :veranstaltungID="this.vid"
      :rasterID="this.raster.id"
      :selectedFach="this.selectedFach"
      :anmeldungId="anmeldungId"
      :veranstaltungNiveau="
        this.veranstaltung.niveau ? this.veranstaltung.niveau.id : null
      "
      :praesenzProp="praesenz"
      :istInhalt="istInhalt"
      :istPlanung="istPlanung"
      :key="lektionsInhaltModalKey"
      @changed="resetPraesenz"
    ></lektionsInhalt-modal>

    <kompetenzraster-bewertung-modal
      :id="KompetenzrasterBewertungModalId"
      @kompetenzrasterDrucken="kompetenzrasterDrucken"
      @kompetenzrasterVorlageDrucken="kompetenzrasterVorlageDrucken"
    ></kompetenzraster-bewertung-modal>

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayerAnwesenheit"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";

import LernzielvereinbarungBewertungModal from "@/components/Modals/LernzielvereinbarungBewertungModal";
import LektionsInhaltModal from "@/components/Modals/LektionsinhaltModal";
import KompetenzrasterBewertungModal from "@/components/Modals/KompetenzrasterBewertungModal";
import FachkompetenzenUebernehmenModal from "@/components/Modals/FachkompetenzenUebernehmenModal";
import FileDownload from "js-file-download";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import server from "@/server";

import { textNotLinked } from "@/defaultTexts.js";

import ChangeWatcher from "@/mixins/ChangeWatcher";

export default {
  name: "Anwesenheit",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LernzielvereinbarungBewertungModal,
    LektionsInhaltModal,
    FachkompetenzenUebernehmenModal,
    KompetenzrasterBewertungModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return this.$t("dashboard.presence");
      },
    };
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    vid: {
      type: String,
      default: null,
    },
    tid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lektionsInhaltModalKey: 0,
      pdfPath: "",
      veranstaltungStatus: textNotLinked,
      anmeldungId: "",
      praesenz: null,
      istInhalt: false,
      istPlanung: false,
      LernzielvereinbarungBewertungModalId:
        "lernzielvereinbarung-bewertung-modal",
      LektionsInhaltModalId: "lektionsInhalt-modal",
      KompetenzrasterBewertungModalId: "kompetenzraster-bewertung-modal",
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      veranstaltung: {},
      oldAnwesenheit: null,
      anwesenheit: null,
      termin: { bezeichnung: "" },
      dozentenbuchung: null,
      dozenteneinsatz: null,
      fachkompetenzen: null,
      sozialverhalten: null,
      bemerkung_unterricht: null,
      bemerkung_planung: null,
      fachkompetenzAll: null,
      sozialverhaltenAll: null,

      fach: "",
      datum: "",
      uhrzeit: "",
      raum: "",
      dozent: "",

      editable: false,
      bearbeitungGesperrt: false,

      raster: {},
      matrix: [],
      selectedFach: [],
      reverseMatrix: {},
      faecher: {},
      bereiche: {},
      sektionen: {},
      subsektionen: {},
      stufen: {},

      selected: [],
      selectedIds: [],
      selectedInhalt: [],
      sektionenList: [],
      sektionenListPlanung: [],
      kompetenzliste: [],

      showUnterrichtsinhalt: false,
      showUnterrichtsinhaltPerson: false,
      showPlanung: false,

      fields: [
        {
          key: "anmeldung.person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "redirectPerson",
          sortable: true,
          label: "Zur Person",
          tdClass: "text-center",
        },
        {
          key: "redirect",
          sortable: true,
          label: this.$t("global.registration"),
          tdClass: "text-center",
        },
        {
          key: "anwesenheit",
          sortable: true,
          label: this.$t("dashboard.presence"),
          tdClass: "w-10",
        },
        {
          key: "abwesenheit",
          sortable: true,
          label: this.$t("global.absence"),
          tdClass: "w-25",
        },
        {
          key: "note",
          sortable: true,
          label: "Unterrichtsbeurteilung" /*this.$t("global.classassessment")*/,
        },
        //{ key: "edit", sortable: false, label: this.$t("global.subjectprofessionalcompetencies") },
        {
          key: "kompetenzraster",
          sortable: false,
          label: "Kompetenzenraster",
        },
        {
          key: "editSozial",
          sortable: false,
          label: "Kompetenzen / Verhalten",
        },
        {
          key: "editKompetenzen",
          sortable: false,
          label: "",
        },
      ],
      defaultSortBy: "anmeldung.person.personName",
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.vid && this.tid) {
        return (
          this.fach +
          (this.datum ? " " + this.$t("global.on") + " " + this.datum : "") +
          (this.uhrzeit ? " " + this.$t("global.at") + " " + this.uhrzeit : "")
        );
      } else {
        return this.$t("global.newpresence");
      }
    },
    selectedFachMatrix: function () {
      return this.reverseMatrix[this.selectedFach];
    },
  },
  watch: {},
  created() {
    if (this.vid && this.tid) {
      this.loading = true;

      Api.get("/bildung/praesenzverwaltung/", {
        params: { veranstaltung: this.vid, termin: this.tid },
      }).then((response) => {
        this.initializeAnwesenheit(response.data);
      });
    }
  },
  mounted() {},
  methods: {
    resetPraesenz() {
      this.lektionsInhaltModalKey++;
      this.praesenz = null;
      this.istInhalt = null;
      this.istPlanung = null;
    },
    initializeAnwesenheit(anwesenheit) {
      if (anwesenheit) {
        this.veranstaltung = anwesenheit.veranstaltung;
        this.termin = anwesenheit.termin;
        this.bemerkung_unterricht = anwesenheit.termin.bemerkung_unterricht;
        this.bemerkung_planung = anwesenheit.termin.bemerkung_planung;
        this.oldAnwesenheit = JSON.parse(
          JSON.stringify(anwesenheit.praesenzliste)
        );
        this.anwesenheit = anwesenheit.praesenzliste;
        this.dozenteneinsatz = anwesenheit.dozenteneinsatz;
        anwesenheit.termin.unterrichtsinhalt.forEach((element) =>
          this.sektionenList.push(element.id)
        );
        anwesenheit.termin.anschlussplanung.forEach((element) =>
          this.sektionenListPlanung.push(element.id)
        );

        this.veranstaltungStatus =
          anwesenheit.veranstaltung.veranstaltungsstatus.bezeichnung;

        if (anwesenheit.dozenteneinsatz) {
          if (anwesenheit.dozenteneinsatz.dozentenbuchung) {
            this.dozent =
              anwesenheit.dozenteneinsatz.dozentenbuchung.person.personName;
            this.dozentenbuchung =
              anwesenheit.dozenteneinsatz.dozentenbuchung.id;
          }
        }

        this.fach = this.veranstaltung.title;
        this.datum = this.termin.von;
        this.uhrzeit = this.termin.von_uhrzeit;

        if (this.termin.raumliste && this.termin.raumliste.length > 0)
          this.raum = this.termin.raumliste[0].bezeichnung;
        else this.raum = "";

        this.initChangeWatcher(this.anwesenheit);
      } else {
        console.error("anwesenheit ist null");
      }

      this.editable = this.berechtigungen.m_anwesenheit.update;

      this.loading = false;
      this.loadRaster();
    },

    redirectToRegistration(id) {
      this.$router.push({
        name: "anmeldung",
        params: { anmeldungid: id, anzeige: 0 },
      });
    },
    redirectToPerson(id) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: id, anzeige: 0 },
      });
    },
    selectInhalt(sektionObj) {
      if (this.sektionenList.includes(sektionObj)) {
        let indexOfObject = this.sektionenList.findIndex((object) => {
          return object == sektionObj;
        });
        this.sektionenList.splice(indexOfObject, 1);
      } else {
        this.sektionenList.push(sektionObj);
      }
    },
    selectPlanung(sektionObj) {
      if (this.sektionenListPlanung.includes(sektionObj)) {
        let indexOfObject = this.sektionenListPlanung.findIndex((object) => {
          return object == sektionObj;
        });
        this.sektionenListPlanung.splice(indexOfObject, 1);
      } else {
        this.sektionenListPlanung.push(sektionObj);
      }
    },
    toggleUnterrichtsinhalt() {
      if (this.showUnterrichtsinhalt) {
        this.showUnterrichtsinhalt = false;
      } else {
        this.showUnterrichtsinhalt = true;
      }
    },
    toggleUnterrichtsinhaltPerson() {
      if (this.showUnterrichtsinhaltPerson) {
        this.showUnterrichtsinhaltPerson = false;
      } else {
        this.showUnterrichtsinhaltPerson = true;
      }
    },
    togglePlanung() {
      if (this.showPlanung) {
        this.showPlanung = false;
      } else {
        this.showPlanung = true;
      }
    },
    loadRaster() {
      if (!this.veranstaltung.fach) return;

      this.loading = true;

      Api.get("bildung/kompetenzraster/", {
        params: {
          fachID: this.veranstaltung.fach?.id,
        },
      }).then((response) => {
        this.initializeRaster(response.data[0]);
      });
    },

    initializeRaster(raster) {
      if (raster) {
        this.raster = raster;
        Api.get("bildung/kompetenzraster/kompetenzdefinition/", {
          params: { rasterid: this.raster.id },
        }).then((response) => {
          this.initializeMatrix(response?.data);
        });
      } else {
        console.error("kompetenzraster ist null");
      }

      this.loading = false;
    },
    initializeMatrix(matrix) {
      if (matrix) {
        this.matrix = matrix;

        this.selectedFach = null;

        this.matrix.forEach((definition) => {
          let subsektion = definition.subsektion;
          let sektion = definition.sektion;
          let bereich = definition.bereich;
          let fach = definition.fach;

          if (!this.faecher[fach?.id]) this.faecher[fach?.id] = fach;
          if (!this.selectedFach) this.selectedFach = fach?.id;

          if (!this.reverseMatrix[fach?.id]) this.reverseMatrix[fach?.id] = {};

          if (!this.reverseMatrix[fach?.id][bereich?.id]) {
            this.reverseMatrix[fach?.id][bereich?.id] = {};
            this.bereiche[bereich?.id] = bereich?.bezeichnung;
          }

          if (!this.reverseMatrix[fach?.id][bereich?.id][sektion?.id]) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id] = {};
            this.sektionen[sektion?.id] = sektion?.bezeichnung;
          }

          if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ] = {};
            this.subsektionen[subsektion?.id] = subsektion?.bezeichnung;
          }

          if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ] = {};
            //this.stufen[definition.stufe.id] = definition.stufe.bezeichnung;
          }

          this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
            subsektion?.id
          ][definition.id] = definition;
        });

        this.fachSelect++;
      } else {
        console.error("kompetenzmastrix ist null");
      }
    },

    setAll100() {
      if (!this.editable) return;

      if (
        this.veranstaltung &&
        this.veranstaltung.veranstaltungsstatus.id ==
          this.$CONST("VERANSTALTUNGSSTATI").OPEN
      ) {
        this.anwesenheit.forEach((anwesenheit) => {
          this.$set(anwesenheit, "anwesenheitsrate", 100);
          this.$set(
            anwesenheit.praesenzstatus,
            "id",
            this.$CONST("PRAESENZSTATI").ATTENDED
          );
        });
      }
    },
    speichern() {
      if (this.loading) return;
      this.loading = true;

      // Hier über this.anwesenheit loopen und neues Array nur mit denjenigen Präsenzen, die verändert wurden anlegen, welche im JSON mitgegeben wird.
      let praesenzen = [];
      for (let counter = 0; counter <= this.anwesenheit.length - 1; counter++) {
        if (
          this.anwesenheit[counter].fachkompetenzen !==
            this.oldAnwesenheit[counter].fachkompetenzen ||
          this.anwesenheit[counter].sozialverhalten !==
            this.oldAnwesenheit[counter].sozialverhalten
        ) {
          praesenzen.push(this.anwesenheit[counter]);
        }
      }

      var json = {
        praesenzliste: praesenzen.length !== 0 ? praesenzen : this.anwesenheit,
        dozenteneinsatz: this.dozenteneinsatz,
        kompetenzliste: this.kompetenzliste,
        bemerkung_unterricht: this.bemerkung_unterricht,
        bemerkung_planung: this.bemerkung_planung,
      };

      Api.put("/bildung/praesenzverwaltung/", json, {
        params: { veranstaltung: this.vid, termin: this.tid },
      })
        .then((response) => {
          if (!response.data.error) {
            this.initializeAnwesenheit(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.presencesuccessfullysaved"),
            });
          }
          this.disableChangeWatcher(this.anwesenheit);
        })
        .catch((e) => {
          //this.$notify(apiErrorToAlert(e));
          this.$notify({
            type: "warning",
            title: this.$t("notification.actionnotsuccessful"),
            text: this.$t("notification.jokerdaydoesnotmatch"),
          });
          this.$refs.duplettenMsgBox.openModal();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    abbrechen() {
      this.$router.push({ name: "Anwesenheitsliste" });
    },
    setPraesenzStatus(status, item) {
      if (!this.editable) return;

      if (
        this.veranstaltung &&
        this.veranstaltung.veranstaltungsstatus.id ==
          this.$CONST("VERANSTALTUNGSSTATI").OPEN
      ) {
        this.$set(item, "anwesenheitsrate", 0);
        this.$set(item.praesenzstatus, "id", status);
      }
      /*
      if (this.veranstaltung)
        if (
          this.veranstaltung.veranstaltungsstatus.id ==
          this.$CONST("VERANSTALTUNGSSTATI").OPEN
        ) {
          this.anwesenheit[index].anwesenheitsrate = 0;
          this.anwesenheit[index].praesenzstatus.id = status;
        }*/
    },

    setNote(note, index) {
      if (!this.editable) return;
      this.anwesenheit[index].note = note;
    },

    setAnwesenheit(rate, item) {
      if (!this.editable) return;

      if (
        this.veranstaltung &&
        this.veranstaltung.veranstaltungsstatus.id ==
          this.$CONST("VERANSTALTUNGSSTATI").OPEN
      ) {
        this.$set(item, "anwesenheitsrate", rate);
        this.$set(
          item.praesenzstatus,
          "id",
          rate == 0
            ? this.$CONST("PRAESENZSTATI").UNEXCUSED
            : this.$CONST("PRAESENZSTATI").ATTENDED
        );
      }
    },
    oeffneDozentenbuchung() {
      this.$router.push({
        name: "dozentenbuchung",
        params: { id: this.dozentenbuchung, anzeige: 0 },
      });
    },
    oeffneTermin() {
      this.$router.push({
        name: "termin",
        params: { id: this.termin.id, anzeige: 0 },
      });
    },
    oeffneFach() {
      this.$router.push({
        name: "fach",
        params: { fachid: this.vid, anzeige: 0 },
      });
    },

    showLernzielvereinbarungBewertung(anwesenheit) {
      this.anmeldungId = anwesenheit?.anmeldungIdSemester;

      this.$bus.$emit("openLernzielvereinbarungBewertungModal", {
        modalId: "lernzielvereinbarung-bewertung-modal",
        anmeldungId: anwesenheit?.anmeldungIdSemester,
      });
    },
    showKompetenzraster(anwesenheit) {
      //this.anmeldungId = anwesenheit?.anmeldungIdSemester;
      console.log(anwesenheit?.anmeldungIdSemester);
      this.$bus.$emit("openKompetenzrasterBewertungModal", {
        id: "kompetenzraster-bewertung-modal",
        anmeldungId: anwesenheit?.anmeldungIdSemester,
      });
    },
    showUnterrichtsinhaltPersonModal(praesenz) {
      this.praesenz = praesenz;

      this.$bus.$emit("openLektionsInhaltModal", {
        modalId: "lektionsInhalt-modal",
        praesenzID: praesenz.id,
      });
    },
    showUnterrichtsinhaltModal() {
      this.istPlanung = false;
      this.istInhalt = true;
      this.$bus.$emit("openLektionsInhaltModal", {
        modalId: "lektionsInhalt-modal",
        istPlanung: false,
        istInhalt: true,
      });
    },
    showUnterrichtsplanungModal() {
      this.istPlanung = true;
      this.istInhalt = false;
      this.$bus.$emit("openLektionsInhaltModal", {
        modalId: "lektionsInhalt-modal",
        istInhalt: false,
        istPlanung: true,
      });
    },
    showFachkompetenzenUebernehmenModal() {
      this.$bus.$emit("openFachkompetenzenUebernehmenModal");
    },
    uebernehmen(json) {
      if (json.fachkompetenz || json.sozialverhalten) {
        this.anwesenheit.forEach((praesenz) => {
          if (json.fachkompetenz && praesenz.fachkompetenzen !== null) {
            if (praesenz.fachkompetenzen !== "") {
              praesenz.fachkompetenzen = `${praesenz.fachkompetenzen}\n${json.fachkompetenz}`;
            } else {
              praesenz.fachkompetenzen = `${json.fachkompetenz}`;
            }
          }
          if (json.fachkompetenz && !praesenz.fachkompetenzen)
            praesenz.fachkompetenzen = json.fachkompetenz;

          if (json.sozialverhalten && praesenz.sozialverhalten !== null) {
            if (praesenz.sozialverhalten !== "") {
              praesenz.sozialverhalten = `${praesenz.sozialverhalten}\n${json.sozialverhalten}`;
            } else {
              praesenz.sozialverhalten = `${json.sozialverhalten}`;
            }
          }
          if (json.sozialverhalten && !praesenz.sozialverhalten)
            praesenz.sozialverhalten = json.sozialverhalten;
        });
        this.speichern();
      }
    },
    kompetenzrasterDrucken(anmeldung) {
      let filename =
        "Kompetenzraster_" +
        anmeldung.person.personName.replace(/\s/g, "") +
        "_" +
        new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/kompetenzraster.htm&ids=${anmeldung.id}&sgid=${anmeldung.kompetenzraster.id}&date=${anmeldung.veranstaltung.periodennummer}`;

      this.$refs.pdfLayerAnwesenheit.show();
    },

    kompetenzrasterVorlageDrucken(anmeldung) {
      let filename =
        "KompetenzrasterVorlage_" +
        anmeldung.person.personName.replace(/\s/g, "") +
        "_" +
        new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/kompetenzrasterVorlage.htm&ids=${anmeldung.id}&sgid=${anmeldung.kompetenzraster.id}&date=${anmeldung.veranstaltung.periodennummer}`;

      this.$refs.pdfLayerAnwesenheit.show();
    },
    excelExport() {
      Api.post(
        "/praesenz/export/",
        this.anwesenheit,
        { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(this.excelFile, `Anwesenheit-${this.fach}.xlsx`);
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: this.$t("notification.actionfailed"),
              text: "Fehler beim Erstellen der Excel-Datei",
              duration: 7000,
            });
          }, 2000);
          this.loading = false;
        });
    },
    praesenzlisteDrucken() {
      let [day, month, year] = this.datum.split(".");
      let jahr = year;
      let filename =
        "Praesenzliste_" +
        this.veranstaltung.bezeichnung.replace(/\s/g, "") +
        "_" +
        day +
        "-" +
        month +
        "-" +
        year;
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/praesenzliste.htm&ids=${this.termin.id}&sgid=${this.veranstaltung.id}&date=${jahr}`;

      this.$refs.pdfLayerAnwesenheit.show();
    },

    KompetenzenUndVerhaltenFuerAlleUebernehmen(selectedRow) {
      this.fachkompetenzAll = selectedRow.fachkompetenzen;
      this.sozialverhaltenAll = selectedRow.sozialverhalten;

      // allen rows diesen Inhalt zuordnen
      this.anwesenheit.forEach((row) => {
        row.fachkompetenzen = this.fachkompetenzAll;
        row.sozialverhalten = this.sozialverhaltenAll;
      });
      this.speichern();
      // beim Speichern alle neuen Inahlte übernehmen
    },
    alleAnzeigen() {
      // was passiert onclick des Buttons
    },
    /*  expandAll() {
      for (const item of this.anwesenheit) {
        this.$set(item, "aria-details", true);
      }
    },
    collapseAll() {
      for (const item of this.anwesenheit) {
        this.$set(item, "aria-details", false);
      }
    }, */
  },
};
</script>

<style>
</style>
