<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4">
                <button class="btn btn-success mr-2" @click="speichern">
                  {{ $t("global.save") }}
                </button>
                <button class="btn btn-primary mr-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
                <button
                  class="btn btn-primary mr-2"
                  @click="oeffneAnmeldungFinalisierenmodal"
                >
                  <span class="mr-2"
                    ><font-awesome-icon icon="fa-duotone fa-clipboard-check"
                  /></span>
                  {{ $t("global.finalizeregistrations") }}
                </button>
              </div>
              <div class="mr-4">
                <button class="btn btn-danger" @click="oeffneLoeschenModal">
                  <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
                  <span>{{ $t("global.delete") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- DATEN AUS ANMELDEFORMULAR -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <container-headline
          :headline="$t('global.datafromregistrationform')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.salutation"
                      class="form-control"
                      :placeholder="$t('global.salutation')"
                      readonly
                    />
                    <label>{{ $t("global.salutation") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.first_name"
                      class="form-control"
                      :placeholder="$t('global.firstname')"
                      readonly
                    />
                    <label>{{ $t("global.firstname") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.last_name"
                      class="form-control"
                      :placeholder="$t('global.surname')"
                      readonly
                    />
                    <label>{{ $t("global.surname") }}</label>
                  </div>
                </div>

                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.creation_date"
                      class="form-control"
                      :placeholder="$t('global.createdon')"
                      readonly
                    />
                    <label>{{ $t("global.createdon") }}</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-5">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.adress"
                      class="form-control"
                      :placeholder="$t('global.address')"
                      readonly
                    />
                    <label>{{ $t("global.address") }}</label>
                  </div>
                </div>

                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.zip"
                      class="form-control"
                      :placeholder="$t('global.zip')"
                      readonly
                    />
                    <label>{{ $t("global.zip") }}</label>
                  </div>
                </div>

                <div class="col-xl-5">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.city"
                      class="form-control"
                      :placeholder="$t('global.city')"
                      readonly
                    />
                    <label>{{ $t("global.city") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.phone"
                      class="form-control"
                      :placeholder="$t('global.phone')"
                      readonly
                    />
                    <label>{{ $t("global.phone") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.email"
                      class="form-control"
                      :placeholder="$t('global.email')"
                      readonly
                    />
                    <label>{{ $t("global.email") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="anmeldungEvent.interests"
                      class="form-control"
                      :placeholder="$t('global.interests')"
                      readonly
                    />
                    <label>{{ $t("global.interests") }}</label>
                  </div>
                </div>
                <div class="col-xl-12">
                  <single-select-veranstaltung
                    id="anmeldung-event-veranstaltung"
                    :veranstaltung="anmeldungEvent.event"
                    :label="$t('global.event')"
                    :allowOpen="berechtigungen.m_bildung.read"
                    :editable="berechtigungen.m_bildung.delete"
                    @confirmed="setVeranstaltung"
                  />
                </div>
                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      ref="messageTextarea"
                      v-model="anmeldungEvent.comment"
                      class="form-control"
                      :placeholder="$t('global.comment')"
                      readonly
                    />
                    <label>{{ $t("global.comment") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- DUBLETTE -->
      <div
        v-if="anmeldungEvent.master_gp || anmeldungEvent.person"
        class="col-xl-6 pr-4"
        :class="{ 'col-xl-12': !showNavbar }"
      >
        <container-headline
          :headline="$t('global.foundpersoninsystem')"
          :col="6"
        ></container-headline>

        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <!-- DUBLETTENCHECK INFO -->
              <div
                class="row px-3 mb-3"
                v-if="anmeldungEvent.duplicate_resolved"
              >
                <div
                  class="w-100 fs-14 bg-info-light rounded px-1 py-2 d-flex align-items-center"
                  v-if="
                    anmeldungEvent.person &&
                    anmeldungEvent.master_gp.id === anmeldungEvent.person.id
                  "
                >
                  <font-awesome-icon
                    class="ml-2 fs-22"
                    icon="fa-duotone fa-circle-info"
                  />
                  <span class="ml-3">{{
                    $t("global.linkexistingpersoninfo")
                  }}</span>
                </div>
                <div
                  class="w-100 fs-14 bg-info-light rounded px-1 py-2 d-flex align-items-center"
                  v-if="
                    !anmeldungEvent.person ||
                    anmeldungEvent.master_gp.id != anmeldungEvent.person.id
                  "
                >
                  <font-awesome-icon
                    class="ml-2 fs-22"
                    icon="fa-duotone fa-circle-info"
                  />
                  <span class="ml-3">{{ $t("global.linknewpersoninfo") }}</span>
                </div>
              </div>
              <!-- INFOS DUBLETTE -->
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="anmeldungEvent.person.person_number"
                      class="form-control"
                      :placeholder="$t('global.personnumber')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="anmeldungEvent.master_gp.person_number"
                      class="form-control"
                      :placeholder="$t('global.personnumber')"
                      readonly
                    />
                    <label>{{ $t("global.personnumber") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="anmeldungEvent.person.first_name"
                      class="form-control"
                      :placeholder="$t('global.firstname')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="anmeldungEvent.master_gp.first_name"
                      class="form-control"
                      :placeholder="$t('global.firstname')"
                      readonly
                    />
                    <label>{{ $t("global.firstname") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="anmeldungEvent.person.last_name"
                      class="form-control"
                      :placeholder="$t('global.surname')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="anmeldungEvent.master_gp.last_name"
                      class="form-control"
                      :placeholder="$t('global.surname')"
                      readonly
                    />
                    <label>{{ $t("global.surname") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="anmeldungEvent.person.adress"
                      class="form-control"
                      :placeholder="$t('global.address')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="anmeldungEvent.master_gp.adress"
                      class="form-control"
                      :placeholder="$t('global.address')"
                      readonly
                    />
                    <label>{{ $t("global.address") }}</label>
                  </div>
                </div>
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="anmeldungEvent.person.zip"
                      class="form-control"
                      :placeholder="$t('global.zip')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="anmeldungEvent.master_gp.zip"
                      class="form-control"
                      :placeholder="$t('global.zip')"
                      readonly
                    />
                    <label>{{ $t("global.zip") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="anmeldungEvent.person.city"
                      class="form-control"
                      :placeholder="$t('global.city')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="anmeldungEvent.master_gp.city"
                      class="form-control"
                      :placeholder="$t('global.city')"
                      readonly
                    />
                    <label>{{ $t("global.city") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="formattedPhonePerson"
                      class="form-control"
                      :placeholder="$t('global.phone')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="formattedPhoneMasterGP"
                      class="form-control"
                      :placeholder="$t('global.phone')"
                      readonly
                    />
                    <label>{{ $t("global.phone") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-if="anmeldungEvent.person"
                      v-model="anmeldungEvent.person.email"
                      class="form-control"
                      :placeholder="$t('global.email')"
                      readonly
                    />

                    <input
                      v-else
                      v-model="anmeldungEvent.master_gp.email"
                      class="form-control"
                      :placeholder="$t('global.email')"
                      readonly
                    />
                    <label>{{ $t("global.email") }}</label>
                  </div>
                </div>
              </div>
              <!-- BUTTONS -->
              <div class="row px-3 pt-3 d-flex justify-content-between">
                <button
                  class="btn btn-secondary d-flex align-items-center mr-2 mb-2"
                  @click="oeffnePerson()"
                  v-if="berechtigungen.m_anmeldungen.delete"
                >
                  <font-awesome-icon class="ml-1" icon="fa-solid fa-user-pen" />
                  <span class="ml-2">{{
                    $t("global.editexistingperson")
                  }}</span>
                </button>
                <button
                  class="btn btn-primary d-flex align-items-center mr-2 mb-2"
                  @click="oeffneAnmeldungBestehendePersonModal()"
                  v-if="
                    berechtigungen.m_anmeldungen.delete &&
                    !anmeldungEvent.duplicate_resolved
                  "
                >
                  <font-awesome-icon
                    class="ml-1"
                    icon="fa-solid fa-people-arrows-left-right"
                  />
                  <span class="ml-2">{{
                    $t("global.linkwithexistingperson")
                  }}</span>
                </button>
                <button
                  class="btn btn-success d-flex align-items-center mb-2"
                  @click="oeffneAnmeldungNeuePersonModal()"
                  v-if="
                    berechtigungen.m_anmeldungen.delete &&
                    !anmeldungEvent.duplicate_resolved
                  "
                >
                  <font-awesome-icon
                    class="ml-1"
                    icon="fa-solid fa-user-plus"
                  />
                  <span class="ml-2">{{ $t("global.createnewperson") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="anmeldung-loeschen-modal"
      @confirmed="anmeldungLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
    <message-box
      id="anmeldung-neue-person-modal"
      :headerText="$t('global.createnewperson')"
      :text="messageTextNeuePerson"
      :ok="true"
      :cancelText="$t('global.cancel')"
      :repeat="false"
      :okText="$t('global.ok')"
      @ok="neuePersonErstellen"
    />
    <message-box
      id="anmeldung-bestehende-person-modal"
      :headerText="$t('global.linkwithexistingperson')"
      :text="messageTextBestehendePerson"
      :ok="true"
      :cancelText="$t('global.cancel')"
      :repeat="false"
      :okText="$t('global.ok')"
      @ok="bestehendePersonVerknuepfen"
    />
    <message-box
      id="anmeldung-finalisieren-modal"
      :headerText="$t('global.finalizeregistrations')"
      :text="messageAnmeldungFinalisieren"
      :ok="true"
      :cancelText="$t('global.cancel')"
      :repeat="false"
      :okText="$t('global.ok')"
      @ok="anmeldungenFinalisieren"
    />
    <message-box
      id="anmeldung-finalisieren-erfolg-modal"
      :headerText="$t('notification.actionsuccessful')"
      :text="messageAnmeldungFinalisierenErfolg"
      :ok="true"
      :cancelText="$t('global.tolist')"
      :repeat="false"
      :okText="$t('global.gotoregistration')"
      @ok="zurAnmeldung"
      @cancel="abbrechen"
    />
  </div>
</template>
	
	<script>
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageBox from "@/components/Modals/Messagebox";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";

import WebsiteApi from "@/WebsiteApi";
import Api from "@/Api";

import store from "@/store";
import page from "@/mixins/Page";

export default {
  name: "WebseiteAnmeldungEvent",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
    LoeschenModal,
    MessageBox,
    SingleSelectVeranstaltung,
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      anmeldungEvent: {
        duplicate_resolved: null,
        person: {},
        master_gp: {},
        event: { id: null },
        comment: null,
      },
      finalisierteAnmeldungId: "",
    };
  },
  computed: {
    navbarTitel: function () {
      return (
        this.$t("global.websiteregistration") +
        " " +
        (this.anmeldungEvent?.event?.designation
          ? this.anmeldungEvent?.event?.designation
          : this.anmeldungEvent?.event?.bezeichnung
          ? this.anmeldungEvent?.event?.bezeichnung
          : "")
      );
    },
    formattedPhonePerson() {
      return this.formattedPhoneNumber(this.anmeldungEvent?.person?.phone);
    },
    formattedPhoneMasterGP() {
      return this.formattedPhoneNumber(this.anmeldungEvent?.master_gp?.phone);
    },
    messageTextNeuePerson() {
      return this.$t("global.messagetextnewperson");
    },
    messageTextBestehendePerson() {
      return this.$t("global.messagetextexistingperson");
    },
    messageAnmeldungFinalisieren() {
      return this.$t("global.messagetextnewperson");
    },
    messageAnmeldungFinalisierenErfolg() {
      return this.$t("global.messagetextfinalizeregistrationssuccess");
    },
  },
  created() {
    this.initializeAnmeldung();
  },
  mounted: function () {},
  methods: {
    initializeAnmeldung() {
      this.loading = true;

      WebsiteApi.get("event/", { params: { id: this.id } })
        .then((response) => {
          this.anmeldungEvent = response.data;
        })
        .finally(() => {
          this.initializeVeranstaltung();
        });
    },
    initializeVeranstaltung() {
      Api.get("veranstaltungen/", {
        params: { id: this.anmeldungEvent.event.id },
      })
        .then((response) => (this.anmeldungEvent.event = response.data))
        .finally(() => {
          this.loading = false;
        });
    },
    formattedPhoneNumber(phone) {
      if (!phone) return "";
      const numbers = phone.split(",").map((num) => num.trim());
      const validNumbers = numbers.filter((num) => num);
      const length = validNumbers.length;

      switch (length) {
        case 0:
          return "";
        case 1:
          return validNumbers[0];
        case 2:
          return validNumbers.join(", ");
        default:
          return `${validNumbers[0]}, ${validNumbers[1]}, ${validNumbers[2]}`;
      }
    },
    abbrechen() {
      this.$router.push({
        name: "webseite-anmeldungen-liste",
        params: { anzeige: 2 },
      });
    },
    speichern() {
      this.loading = true;

      WebsiteApi.put(
        "event/" + this.anmeldungEvent.id + "/",
        this.anmeldungEvent,
        {}
      )
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.registrationsavedsuccessfully"),
          });
        })
        .finally(() => {
          this.initializeAnmeldung();
          this.loading = false;
        });
    },
    oeffneAnmeldungFinalisierenmodal() {
      if (
        !this.anmeldungEvent.duplicate_resolved &&
        this.anmeldungEvent.master_gp
      ) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("global.duplicateconflict"),
        });
      } else {
        this.$bus.$emit("open-modal", "anmeldung-finalisieren-modal");
      }
    },
    anmeldungenFinalisieren() {
      let json = [];
      json.push(this.anmeldungEvent);

      WebsiteApi.post("finalize-registration/", json).then((response) => {
        this.finalisierteAnmeldungId = response.data.id;
        this.$bus.$emit("open-modal", "anmeldung-finalisieren-erfolg-modal");
      });
    },
    anmeldungLoeschen() {
      let json = [];
      json.push(this.id);

      WebsiteApi.delete("event/", {
        data: json,
      }).then(() => {
        this.$router.push({
          name: "webseite-anmeldungen-liste",
          params: { anzeige: 0 },
        });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "anmeldung-loeschen-modal");
    },
    oeffneVeranstaltung() {
      let route = this.$router.resolve({
        name: "event",
        params: {
          eventid: this.anmeldungEvent.event?.id,
          anzeige: 0,
        },
      });
      window.open(route.href, "_blank");
    },
    oeffnePerson() {
      let route = this.$router.resolve({
        name: "geschaeftspartner",
        params: {
          id: this.anmeldungEvent.person?.id
            ? this.anmeldungEvent.person.id
            : this.anmeldungEvent.master_gp.id,
          anzeige: 0,
        },
      });
      window.open(route.href, "_blank");
    },
    zurAnmeldung() {
      let route = this.$router.resolve({
        name: "anmeldung.anzeige",
        params: {
          anmeldungid: this.finalisierteAnmeldungId,
          anzeige: 0,
        },
      });
      window.open(route.href, "_blank");
      this.abbrechen();
    },
    oeffneAnmeldungNeuePersonModal() {
      this.$bus.$emit("open-modal", "anmeldung-neue-person-modal");
    },
    oeffneAnmeldungBestehendePersonModal() {
      this.$bus.$emit("open-modal", "anmeldung-bestehende-person-modal");
    },
    neuePersonErstellen() {
      this.loading = true;

      WebsiteApi.put(
        "event/" + this.anmeldungEvent.id + "/",
        this.anmeldungEvent,
        {
          params: { new_person: true },
        }
      )
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.newpersoncreatedsuccess"),
          });
          this.anmeldungEvent = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    bestehendePersonVerknuepfen() {
      this.loading = true;

      WebsiteApi.put(
        "event/" + this.anmeldungEvent.id + "/",
        this.anmeldungEvent,
        {
          params: { new_person: false },
        }
      )
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("global.personlinkedsuccess"),
          });
          this.anmeldungEvent = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setVeranstaltung(veranstaltung) {
      this.anmeldungEvent.event = veranstaltung;
    },
  },
};
</script>
	
	<style></style>
	