//import Adressenliste from "@/pages/Dashboardlisten/Adressenliste";
import Firmenliste from "@/pages/Dashboardlisten/Firmenliste";
import PersonenListe from "@/pages/Dashboardlisten/Geschaeftspartnerliste";
import Geschaeftspartner from "@/pages/Geschaeftspartner/Geschaeftspartner";
import Firma from "@/pages/Geschaeftspartner/Firma";
//import Firma from "@/pages/Geschaeftspartner/Firma";


export default [

 /*  {
    path: "/geschaeftspartner/adressenliste",
    name: "adressen-liste",
    component: Adressenliste,
  }, */

  {
    path: "/geschaeftspartner/firmenliste",
    name: "firmen-liste",
    component: Firmenliste,
  },

  {
    path: "/geschaeftspartner/personenliste",
    name: "personen-liste",
    props: (route) => {
      return {
        rolleProp: route.params.rolleProp,
      };
    },
    component: PersonenListe,
  },

  {
    path: "/geschaeftspartner/studierende",
    name: "studierende-liste",
    props: (route) => {
      return {
        rolleProp: route.params.rolleProp,
      };
    },
    component: PersonenListe,
  },

  {
    path: "/geschaeftspartner/:rolleProp",
    name: "geschaeftspartner-mit-rolle",
    props: (route) => {
      return {
        rolleProp: route.params.rolleProp,
      };
    },
    component: Geschaeftspartner,
  },
  {
    path: "/geschaeftspartner/:id?/:anzeige",
    name: "geschaeftspartner",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: Geschaeftspartner,
  },

  {
    path: "/firma/:id?/:anzeige",
    name: "firma",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: Firma,
  },

  /*** Muss überarbeitet werdne ***/

  {
    path: "/geschaeftspartner/:id/:anzeige",
    name: "GeschaeftspartnerMitIdUndAnzeige",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: () => import("@/pages/Geschaeftspartner/Geschaeftspartner"),
  },
];
