<template>
  <div class="list">
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="kontaktdurch"
                      :person="filters.kontaktperson"
                      :label="$t('global.contactperson')"
                      editable
                      :required="false"
                      @confirmed="setKontaktPerson"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="verantwortlicher"
                      :person="filters.verantwortlicher"
                      :label="$t('global.Responsible')"
                      :required="false"
                      editable
                      :rollen="filterMitarbeitende"
                      @confirmed="setVerantwortlicher"
                    />
                  </div>
                </div>
                <filter-search-reset-button/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.note')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <button
                      class="btn btn-success"
                      @click="neueNotizOhnePerson"
                      v-if="berechtigungen.m_notizen.create"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />{{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_notizen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="notiz-loeschen-modal"
      :selectedIds="this.selectedIds"
      :multiple="true"
      @confirmed="notizLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

export default {
  name: "NotizListe",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    SingleSelectPerson,
    LoeschenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.notes");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
      fields: [
        { key: "selected", label: "" },
        { key: "datum", searchable: false, sortable: true, label: this.$t("global.date") },
        {
          key: "kontaktpersonen",
          searchable: false,
          sortable: true,
          label: this.$t("global.contactperson"),
          formatter: (value) => {
            if (value) {
              let person = value[0];
              return person ? person.personName : "";
            } else {
              return "";
            }
          },
        },
        { key: "bezeichnung", sortable: true, label: this.$t("global.designation") },
        {
          key: "kontaktart.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.contacttype"),
        },
        {
          key: "kontaktkategorie.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.contactcategory"),
        },
        {
          key: "kontakt_durch.personName",
          sortable: true,
          label: this.$t("global.contactby"),
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {}

      params.person = this.setQueryParam(this.filters.kontaktperson?.id);
      params.verantwortlicher = this.setQueryParam(
        this.filters.verantwortlicher?.id
      );
      params.bezeichnung = this.setQueryParam(this.filters['bezeichnung']);
      params.filterKDurch = this.setQueryParam(this.filters['kontakt_durch.personName'])

      // TODO

      //params.datum = this.setQueryParam(this.filters.datum)
      //params.filterKPerson = this.setQueryParam(this.filters.kontaktpersonen)
      //params.filterKABez = this.setQueryParam(this.filters['kontaktart.bezeichnung'])

      return params;
    },
  },
  created() {
    this.initFilter("notizen-liste", "kontakt/", false);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "date",
      "contactperson",
      "designation",
      "contacttype",
      "contactcategory",
      "contactby",
    ]);
  },
  methods: {
    details(notiz) {
      this.$router.push({
        name: "notiz",
        params: { id: notiz.id, anzeige: 0 },
      });
    },

    setKontaktPerson(person) {
      this.$set(this.filters, "kontaktperson", person);
    },

    setVerantwortlicher(person) {
      this.$set(this.filters, "verantwortlicher", person);
    },

    notizLoeschen() {
      Api.delete("kontakt", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "notiz-loeschen-modal");
    },

    neueNotiz() {
      let json = {
        kontaktpersonen: [this.kontaktpersonen],
        firmen: [this.firmen],
        kontakt_durch: this.angemeldeterMitarbeiter.person,
        person: this.angemeldeterMitarbeiter.person,
        beschreibung: "",
        datum: new Intl.DateTimeFormat("de-CH").format(new Date()),
      };

      Api.post("kontakt/", json, { params: {} }).then((response) => {
        this.$router.push({
          name: "notiz",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },
    neueNotizOhnePerson() {
      this.$router.push({ name: "notiz" });
    },
  },
};
</script>

<style>
</style>
