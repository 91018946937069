import Produkt from "@/pages/Produkt/Produkt";
import Produktliste from "@/pages/Dashboardlisten/Produktliste";

export default [
    {
        path: "/produktliste",
        name: "produkt-liste",
        component: Produktliste,
    },

    {
        path: "/produkt/:produktid?",
        name: "produkt",
        props: (route) => {
            return {
                anmeldungid: route.params.anmeldungid,
            };
        },
        component: Produkt,
    },
];
