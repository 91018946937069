<!-- Diese Liste dient zur Ausgabe aller Anmeldungen für eine Veranstaltung -->
<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="anmeldung-von"
                      :person="filters.person"
                      :label="$t('global.person')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      editable
                      @confirmed="setFilterPerson"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.geschlecht"
                        :options="geschlechter"
                        label="bezeichnung"
                        :placeholder="$t('global.gender')"
                      >
                      </v-select>
                      <label>{{ $t("global.gender") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.abschluss"
                        :options="abschluesse"
                        :reduce="(ab) => ab.id"
                        label="bezeichnung"
                        :placeholder="$t('global.degree')"
                      >
                        <span slot="no-options">{{ $t("global.nodegreefound") }}</span>
                      </v-select>
                      <label>{{ $t("global.degree")}}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="anmeldung-klasse-veranstaltung"
                      :veranstaltung="filters.veranstaltung"
                      :label="$t('global.event')"
                      :allowOpen="false"
                      editable
                      @confirmed="setFilterVeranstaltung"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungsstatus"
                        :options="veranstaltungsstati"
                        :reduce="(vs) => vs.id"
                        label="bezeichnung"
                        :placeholder="$t('global.eventstatus')"
                      >
                        <span slot="no-options">{{ $t("global.nostatusfound") }}</span>
                      </v-select>
                      <label>{{ $t("global.eventstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungstyp"
                        :options="veranstaltungstypen"
                        :reduce="(vt) => vt.id"
                        label="bezeichnung"
                        :placeholder="$t('global.typeofevent')"
                      >
                        <span slot="no-options">{{ $t("global.notypesfound") }}</span>
                      </v-select>
                      <label>{{ $t("global.typeofevent") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.statusAnmeldung"
                        :options="anmeldestati"
                        :reduce="(vt) => vt.id"
                        label="bezeichnung"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{ $t("global.nostatusfound") }}</span>
                      </v-select>
                      <label>{{ $t("global.registrationstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.anmeldungstyp"
                        :options="anmeldetypen"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('dashboard.type')"
                      >
                        <span slot="no-options">{{ $t("global.notypefound") }}</span>
                      </v-select>
                      <label>{{ $t("global.typeofregistration") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.semester"
                        :options="periode"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :placeholder="$t('global.period')"
                      >
                        <span slot="no-options">{{ $t("global.noperiodsfound") }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period")}}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.remarks"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.remarks") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.listofregistrations')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4">
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_anmeldungen.update"
                      @click="showAnmeldungUebertragen"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-sign-out-alt"
                        class="mr-2"
                      />
                      <span>{{ $t("global.transferregistration") }}</span>
                    </button>

                    <b-dropdown variant="primary mr-2">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-print"
                          class="mr-2"
                        />
                        <em>{{ $t("global.output") }}</em>
                      </template>
                      <b-dropdown-item @click="ausgebenTeilnehmerliste">
                        {{ $t("global.participantlist") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="ausgebenTischkarten">
                        {{ $t("global.placecards") }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_finanzen.create"
                      :disabled="!filterVeranstaltung"
                      @click="oeffneRechnungErstellenModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-file-invoice-dollar"
                        class="mr-2"
                      />
                      {{ $t("global.createinvoice") }}
                    </button>
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                      @click="massenmailErstellen"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-envelope-open-text"
                        class="mr-2"
                      />
                      {{ $t("global.createmassmail") }}
                    </button>
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                      @click="massenkorrespondenzErstellen"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-memo"
                        class="mr-2"
                      />
                      {{ $t("global.createserialletter") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_anmeldungen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(openveranstaltung)="row">
                      <div @click="openVeranstaltung(row.item)">
                        <font-awesome-icon
                          icon="fa-duotone fa-arrow-circle-right"
                        />
                      </div>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <rechnung-erstellen-modal
      id="rechnung-erstellen-modal"
      ref="rechnungErstellenModal"
      :anmeldungen="selectedIds"
      :veranstaltung="rgveranstaltung"
      :angemeldeterMitarbeiter="angemeldeterMitarbeiter"
      :key="rechnungModalKey"
    />

    <loeschen-modal
      id="anmeldungen-loeschen-modal"
      @confirmed="anmeldungenLoeschen"
      :selectedIds="selectedIds"
    />

    <messagebox-count
      headerText="Anmeldung(en) gelöscht"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
            :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <anmeldung-uebertragen-modal
      id="anmeldung-uebertragen-modal"
      :allowCopy="true"
      @copied="anmeldungGeaendert"
      @changed="anmeldungGeaendert"
    ></anmeldung-uebertragen-modal>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import server from "@/server";

import RechnungErstellenModal from "@/components/Rechnungslegung/Modals/RechnungErstellenModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import AnmeldungUebertragenModal from "@/components/Modals/AnmeldungUebertragenModal";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import {
  LADE_AUSWAHLWERTE_ANMELDUNG,
  LADE_ABSCHLUESSE,
} from "@/store/anmeldung/actions.type";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

export default {
  name: "Anmeldungsliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    RechnungErstellenModal,
    LoeschenModal,
    AnmeldungUebertragenModal,
    SingleSelectPerson,
    SingleSelectVeranstaltung,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.registrationsinvoices");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      rgveranstaltung: null,
      filterRollen: this.$CONST("ROLLEN").STUDENTEN,
      
      pdfPath: "",
      sortBy: "person.personName",
      sortDesc: false,
      rechnungModalKey: 0,

      fields: [
        { key: "selected", label: "" },
        {
          key: "veranstaltung.kuerzel",
          sortable: true,
          searchable: false,
          label: this.$t("global.abbrevi"),
        },
        {
          key: "openveranstaltung",
          sortable: false,
          label: "",
          searchable: false,
        },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
          searchable: true,
        },
        {
          key: "abschluss.bezeichnung",
          sortable: true,
          searchable: false,
          label: this.$t("global.degree"),
        },
        {
          key: "anmeldestatus.bezeichnung",
          sortable: true,
          searchable: true,
          label: this.$t("global.status"),
        },
        {
          key: "rechnungsdatum",
          sortable: true,
          searchable: false,
          label: this.$t("global.invdate"),
        },
        {
          key: "anmeldetyp.bezeichnung",
          sortable: true,
          searchable: true,
          label: this.$t("global.registrationtype"),
        },
        {
          key: "rechnungshinweis",
          sortable: true,
          searchable: false,
          label: this.$t("global.invnote"),
          thStyle: "width: 100px",
        },
        {
          key: "rg_adresse_zusatz",
          sortable: true,
          searchable: false,
          label: this.$t("global.invadraddition"),
          thStyle: "width: 100px",
        },
        {
          key: "bemerkung",
          sortable: true,
          searchable: false,
          label: this.$t("global.comment"),
          thStyle: "width: 200px",
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {}

      params.person = this.setQueryParam(this.filters.person?.id);
      params.filterPerson = this.setQueryParam(
        this.filters["person.personName"]
      );
      params.abschluss = this.setQueryParam(this.filters.abschluss);
      params.anmeldungstyp = this.setQueryParam(this.filters.anmeldungstyp);
      params.statusAnmeldung = this.setQueryParam(this.filters.statusAnmeldung);
      params.veranstaltung = this.setQueryParam(this.filters.veranstaltung?.id);
      params.geschlecht = this.setQueryParam(this.filters.geschlecht?.id);

      params.vstatus = this.setQueryParam(this.filters.veranstaltungsstatus);
      params.vtyp = this.setQueryParam(this.filters.veranstaltungstyp);
      params.filterVKuerzel = this.setQueryParam(
        this.filters["veranstaltung.kuerzel"]
      );
      params.filterVBez = this.setQueryParam(
        this.filters["veranstaltung.bezeichnung"]
      );
      params.filterASBez = this.setQueryParam(
        this.filters["anmeldestatus.bezeichnung"]
      );
      params.filterATBez = this.setQueryParam(
        this.filters["anmeldetyp.bezeichnung"]
      );
      params.remarks = this.setQueryParam(this.filters.remarks);
      params.periode = this.setQueryParam(this.filters.semester);

      params.filterGebucht = this.setQueryParam(this.filters.gebucht);
      params.filterRGDatum = this.setQueryParam(this.filters.rechnungsdatum);

      params.filterCount = this.setQueryParam(this.filters.count);

      return params;
    },

    anmeldetypen: {
      get() {
        return this.$store.state.anmeldung.anmeldetypen;
      },
    },
    anmeldestati: {
      get() {
        return this.$store.state.anmeldung.anmeldestati;
      },
    },
    abschluesse: {
      get() {
        return this.$store.state.anmeldung.abschluesse;
      },
    },
    periode: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    geschlechter: {
      get() {
        return this.$store.state.geschaeftspartner.geschlechter;
      },
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    veranstaltungstypen: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungstypen;
      },
    },
    filterVeranstaltung: function () {
      return this.filters.veranstaltung;
    },
  },
  created() {
    if (this.anmeldetypen.length == 0)
      this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);

    if (this.abschluesse.length == 0)
      this.$store.dispatch(`anmeldung/${LADE_ABSCHLUESSE}`);

    if (this.periode.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    if (this.geschlechter.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    this.initFilter("anmeldung-liste", "anmeldung/", true);

    if (!this.rgveranstaltung)
      this.rgveranstaltung = this.filterVeranstaltung
        ? this.filterVeranstaltung.id
        : null;
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "abbrevi",
      "",
      "person",
      "degree",
      "status",
      "invdate",
      "registrationtype",
      "invnote",
      "invadraddition",
      "comment",
    ]);
  },
  methods: {
    details(anmeldungen) {
      if (anmeldungen.id) {
        this.$router.push({
          name: "anmeldung",
          params: { anmeldungid: anmeldungen.id, anzeige: 0 },
        });
      }
    },

    openVeranstaltung(anmeldung) {
      if (!anmeldung.veranstaltung || !anmeldung.veranstaltung.id) return;

      let typ = anmeldung.veranstaltung.veranstaltungstyp;

      if (!typ || typ.id == this.$CONST("VERANSTALTUNGSTYPEN").STUDIES) {
        this.$router.push({
          name: "klasse",
          params: { klassenid: anmeldung.veranstaltung.id, anzeige: 0 },
        });
      } else if (typ.id == this.$CONST("VERANSTALTUNGSTYPEN").MODULE) {
        this.$router.push({
          name: "fach",
          params: { kursid: anmeldung.veranstaltung.id, anzeige: 0 },
        });
      } else if (
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").COURSE ||
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").PRECOURSE
      ) {
        this.$router.push({
          name: "kurs",
          params: { kursid: anmeldung.veranstaltung.id, anzeige: 0 },
        });
      } else if (typ.id == this.$CONST("VERANSTALTUNGSTYPEN").INFO) {
        this.$router.push({
          name: "infoanlass",
          params: { infoanlassid: anmeldung.veranstaltung.id, anzeige: 0 },
        });
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").EVENT ||
          this.$router.push({
            name: "event",
            params: { eventid: anmeldung.veranstaltung.id, anzeige: 0 },
          });
      } else if (
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_NDS ||
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_HFP
      ) {
        this.$router.push({
          name: "klasse",
          params: { klassenid: anmeldung.veranstaltung.id, anzeige: 0 },
        });
      } else {
        console.log(typ.id);
        console.log("Veranstaltungstyp nicht identifiziert");
      }
    },

    oeffneRechnungErstellenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("open-modal", "rechnung-erstellen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselected"),
        });
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "anmeldungen-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselecteddeletion"),
        });
      }
    },

    anmeldungenLoeschen() {
      this.delete("anmeldung/", this.selectedIds);
    },

    ausgebenTeilnehmerliste: function () {
      this.pdfPath =
        server.url +
        "/pdf/teilnehmerliste-anmeldung/pdf.act?ids=" +
        this.selectedIds.join(",");
      this.$refs.pdfLayer.show();
    },

    ausgebenTischkarten: function () {
      this.pdfPath =
        server.url +
        "/pdf/tischkarten-pdf-erzeugen-anmeldung-/pdf.act?ids=" +
        this.selectedIds.join(",");
      this.$refs.pdfLayer.show();
    },

    showAnmeldungUebertragen() {
      this.$bus.$emit("openAnmeldungUebertragenModal", this.selectedIds);
    },

    anmeldungGeaendert() {
      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: this.$t("notification.registrationssuccessfullytransferred"),
      });

      this.resetLoading(this);
    },

    setFilterPerson(person) {
      this.$set(this.filters, "person", person);
    },

    setFilterVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
      this.rgveranstaltung = veranstaltung.id;
    },

    massenkorrespondenzErstellen() {
      if (this.selectedIds.length > 0) {
        let json = {
          empfaenger: [],
          person: [], // ist de facto person. Noch unsauber im Backend
          isMassKorrespondenz: true,
          anmeldung: this.selectedIds[0], // Die erste Anmeldung wird für die Verknüpfung mitgegeben, damit die Serienfelder gehen
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter,
            datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
            ort: "",
            betreff: this.$t("global.newserialletter"),
          },
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch-DE").format(new Date()),
        };

        Array.from(new Set(this.selectedIds)).forEach((id) => {
          json.empfaenger.push(
            this.anmeldungen.find((anmeldung) => anmeldung.id == id).person.id
          );
        });

        json.person = json.empfaenger;

        if (json.empfaenger.length == 0) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.selectedregistrationsnotcontainlinkedperson"),
          });
          return;
        }

        Api.post("korrespondenz/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "korrespondenz",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nopersonselected"),
        });
    },

    massenmailErstellen() {
      if (this.selectedIds.length > 0) {
        let json = {
          empfaenger: [],
          isMassMail: true,
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        Array.from(new Set(this.selectedIds)).forEach((id) => {
          json.empfaenger.push(
            this.listData.find((anmeldung) => anmeldung.id == id).person.id
          );
        });

        if (json.empfaenger.length == 0) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.selectedregistrationsnotcontainlinkedperson"),
          });
          return;
        }

        Api.post("mail/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "mail",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselected"),
        });
    },
  },
};
</script>

<style>
</style>
