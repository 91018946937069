import {
  SET_ANREDEN,
  SET_AKADEMISCHER_TITEL,
  SET_EMAIL_TYPEN,
  SET_BONITAETEN,
  SET_GESCHLECHTER,
  SET_FUNKTIONSBEREICHE,
  SET_INTERESSEN,
  SET_ZIVILSTAENDE,
  SET_NATIONALITAETEN,
  SET_SPRACHEN,
  SET_AUFENTHALTSBEWILLIGUNGEN,
  SET_ANSTELLLUNGS_ARTEN,
  SET_AKTIVITAETS_STATI,
  SET_KANTONE,
  SET_PARTNERROLLEN,
  SET_DOZENTEN,
  SET_QUALIFIKATION_TYPEN,
  SET_QUALIFIKATION_NIVEAU,
  SET_POSTLEITZAHLEN,
  SET_ADRESS_VERWENDUNG_TYPEN,
  SET_RELATION_TYPEN,
  SET_RELATION_ARTEN,
  SET_RELATION_STATI,
  SET_FIRMA_PARTNERROLLEN,
  SET_BRANCHEN,
  SET_KOMPETENZSTUFEN,
  SET_INTERESSEN_INFOABEND
} from "./mutations.type";

import {
  LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER,
  LADE_DOZENTEN,
} from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    emailtypen: [],
    bonitaten: [],
    geschlechter: [],
    funktionsbereiche: [],
    anreden: [],
    akademischetitel: [],
    interessen: [],
    zivilstaende: [],
    nationalitaeten: [],
    sprachen: [],
    aufenthaltsbewilligungen: [],
    anstellungsarten: [],
    aktivitaetsstati: [],
    kantone: [],
    partnerrollen: [],
    dozenten: [],
    qualifikationtypen: [],
    niveau: [],
    postleitzahlen: [],
    adressverwendungstypen: [],
    relationtypen: [],
    relationarten: [],
    relationsstati: [],
    firma_partnerrollen: [],
    branchen: [],
    kompetenzstufen: [],
    interessen_infoabend: []
  },
  mutations: {
    [SET_EMAIL_TYPEN](state, val) {
      state.emailtypen = val;
    },
    [SET_BONITAETEN](state, val) {
      state.bonitaeten = val;
    },
    [SET_GESCHLECHTER](state, val) {
      state.geschlechter = val;
    },
    [SET_FUNKTIONSBEREICHE](state, val) {
      state.funktionsbereiche = val;
    },
    [SET_ANREDEN](state, val) {
      state.anreden = val;
    },
    [SET_AKADEMISCHER_TITEL](state, val) {
      state.akademischetitel = val;
    },
    [SET_INTERESSEN](state, val) {
      state.interessen = val;
    },
    [SET_ZIVILSTAENDE](state, val) {
      state.zivilstaende = val;
    },
    [SET_NATIONALITAETEN](state, val) {
      state.nationalitaeten = val;
    },
    [SET_SPRACHEN](state, val) {
      state.sprachen = val;
    },
    [SET_AUFENTHALTSBEWILLIGUNGEN](state, val) {
      state.aufenthaltsbewilligungen = val;
    },
    [SET_ANSTELLLUNGS_ARTEN](state, val) {
      state.anstellungsarten = val;
    },
    [SET_AKTIVITAETS_STATI](state, val) {
      state.aktivitaetsstati = val;
    },
    [SET_KANTONE](state, val) {
      state.kantone = val;
    },
    [SET_PARTNERROLLEN](state, val) {
      state.partnerrollen = val;
    },
    [SET_DOZENTEN](state, val) {
      state.dozenten = val;
    },
    [SET_QUALIFIKATION_TYPEN](state, val) {
      state.qualifikationtypen = val;
    },
    [SET_QUALIFIKATION_NIVEAU](state, val) {
      state.niveau = val;
    },
    [SET_POSTLEITZAHLEN](state, val) {
      state.postleitzahlen = val;
    },
    [SET_ADRESS_VERWENDUNG_TYPEN](state, val) {
      state.adressverwendungstypen = val;
    },
    [SET_RELATION_TYPEN](state, val) {
      state.relationtypen = val;
    },
    [SET_RELATION_ARTEN](state, val) {
      state.relationarten = val;
    },
    [SET_RELATION_STATI](state, val) {
      state.relationsstati = val;
    },
    [SET_FIRMA_PARTNERROLLEN](state, val) {
      state.firma_partnerrollen = val;
    },
    [SET_BRANCHEN](state, val) {
      state.branchen = val;
    },
    [SET_KOMPETENZSTUFEN](state, val) {
      state.kompetenzstufen = val;
    },
    [SET_INTERESSEN_INFOABEND](state, val) {
      state.interessen_infoabend = val;
    },
  },
  actions: {
    async [LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER](context) {
      let response = await Api.get("geschaeftspartner/auswahlwerte/");
      context.commit(SET_EMAIL_TYPEN, response.data.emailtypen);
      context.commit(SET_BONITAETEN, response.data.bonitaeten);
      context.commit(SET_GESCHLECHTER, response.data.geschlechter);
      context.commit(SET_FUNKTIONSBEREICHE, response.data.funktionsbereiche);
      context.commit(SET_ANREDEN, response.data.anreden);
      context.commit(SET_AKADEMISCHER_TITEL, response.data.akademischetitel);
      context.commit(SET_INTERESSEN, response.data.interessen);
      context.commit(SET_ZIVILSTAENDE, response.data.zivilstaende);
      context.commit(SET_NATIONALITAETEN, response.data.nationalitaeten);
      context.commit(SET_SPRACHEN, response.data.sprachen);
      context.commit(
        SET_AUFENTHALTSBEWILLIGUNGEN,
        response.data.aufenthaltsbewilligungen
      );
      context.commit(SET_ANSTELLLUNGS_ARTEN, response.data.anstellungsarten);
      context.commit(SET_AKTIVITAETS_STATI, response.data.aktivitaetsstati);
      context.commit(SET_KANTONE, response.data.kantone);
      context.commit(SET_PARTNERROLLEN, response.data.partnerrollen);
      context.commit(SET_QUALIFIKATION_TYPEN, response.data.qualifikationtypen);
      context.commit(SET_QUALIFIKATION_NIVEAU, response.data.niveau);
      context.commit(SET_POSTLEITZAHLEN, response.data.postleitzahlen);
      context.commit(SET_RELATION_TYPEN, response.data.relationtypen);
      context.commit(SET_RELATION_ARTEN, response.data.relationarten);
      context.commit(SET_RELATION_STATI, response.data.relationsstati);
      context.commit(SET_FIRMA_PARTNERROLLEN, response.data.firma_partnerrollen);
      context.commit(SET_BRANCHEN, response.data.branche);
      context.commit(SET_KOMPETENZSTUFEN, response.data.kompetenzstufen);
      context.commit(
        SET_ADRESS_VERWENDUNG_TYPEN,
        response.data.adressverwendungstypen
      );
      context.commit(SET_INTERESSEN_INFOABEND, response.data.interessen_infoabend);
    },
    async [LADE_DOZENTEN](context, status) {
      let response = await Api.get("geschaeftspartner/", {
        params: {
          rollen: this.$CONST("ROLLEN").DOZENTEN.join(),
          status: status,
          count: 1000,
        },
      });
      context.commit(SET_DOZENTEN, response.data.dozenten);
    },
  },
};
