<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel ? navbarTitel : $t('global.mail')"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.savemail") }}
                  </button>

                  <button
                    v-if="id && !mail.queued && !mail.gesendet"
                    class="btn btn-secondary mr-2"
                    :disabled="invalid"
                    @click="sendTestMail"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-envelope"
                      class="mr-2"
                    />
                    {{ $t("global.testmail") }}
                  </button>

                  <button
                    v-if="id && !mail.queued && !mail.gesendet"
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="mailSendenModal"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-envelope"
                      class="mr-2"
                    />{{ $t("global.send") }}
                  </button>

                  <button
                    v-if="mail.queued || mail.gesendet"
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="mailSendenModal"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-envelope"
                      class="mr-2"
                    />{{ $t("global.sendagain") }}
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="vorlageWaehlen"
                    v-if="editable"
                  >
                    {{ $t("global.selecttemplate") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />
                    {{ $t("global.tolist") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_korrespondenz.delete"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    <span>{{ $t("global.deletemail") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mail-empfaenger-absender
          v-if="!mail.isMassMail"
          :mail="mail"
          :editable="editable"
          @updated="inhaltKey++"
        />

        <massen-mail-empfaenger-absender
          v-if="mail.isMassMail"
          :mail="mail"
          :editable="editable"
          @updated="inhaltKey++"
        />

        <mail-inhalt
          v-if="!mail.isMassMail"
          :key="inhaltKey"
          :id="id"
          :mail="mail"
          :editable="editable"
          @mailbannerupdated="mailBannerUpdated"
        />

        <mass-mail-inhalt
          v-if="mail.isMassMail"
          :key="inhaltKey"
          :id="id"
          :mail="mail"
          :editable="editable"
          @mailbannerupdated="mailBannerUpdated"
        />

        <div class="col-xl-12 p-0" v-if="id">
          <div class="tab-container p-3">
            <attachment-liste
              :key="attachmentKey"
              :id="id"
              :editable="editable"
              :shown="true"
              @updateComponent="attachmentKey++"
              @dokumenteLoaded="getAttachments"
            />
          </div>
        </div>
      </div>
    </ValidationObserver>

    <mail-vorlage-modal @confirmed="vorlageSetzen" />

    <loeschen-modal id="mail-loeschen-modal" @confirmed="mailLoeschen" />

    <message-box
      id="mail-senden-modal"
      :headerText="$t('notification.Sendmails')"
      :text="$t('notification.doyouwantsendmails')"
      :ok="true"
      :cancel="true"
      :repeat="false"
      :okText="$t('notification.sendmails')"
      :cancelText="$t('global.cancel')"
      @ok="senden"
    />

    <message-box
      id="mail-notify-modal"
      :headerText="$t('notification.mailssent')"
      :text="$t('notification.testmailsentsuccessfully')"
      :ok="true"
      :cancel="true"
      :repeat="false"
      :cancelText="$t('global.cancel')"
    />

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MailVorlageModal from "@/components/Modals/MailVorlageModal";

import MailInhalt from "@/components/Kommunikation/MailInhalt.vue";
import MassMailInhalt from "@/components/Kommunikation/MassMailInhalt.vue";
import MailEmpfaengerAbsender from "@/components/Kommunikation/MailEmpfaengerAbsender.vue";
import MassenMailEmpfaengerAbsender from "@/components/Kommunikation/MassenMailEmpfaengerAbsender.vue";
import AttachmentListe from "@/components/Reiter/AttachmentListe.vue";
import MessageBox from "@/components/Modals/Messagebox";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

import { textNotLinked } from "@/defaultTexts";

export default {
  name: "Mail",
  components: {
    HeadMenu,
    Navbar,
    AttachmentListe,
    LoeschenModal,
    MailVorlageModal,
    MailInhalt,
    MassMailInhalt,
    MailEmpfaengerAbsender,
    MassenMailEmpfaengerAbsender,
    ChangeWatcherAlert,
    MessageBox,
  },

  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mail: {
        absender: {},
        absendermail: null,
        empfaengermail: null,
        firma: {},
        empfaenger: [{ personName: textNotLinked }, { name: textNotLinked }],
        zeitpunkt: new Intl.DateTimeFormat("de-CH").format(new Date()),
        inhalt: "",
        isMassMail: false,
        mailbanner: { bezeichnung: textNotLinked },
      },
      alertModal: {
        color: null,
        text: "",
        title: "",
      },
      attachments: [],
      editable: true,
      loading: false,
      inhaltKey: 0,
      attachmentKey: 0,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.mail) {
        return "Mail";
      } else {
        if (this.kategorieObj) return `Neue ${this.kategorieObj.bezeichnung}`;
        return "Neue Mail";
      }
    },
  },
  watch: {},

  mounted() {
    if (this.id) {
      Api.get("mail/", { params: { id: this.id } }).then((response) => {
        this.initializeMail(response.data);
      });
    } else {
      //this.initializeMail(this.mail);
    }
  },

  //mounted: function () {},

  methods: {
    mailBannerUpdated(u) {
      if (u) {
        if (!u.id) return;

        this.mail.mailbanner = u;
      } else {
        this.mail.mailbanner = {};
      }
    },
    abbrechen() {
      this.$router.push({ name: "mail-liste" });
    },
    getAttachments(attachments) {
      this.mail.attachments = attachments;
      this.attachments = attachments;
    },
    initializeMail(mail) {
      if (mail) {
        mail.empfaenger.sort((a, b) => {
          return a.personName > b.personName;
        });

        this.mail = mail;

        this.mail.attachments = this.attachments;

        if (!this.mail.inhalt) this.mail.inhalt = "";
        if (mail.empfaenger.length == 0) this.mail.empfaenger = [];
        else {
          this.empfaenger = mail.empfaenger;
        }

        if (this.mail.mailbanner) {
          this.mail.mailbannerURL =
            this.$CONST("CONFIG").BASEURL + this.mail.mailbanner.bild;
        }
      } else {
        //console.error("mail ist null");
      }

      if (mail.queued == true || mail.gesendet != null) this.editable = false;
      //this.editable = this.mail.queued ? false : true;

      if (!this.mail.zeitpunkt)
        this.mail.zeitpunkt = new Intl.DateTimeFormat("de-CH").format(
          new Date()
        );
      if (
        this.mail.isMassMail &&
        this.mail.absendermail != null &&
        this.mail.absendermail != ""
      ) {
        this.absendermail = this.mail.absendermail;
      } else if (
        this.mail.isMassMail &&
        this.mail.absendermail == null &&
        this.mail.absendermail == ""
      ) {
        this.mail.absendermail = this.$CONST("CONFIG").MANDANTEMAIL;
        this.absendermail = "pfui";
      } else {
        if (this.mail.absendermail != null && this.mail.absendermail != "") {
          this.absendermail = this.mail.absendermail;
        } else {
          if (this.mail.absender == null || this.mail.absender.id == null) {
            if (this.angemeldeterMitarbeiter.person) {
              this.mail.absender = this.angemeldeterMitarbeiter.person;

              if (this.angemeldeterMitarbeiter.person.mailadressen) {
                let mailadressen =
                  this.angemeldeterMitarbeiter.person.mailadressen;

                let pos = mailadressen.findIndex(
                  (mailadr) => mailadr.hauptadresse == true
                );
                if (pos == -1)
                  this.mail.absendermail = mailadressen[0].bezeichnung;
                else this.mail.absendermail = mailadressen[pos].bezeichnung;
              } else {
                this.mail.absendermail = "";
              }
            }
          }
        }
        let tempMail;
        if (this.mail.empfaenger[0]) {
          tempMail = this.mail.empfaenger[0]?.mailadressen?.find(
            (item) => item.hauptadresse == true
          );
        } else if (this.mail.firma[0]) {
          tempMail = this.mail.firma[0]?.mailadressen?.find(
            (item) => item.hauptadresse == true
          );
        }

        if (tempMail?.bezeichnung) {
          this.mail.empfaengermail = tempMail.bezeichnung;
        }

        if (!this.mail.empfaengermail && this.mail.empfaenger[0]) {
          this.mail.empfaengermail =
            this.mail?.empfaenger[0]?.mailadressen[0]?.bezeichnung;
        } else if (!this.mail.empfaengermail && this.mail.firma[0]) {
          this.mail.empfaengermail =
            this.mail?.firma[0]?.mailadressen[0]?.bezeichnung;
        } else {
          this.mail.empfaengermail = mail.empfaengermail;
        }
      }

      this.editable = !this.berechtigungen.m_korrespondenz.update
        ? false
        : this.editable;

      if (mail) this.initChangeWatcher(this.mail);
    },

    mailLoeschen() {
      Api.delete("mail/", {
        params: { ids: [this.id].join(",") },
      }).then(() => {
        this.$router.push({ name: "mail-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "mail-loeschen-modal");
    },

    showAlertModal(title, text, color) {
      this.alertModal.title = title;
      this.alertModal.text = text;
      this.alertModal.color = color;
      $("#modal-alert").modal("show");
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.mail);

      if (!this.id) {
        Api.post("mail/", json)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.mailsuccessfullysaved"),
            });

            this.$router.replace({
              name: "mail",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("mail/", json, { params: { id: this.id } })
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.mailsuccessfullysaved"),
            });
            this.initializeMail(response.data);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    vorlageWaehlen() {
      this.$bus.$emit("open-mail-vorlage-modal");
    },

    vorlageSetzen(vorlage) {
      if (vorlage.absenderadresse != "")
        this.mail.absender = this.angemeldeterMitarbeiter.person;
      if (vorlage.absenderadresse) {
        this.mail.absendermail = vorlage.absenderadresse;
      } else {
        //this.mail.absender = this.angemeldeterMitarbeiter.person;
        if (this.angemeldeterMitarbeiter.person.mailadressen.length > 0)
          this.mail.absendermail =
            this.angemeldeterMitarbeiter.person.mailadressen[0].bezeichnung;
      }

      this.mail.betreff = vorlage.betreff;
      this.mail.inhalt = vorlage.mailtext;

      this.inhaltKey += 1;
    },

    mailSendenModal() {
      this.$bus.$emit("open-modal", "mail-senden-modal");
    },

    mailNotify() {
      this.$bus.$emit("open-modal", "mail-notify-modal");
    },
    senden() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.mail);

      Api.put("mail/send/", json, {
        params: { id: this.id, testmail: false },
      })
        .then((response) => {
          this.initializeMail(response.data);

          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.mailssentsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sendTestMail() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.mail);

      if (this.mail.isMassMail) {
        if (this.mail.empfaenger) {
          let ersterEmpfaenger = this.mail.empfaenger[0];
          if (ersterEmpfaenger.mailadressen) {
            let mailadresse = ersterEmpfaenger.mailadressen.find(
              (email) => email.hauptadresse == true
            );
            if (!mailadresse)
              json.empfaengermail =
                ersterEmpfaenger.mailadressen[0].bezeichnung;
            else json.empfaengermail = mailadresse.bezeichnung;
          }
        }
      }

      Api.put("mail/send/", json, {
        params: { id: this.id, testmail: true },
      })
        .then((response) => {
          this.initializeMail(response.data);

          this.mailNotify();
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
